<template>
  <v-container fluid class="my-10" v-if="props">
    <v-row justify="center">
      <v-col class="pa-0">
        <v-slide-group
          v-model="props.suggested_tarif"
          show-arrows
          center-active>
          <v-slide-item v-for="(tarif, index) in props.tarifs" :key="index">
            <v-card
              class="rounded-lg mx-3 my-2"
              :id="index == props.suggested_tarif ? 'recommended' : ''">
              <center>
                <v-sheet
                  class="py-6 px-4 rounded-t-lg"
                  :color="
                    index == props.suggested_tarif ? 'secondary' : 'primary'
                  ">
                  <h4 class="text-h4 white--text">
                    Basistarif {{ tarif.tarif_name }}
                  </h4>
                  <p class="mb-0 text-subtitle-1 white--text">(Hausstrom)</p>
                </v-sheet>
                <v-card-text
                  :class="index == props.suggested_tarif ? '' : 'pt-16'">
                  <p
                    v-if="index == props.suggested_tarif"
                    class="
                      mr-4
                      secondary--text
                      font-weight-medium
                      text-right text-h6
                    ">
                    Empfohlen!
                  </p>
                  <span class="text-h3 font-weight-bold"
                    ><nobr
                      >{{ tarif.price.toFixed(2).toString().replace(".", ",") }}
                      {{ tarif.price_unit }}
                    </nobr></span
                  >
                  <span> / Monat</span>
                  <br />
                  <br />
                  <span class="mr-2">Freie Rückliefermenge</span>
                  <b
                    >{{ tarif.amount_free }} {{ tarif.amount_free_unit }} /
                    Jahr</b
                  >
                  <br />
                  <v-btn
                    @click="selectTarif(tarif)"
                    class="my-8"
                    :color="
                      index == props.suggested_tarif ? 'secondary' : 'primary'
                    "
                    >Tarif wählen</v-btn
                  >
                </v-card-text>
              </center>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
    <!-- Selected options -->
    <v-row justify="center" class="px-6" v-if="tarifSelected" id="options">
      <v-col>
        <h3 class="mb-6">Ihr gewähltes Paket</h3>
        <v-row>
          <v-col
            class="pa-0 rounded-l-lg"
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4">
            <v-sheet
              :class="`pa-9 ${
                $vuetify.breakpoint.smAndUp ? 'rounded-tl-lg' : 'rounded-t-lg'
              }`"
              color="background_secondary">
              <p class="mb-2 text-h4">
                Basistarif
                {{ tarifSelected.tarif_name }}
              </p>
              <p>(Hausstrom)</p>
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="12" sm="6" md="5" lg="5" xl="5">
            <v-sheet class="pa-9" color="background_primary" height="100%">
              <ul>
                <li>
                  Freie Rückliefermenge:
                  {{ tarifSelected.amount_free }}
                  {{ tarifSelected.amount_free_unit }}
                </li>
              </ul>
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="12" sm="12" md="3" lg="3" xl="3">
            <v-sheet
              :class="`pa-9 ${
                $vuetify.breakpoint.smAndUp ? 'rounded-tr-lg' : ''
              }`"
              color="background_secondary"
              height="100%">
              <p
                class="mb-2 text-h4"
                v-if="tarifSelected && tarifSelected.price">
                {{
                  tarifSelected.price.toFixed(2).toString().replace(".", ",")
                }}
                {{ tarifSelected.price_unit }}
              </p>
              <span>Paketpreis pro Monat</span>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0">
            <v-sheet
              class="rounded-b-lg"
              color="secondary "
              height="5px"
              width="100%"></v-sheet
          ></v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Option SolHeat -->
    <v-row justify="center" class="px-6" v-if="tarifSelected">
      <v-col>
        <h3 class="mb-6">Buchen Sie optional hinzu</h3>
        <v-row>
          <v-col class="pa-0" cols="12" sm="6" md="4" lg="4" xl="4">
            <v-sheet
              height="100%"
              :class="`pa-9 pt-7 ${
                solHeat
                  ? $vuetify.breakpoint.smAndUp
                    ? 'rounded-tl-lg'
                    : 'rounded-t-lg'
                  : $vuetify.breakpoint.smAndUp
                  ? 'rounded-l-lg'
                  : 'rounded-t-lg'
              }`"
              color="background_secondary">
              <v-checkbox color="secondary" v-model="solHeat">
                <template v-slot:label>
                  <p class="mt-3 text-h4 black--text">
                    {{ options.sol_heat.name }}
                  </p>
                </template>
              </v-checkbox>
              <p>(Strom für Wärmepumpe)</p>
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="12" sm="6" md="5" lg="5" xl="5">
            <v-sheet class="pa-9" color="background_primary" height="100%">
              <ul>
                <li>Nutzen Sie Ihren Sonnenstrom auch zum Heizen!</li>
                <li>Wärmepumpe muss über eigenen Stromzähler verfügen.</li>
              </ul>
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="12" sm="12" md="3" lg="3" xl="3">
            <v-sheet
              :class="`pa-9 pt-7 ${
                solHeat
                  ? $vuetify.breakpoint.smAndUp
                    ? 'rounded-tr-lg'
                    : ''
                  : $vuetify.breakpoint.smAndUp
                  ? 'rounded-r-lg'
                  : 'rounded-b-lg'
              }`"
              color="background_secondary"
              height="100%">
              <p class="mb-2 text-h4">
                {{
                  options.sol_heat.price.toFixed(2).toString().replace(".", ",")
                }}
                {{ options.sol_heat.price_unit }}
              </p>
              <span>Paketpreis pro Monat</span>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row v-if="solHeat">
          <v-col class="pa-0">
            <v-sheet
              class="rounded-b-lg"
              color="secondary "
              height="5px"
              width="100%"></v-sheet
          ></v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Option cloud to share -->
    <v-row justify="center" class="px-6 mt-8" v-if="tarifSelected">
      <v-col>
        <v-row>
          <v-col class="pa-0" cols="12" sm="6" md="4" lg="4" xl="4">
            <v-sheet
              height="100%"
              :class="`pa-9 pt-7 ${
                cloudToShare
                  ? $vuetify.breakpoint.smAndUp
                    ? 'rounded-tl-lg'
                    : 'rounded-t-lg'
                  : $vuetify.breakpoint.smAndUp
                  ? 'rounded-l-lg'
                  : 'rounded-t-lg'
              }`"
              color="background_secondary">
              <v-checkbox color="secondary" v-model="cloudToShare">
                <template v-slot:label>
                  <p class="mt-3 text-h4 black--text">
                    {{ options.cloud_to_share.name }}
                  </p>
                </template>
              </v-checkbox>
              <p>(Strom für eine zusätzliche Abnahmestelle)</p>
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="12" sm="6" md="5" lg="5" xl="5">
            <v-sheet class="pa-9" color="background_primary" height="100%">
              <ul>
                <li>
                  Belieferung einer weiteren Abnahmestelle an einem anderen Ort
                </li>
                <li>
                  Nutzen Sie Ihren Sonnenstrom auch für Ihre Angehörigen in der
                  Stadtwohnung
                </li>
                <li>Beachten Sie diese Menge bei der Paketauswahl</li>
              </ul>
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="12" sm="12" md="3" lg="3" xl="3">
            <v-sheet
              :class="`pa-9 pt-7 ${
                cloudToShare
                  ? $vuetify.breakpoint.smAndUp
                    ? 'rounded-tr-lg'
                    : ''
                  : $vuetify.breakpoint.smAndUp
                  ? 'rounded-r-lg'
                  : 'rounded-b-lg'
              }`"
              color="background_secondary"
              height="100%">
              <p class="mb-2 text-h4">
                {{
                  options.cloud_to_share.price
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")
                }}
                {{ options.cloud_to_share.price_unit }}
              </p>
              <span>Paketpreis pro Monat</span>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row v-if="cloudToShare">
          <v-col class="pa-0">
            <v-sheet
              class="rounded-b-lg"
              color="secondary "
              height="5px"
              width="100%"></v-sheet
          ></v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Option cloud to car -->
    <v-row justify="center" class="px-6 mt-8" v-if="tarifSelected">
      <v-col>
        <v-row>
          <v-col class="pa-0" cols="12" sm="6" md="4" lg="4" xl="4">
            <v-sheet
              height="100%"
              :class="`pa-9 pt-7 ${
                cloudToCar
                  ? $vuetify.breakpoint.smAndUp
                    ? 'rounded-tl-lg'
                    : 'rounded-t-lg'
                  : $vuetify.breakpoint.smAndUp
                  ? 'rounded-l-lg'
                  : 'rounded-t-lg'
              }`"
              color="background_secondary">
              <v-checkbox color="secondary" v-model="cloudToCar">
                <template v-slot:label>
                  <p class="mt-3 text-h4 black--text">
                    {{ options.cloud_to_car.name }}
                  </p>
                </template>
              </v-checkbox>
              <p>(Strom für Wallbox)</p>
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="12" sm="6" md="5" lg="5" xl="5">
            <v-sheet class="pa-9" color="background_primary" height="100%">
              <ul>
                <li>
                  Nutzen Sie Ihren Sonnenstrom zur Beladung Ihres E-Autos zu
                  Hause
                </li>
                <li>
                  Wallbox muss eigenen Stromzähler und durch den Netzbetreiber
                  unterbrechbar sein
                </li>
              </ul>
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="12" sm="12" md="3" lg="3" xl="3">
            <v-sheet
              :class="`pa-9 pt-7 ${
                cloudToCar
                  ? $vuetify.breakpoint.smAndUp
                    ? 'rounded-tr-lg'
                    : ''
                  : $vuetify.breakpoint.smAndUp
                  ? 'rounded-r-lg'
                  : 'rounded-b-lg'
              }`"
              color="background_secondary"
              height="100%">
              <p class="mb-2 text-h4">
                {{
                  options.cloud_to_car.price
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")
                }}
                {{ options.cloud_to_car.price_unit }}
              </p>
              <span>Paketpreis pro Monat</span>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row v-if="cloudToCar">
          <v-col class="pa-0">
            <v-sheet
              class="rounded-b-lg"
              color="secondary "
              height="5px"
              width="100%"></v-sheet
          ></v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Option  best price guarantee -->
    <v-row justify="center" class="px-6 mt-8" v-if="tarifSelected">
      <v-col>
        <v-row>
          <v-col class="pa-0" cols="12" sm="6" md="4" lg="4" xl="4">
            <v-sheet
              height="100%"
              :class="`pa-9 pt-7 ${
                bestPriceGuarantee
                  ? $vuetify.breakpoint.smAndUp
                    ? 'rounded-tl-lg'
                    : 'rounded-t-lg'
                  : $vuetify.breakpoint.smAndUp
                  ? 'rounded-l-lg'
                  : 'rounded-t-lg'
              }`"
              color="background_secondary">
              <v-checkbox color="secondary" v-model="bestPriceGuarantee">
                <template v-slot:label>
                  <p class="mt-3 text-h4 black--text">
                    {{ options.best_price_guarantee.name }}
                  </p>
                </template>
              </v-checkbox>
              <p></p>
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="12" sm="6" md="5" lg="5" xl="5">
            <v-sheet class="pa-9" color="background_primary" height="100%">
              <ul>
                <li>
                  Automatische Einstufung in den jeweils günstigsten Tarif
                </li>
                <li>Zahlen Sie nie zu viel!</li>
                <li>Nie mehr an die Stromrechnung denken!</li>
              </ul>
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="12" sm="12" md="3" lg="3" xl="3">
            <v-sheet
              :class="`pa-9 pt-7 ${
                bestPriceGuarantee
                  ? $vuetify.breakpoint.smAndUp
                    ? 'rounded-tr-lg'
                    : ''
                  : $vuetify.breakpoint.smAndUp
                  ? 'rounded-r-lg'
                  : 'rounded-b-lg'
              }`"
              color="background_secondary"
              height="100%">
              <p class="mb-2 text-h4">
                {{
                  options.best_price_guarantee.price
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")
                }}
                {{ options.best_price_guarantee.price_unit }}
              </p>
              <span>Paketpreis pro Jahr</span>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row v-if="bestPriceGuarantee">
          <v-col class="pa-0">
            <v-sheet
              class="rounded-b-lg"
              color="secondary "
              height="5px"
              width="100%"></v-sheet
          ></v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Option zero cost cloud -->
    <v-row
      justify="center"
      class="px-6 mt-8"
      v-if="
        tarifSelected &&
        Number(props.photovoltaic.replace(/,/g, '.')) >= minimumValueP
      ">
      <v-col>
        <v-row>
          <v-col class="pa-0" cols="12" sm="6" md="4" lg="4" xl="4">
            <v-sheet
              height="100%"
              :class="`pa-9 pt-7 ${
                zeroCostCloud
                  ? $vuetify.breakpoint.smAndUp
                    ? 'rounded-tl-lg'
                    : 'rounded-t-lg'
                  : $vuetify.breakpoint.smAndUp
                  ? 'rounded-l-lg'
                  : 'rounded-t-lg'
              }`"
              color="background_secondary">
              <v-checkbox color="secondary" v-model="zeroCostCloud">
                <template v-slot:label>
                  <p class="mt-3 text-h4 black--text">
                    {{ options.zero_cost_cloud.name }}
                  </p>
                </template>
              </v-checkbox>
              <p>(Individuelle Prüfung nach Abschluss)</p>
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="12" sm="6" md="5" lg="5" xl="5">
            <v-sheet class="pa-9" color="background_primary" height="100%">
              <ul>
                <li>Gefühl von Strom kostenlos genießen</li>
                <li>Abschläge bis Jahresende entfallen</li>
                <li>Verrechnung erfolgt über Jahresendabrechnung</li>
              </ul>
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="12" sm="12" md="3" lg="3" xl="3">
            <v-sheet
              :class="`pa-9 pt-7 ${
                zeroCostCloud
                  ? $vuetify.breakpoint.smAndUp
                    ? 'rounded-tr-lg'
                    : ''
                  : $vuetify.breakpoint.smAndUp
                  ? 'rounded-r-lg'
                  : 'rounded-b-lg'
              }`"
              color="background_secondary"
              height="100%">
              <p class="mb-2 text-h4">
                {{
                  options.zero_cost_cloud.price
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")
                }}
                {{ options.zero_cost_cloud.price_unit }}
              </p>
              <span>Paketpreis pro Jahr</span>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row v-if="zeroCostCloud">
          <v-col class="pa-0">
            <v-sheet
              class="rounded-b-lg"
              color="secondary "
              height="5px"
              width="100%"></v-sheet
          ></v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="mx-3 mt-8" v-if="tarifSelected">
      <v-col align="right">
        <v-btn @click="book" class="px-6 py-8" color="secondary"
          >Jetzt Buchen</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { getPackagePrices } from "../../api/api";

export default {
  props: ["props", "edit", "index"],
  data() {
    return {
      tarifSelected: null,
      solHeat: false,
      cloudToShare: false,
      cloudToCar: false,
      bestPriceGuarantee: false,
      zeroCostCloud: false,
      options: {},
    };
  },
  mounted() {
    getPackagePrices(this.props.bdew_code, this.props.zip_code)
      .then(res => {
        this.options = res.data;
        this.scrollToRecommended();
      })
      .catch(() => {});
  },
  methods: {
    selectTarif(tarif) {
      this.tarifSelected = tarif;
      setTimeout(() => {
        this.$vuetify.goTo("#options", { offset: 100 });
      }, 200);
    },
    book() {
      let tarifAndOptions = {
        isSelected: true,
        information: {
          consumption: this.props.consumption,
          photovoltaic: this.props.photovoltaic,
          bdew_code: this.props.bdew_code,
          zip_code: this.props.zip_code,
        },
        tarif: this.tarifSelected,
        tarifType: "cloud",
        options: {
          ...this.options,
          pv: true,
          solHeat: this.solHeat,
          cloudToShare: this.cloudToShare,
          cloudToCar: this.cloudToCar,
          bestPriceGuarantee: this.bestPriceGuarantee,
          zeroCostCloud: this.zeroCostCloud,
        },
      };
      this.$store.commit("setTarifAndOptions", tarifAndOptions);
      this.$router.push({ path: "checkout" });
    },
    scrollToRecommended() {
      setTimeout(() => {
        this.$vuetify.goTo("#recommended", { offset: 130 });
      }, 400);
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
    minimumValueP() {
      if (!this.tarifSelected) return 0;
      switch (this.tarifSelected.tarif_name) {
        case "Alex":
          return 4.5;
        case "Britta":
          return 8;
        case "Charly":
          return 9.9;
        case "Doris":
          return 11.5;
        case "Elke":
          return 17.5;
        case "Frederike":
          return 25;
        case "Greta":
          return 28.8;
        case "Heike":
          return 35;
        case "Igrid":
          return 35;
        case "Jana":
          return 40;
        case "Katrin":
          return 40;
        default:
          break;
      }
      return 8;
    },
  },
};
</script>

<style></style>
