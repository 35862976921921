<template>
  <v-container fluid class="pa-0">
    <div v-if="selectedObject">
      <div
        v-for="(component, index) in selectedObject.components"
        :key="component.id"
        style="position: relative">
        <div
          class="px-1"
          style="
            position: absolute;
            z-index: 10;
            background-color: rgba(0, 0, 0, 0.6);
          ">
          <v-icon color="white" @click="moveComponentUp(index)"
            >mdi-arrow-up-thick</v-icon
          >
          <v-icon color="white" @click="moveComponentDown(index)"
            >mdi-arrow-down-thick</v-icon
          >
          <v-icon color="white" @click="remove(index)">mdi-minus-thick</v-icon>
          <v-icon color="white" @click="openDialogAdd(index)"
            >mdi-plus-thick</v-icon
          >
          <v-icon color="white" @click="edit(component.id)">mdi-pencil</v-icon>
        </div>
        <component
          :is="component.component"
          :props="component.props"
          :key="component.id"
          :index="index"
          :componentId="component.id"
          :edit="openEditDialogById[component.id]"
          @cancel="cancel(component.id)"
          @save="save"></component>
      </div>
      <!-- If no components -->
      <div v-if="selectedObject.components.length == 0">
        <div
          class="px-1"
          style="position: absolute; z-index: 10; background-color: #4caf50">
          <v-icon color="white" @click="moveComponentUp(0)"
            >mdi-arrow-up-thick</v-icon
          >
          <v-icon color="white" @click="moveComponentDown(0)"
            >mdi-arrow-down-thick</v-icon
          >
          <v-icon color="white" @click="remove(0)">mdi-minus-thick</v-icon>
          <v-icon color="white" @click="openDialogAdd(0)"
            >mdi-plus-thick</v-icon
          >
          <v-icon color="white" @click="edit(0)">mdi-pencil</v-icon>
        </div>
      </div>
      <!-- Add dialog -->
      <v-dialog persistent v-model="dialogAdd" fullscreen>
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Select a component to add
          </v-card-title>

          <v-card-text>
            <v-select
              class="mt-4"
              v-model="componentAdd"
              :items="componentsAll"
              label="Select component"></v-select>
            <p v-if="componentAdd" class="mt-1 mb-4">Preview:</p>
            <component
              :is="componentAdd"
              :props="selectedDefaultProps"></component>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="add"> Add </v-btn>
            <v-btn color="error" text @click="dialogAdd = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import { getDefaultProps } from "../helper";

export default {
  props: ["object"],
  data() {
    return {
      selectedObject: null,
      selectedObjectComponents: null,
      openEditDialogById: {},
      dialogAdd: false,
      positionAdd: 0,
      idSeq: null,
      componentsAll: [],
      componentAdd: null,
    };
  },
  computed: {
    selectedDefaultProps: function () {
      return getDefaultProps(this.componentAdd);
    },
  },
  mounted() {
    this.componentsAll = this.$store.getters.getComponentsAll;

    if (this.object.type == "page" || this.object.type == "press") {
      this.selectedObject = JSON.parse(JSON.stringify(this.object));
      for (
        let index = 0;
        index < this.selectedObject.components.length;
        index++
      ) {
        const component = this.selectedObject.components[index];
        component.id = index;
      }
      this.idSeq = this.selectedObject.components.length;
    }
  },
  methods: {
    moveComponentDown(index) {
      if (index + 1 >= this.selectedObject.components.length) {
        return;
      }
      let cmpMove = this.selectedObject.components[index];

      this.$set(
        this.selectedObject.components,
        index,
        this.selectedObject.components[index + 1]
      );
      this.$set(this.selectedObject.components, index + 1, cmpMove);
    },
    moveComponentUp(index) {
      if (index == 0) {
        return;
      }
      let cmpMove = this.selectedObject.components[index];

      this.$set(
        this.selectedObject.components,
        index,
        this.selectedObject.components[index - 1]
      );
      this.$set(this.selectedObject.components, index - 1, cmpMove);
    },
    remove(index) {
      this.selectedObject.components.splice(index, 1);
    },
    add() {
      let component = {
        component: this.componentAdd,
        props: getDefaultProps(this.componentAdd),
        id: this.idSeq,
      };
      this.idSeq++;
      this.selectedObject.components.splice(this.positionAdd + 1, 0, component);
      this.dialogAdd = false;
    },
    edit(componentId) {
      this.$set(this.openEditDialogById, componentId, true);
    },
    save(props, index, componentId) {
      this.$set(
        this.selectedObject.components[index],
        "props",
        JSON.parse(JSON.stringify(props))
      );
      this.$emit("objectGotEdited", this.selectedObject);
      this.$set(this.openEditDialogById, componentId, false);
    },
    cancel(componentId) {
      this.$set(this.openEditDialogById, componentId, false);
    },
    openDialogAdd(index) {
      this.dialogAdd = true;
      this.positionAdd = index;
    },
  },
};
</script>

<style></style>
