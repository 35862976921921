<template>
  <v-sheet
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`"
    id="ConnSpace"
    :color="props.color_background"
    :height="props.height">
    <!-- Edit dialog -->
    <v-dialog v-if="edit" persistent v-model="edit" fullscreen>
      <v-card>
        <v-card-title class="text-h5 black white--text">
          Edit Component
        </v-card-title>
        <v-card-text class="pt-5">
          <Core
            name="Core"
            :editProps="editProps"
            @emitProps="setPreviewProps"
            @save="save"
            @cancel="cancel" />
          <!-- Preview -->
          <h2 class="my-2 text-h4 black--text">Preview:</h2>
          <ConnSpace
            v-if="previewProps"
            name="ConnSpace"
            :props="previewProps" />
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import Core from "../../editor/Core";
import ConnSpace from "./ConnSpace";

export default {
  name: "ConnSpace",
  props: ["props", "edit", "index", "componentId"],
  data() {
    return {
      editProps: {},
      previewProps: null,
    };
  },
  watch: {
    edit: function () {
      this.editProps.color_background = {};
      this.editProps.color_background.type = "color";
      this.editProps.color_background.name = "color_background";
      this.editProps.color_background.value =
        this.props.color_background ?? "#FFFFFF";

      this.editProps.pt = this.props.pt ?? 0;
      this.editProps.pb = this.props.pb ?? 0;

      this.editProps.height = {};
      this.editProps.height.type = "number";
      this.editProps.height.name = "height";
      this.editProps.height.value = this.props.height ?? 100;
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
      this.$emit("save", this.previewProps, this.index, this.componentId);
    },
    cancel() {
      this.$emit("cancel");
    },
    setPreviewProps(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
    },
  },
  components: {
    Core,
    ConnSpace,
  },
};
</script>

<style></style>
