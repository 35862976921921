<template>
  <v-sheet
    :id="`${props.target}`"
    :color="props.color_background"
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`"
    :style="`background-image: url(${props.img_href});background-position: center;`">
    <v-container fluid v-if="props" class="container-padding">
      <v-row justify="center" align="center" class="py-6">
        <!-- Text -->
        <v-col cols="12" sm="6" md="6" lg="6">
          <h1
            v-if="
              !$vuetify.breakpoint.xs &&
              props.headline &&
              props.headline[language]
            "
            v-html="props.headline[language]"></h1>
          <center v-if="$vuetify.breakpoint.xs">
            <h1
              v-if="props.headline && props.headline[language]"
              class="white--text"
              v-html="props.headline[language]"></h1>
          </center>
          <v-img
            v-if="props.image"
            class="mt-15"
            max-width="160"
            src="https://www.veganstrom.com/wp-content/themes/veganstrom-theme-v2/img/oval-landschaft@2x.png"></v-img>
        </v-col>
        <!-- Button -->
        <v-col cols="12" sm="6" md="6" lg="6" align="right">
          <v-btn
            v-if="props.button_text && props.button_text[language]"
            v-html="props.button_text[language]"
            class="px-6 py-7"
            color="white"
            :to="props.link"></v-btn>
        </v-col>
      </v-row>

      <!-- Edit dialog -->
      <v-dialog v-if="edit" persistent v-model="edit" fullscreen>
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Component
          </v-card-title>
          <v-card-text class="pt-5">
            <Core
              :editProps="editProps"
              @emitProps="setPreviewProps"
              @save="save"
              @cancel="cancel" />
            <!-- Preview -->
            <h2 class="my-2 text-h4 black--text">Preview:</h2>
            <CompBannerToTarif
              v-if="previewProps"
              name="CompBannerToTarif"
              :props="previewProps" />
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import CompBannerToTarif from "./CompBannerToTarif";
import Core from "../editor/Core.vue";

export default {
  name: "CompBannerToTarif",
  props: ["props", "edit", "index", "componentId"],
  data() {
    return {
      editProps: {},
      previewProps: null,
    };
  },
  watch: {
    edit: function () {
      this.editProps.color_background = {};
      this.editProps.color_background.type = "color";
      this.editProps.color_background.name = "color_background";
      this.editProps.color_background.value =
        this.props.color_background ?? "#FFFFFF";

      this.editProps.target = this.props.target ?? "";
      this.editProps.pt = this.props.pt ?? 0;
      this.editProps.pb = this.props.pb ?? 0;

      this.editProps.button_text = {};
      this.editProps.button_text.type = "text";
      this.editProps.button_text.name = "button_text";
      this.editProps.button_text.value = this.props.button_text ?? {};

      this.editProps.headline = {};
      this.editProps.headline.type = "text";
      this.editProps.headline.name = "headline";
      this.editProps.headline.value = this.props.headline ?? {};

      this.editProps.img_href = {};
      this.editProps.img_href.type = "image";
      this.editProps.img_href.name = "img_href";
      this.editProps.img_href.value = this.props.img_href ?? "";

      this.editProps.link = {};
      this.editProps.link.type = "link";
      this.editProps.link.name = "link";
      this.editProps.link.value = this.props.link ?? "";
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
      this.$emit("save", this.previewProps, this.index, this.componentId);
    },
    cancel() {
      this.$emit("cancel");
    },
    setPreviewProps(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
    },
  },
  components: {
    Core,
    CompBannerToTarif,
  },
};
</script>

<style></style>
