import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import { getLogoutAuthUrl } from "../auth/auth";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: function (state) {
    return {
      language: state.language,
      code_verifier: state.code_verifier,
      access_token: state.access_token,
      refresh_token: state.refresh_token,
      expires_in: state.expires_in,
      id_token: state.id_token,
      expiration_time: state.expiration_time,
      cookie_banner: state.cookie_banner,
    };
  },
});

const store = new Vuex.Store({
  state: {
    language: null,
    cookie_banner: true,
    config: "",
    componentsAll: [
      "Header",
      "Footer",
      "ConnSpace",
      "CompText",
      "CompText2Cols",
      "CompTextImgText",
      "CompImg",
      "CompTarifrechner",
      "CompInformation",
      "CompInformationTextImage",
      "CompGrid3x3",
      "CompGrid3x1",
      "CompGrid3x1Links",
      "CompInformationToTarif",
      "CompInformationToTarifBigImage",
      "Comp3Steps",
      "CompSeals",
      "CompBannerToTarif",
      "CompSpeechBubble",
      "CompTimeline",
      "CompGrid2x1",
      "CompThgForm",
      "CompTextImageFullWidth",
      "CompTextImageOverlap",
      "CompBgImageButton",
      "CompBlackBox",
      "CompCareerForm",
    ],
    selectedTarifAndOptions: { isSelected: false },
    checkoutData: {},
    showNavbar: false,
    loading: false,
    error: false,
    // auth variables
    code_verifier: "",
    access_token: null,
    refresh_token: "",
    expires_in: "",
    id_token: "",
    expiration_time: "",
  },
  getters: {
    getConfig: state => {
      return state.config;
    },
    getCheckoutData: state => {
      return state.checkoutData;
    },
    getselectedTarifAndOptions: state => {
      return state.selectedTarifAndOptions;
    },
    getLoading: state => {
      return state.loading;
    },
    getShowNavbar: state => {
      return state.showNavbar;
    },
    getLanguage: state => {
      return state.language;
    },
    getComponentsAll: state => {
      return state.componentsAll;
    },
    // auth getters
    code_verifier: state => {
      return state.code_verifier;
    },
    access_token: state => {
      return state.access_token;
    },
    refresh_token: state => {
      return state.refresh_token;
    },
    cookie_banner: state => {
      return state.cookie_banner;
    },
  },
  mutations: {
    setConfig(state, payload) {
      state.config = payload;
      document.title = state.config.title;
    },
    setTarifAndOptions(state, payload) {
      state.selectedTarifAndOptions = payload;
    },
    setVoucherCode(state, payload) {
      state.selectedTarifAndOptions.voucherCode = payload;
    },
    setCheckoutData(state, payload) {
      Vue.set(state.checkoutData, payload.section, payload);
      // state.checkoutData[payload.section] = payload.data;
    },
    setShowNavbar(state, payload) {
      state.showNavbar = payload;
    },
    setLanguage(state, payload) {
      state.language = payload;
    },
    setCookieBanner(state, payload) {
      state.cookie_banner = payload;
    },
    setDefaultLanguage(state, payload) {
      if (state.language) {
        if (state.config.languages) {
          for (let index = 0; index < state.config.languages.length; index++) {
            const language = state.config.languages[index];
            if (language.key == state.language) {
              return;
            }
          }
        }
      }
      console.log("Set to " + payload);
      state.language = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setError() {},
    set_code_verifier(state, payload) {
      state.code_verifier = payload;
    },
    refresh_access_token(state, payload) {
      state.access_token = payload.access_token;
      state.refresh_token = payload.refresh_token;
      state.expires_in = payload.expires_in;
      state.id_token = payload.id_token;
      // Set the expiration datetime of the new access token
      let exp_date = new Date();
      exp_date.setSeconds(exp_date.getSeconds() + payload.expires_in);
      state.expiration_time = exp_date;
    },
    logout(state) {
      state.access_token = "";
      state.refresh_token = "";
      state.expires_in = "";
      state.id_token = "";
      state.expiration_time = "";

      window.location = getLogoutAuthUrl();
    },
    reset_user_data(state) {
      state.access_token = null;
      state.refresh_token = null;
      state.expires_in = null;
      state.id_token = null;
      state.expiration_time = null;
    },
    set_user_data(state, payload) {
      state.access_token = payload.access_token;
      state.refresh_token = payload.refresh_token;
      state.expires_in = payload.expires_in;
      state.id_token = payload.id_token;
      // Set the expiration datetime of the access token
      let exp_date = new Date();
      exp_date.setSeconds(exp_date.getSeconds() + payload.expires_in);
      state.expiration_time = exp_date;
    },
  },
  plugins: [vuexLocal.plugin],
});

export default store;
