<template>
  <v-img
    v-if="props"
    :style="
      props.mobileNoMargin & $vuetify.breakpoint.xs
        ? ``
        : `margin: ${
            props.margins ? (props.margins.mt ? props.margins.mt : 0) : 0
          }px 
                   ${
                     props.margins
                       ? props.margins.mr
                         ? props.margins.mr
                         : 0
                       : 0
                   }px 
                   ${
                     props.margins
                       ? props.margins.mb
                         ? props.margins.mb
                         : 0
                       : 0
                   }px 
                   ${
                     props.margins
                       ? props.margins.ml
                         ? props.margins.ml
                         : 0
                       : 0
                   }px`
    "
    :aspect-ratio="props.aspect_ratio ? props.aspect_ratio : undefined"
    :src="props.url"
    :contain="props.contain ? props.contain : true"
    :height="props.height ? props.height : '100%'"></v-img>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["props"],
};
</script>

<style></style>
