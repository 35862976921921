<template>
  <v-sheet color="background_secondary">
    <v-container fluid class="container-padding pb-6" v-if="pageConfig">
      <v-row>
        <v-col><CustomHeader :props="headerProps" id="header" /></v-col>
      </v-row>
      <ConnSpace
        :props="{ height: '40px', color_background: 'background_secondary' }" />
      <v-row justify="center">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="pa-0">
          <component :is="tariff.header.component"></component>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="12" md="8" lg="8" xl="8">
          <v-stepper class="rounded-lg" v-model="progress" alt-labels>
            <v-stepper-header>
              <template v-for="(site, n) in pages">
                <v-stepper-step
                  :step="n + 1"
                  :key="`${n}-step`"
                  color="secondary">
                  <small v-if="site.optional">Optional</small>
                </v-stepper-step>
                <v-divider :key="n" v-if="n < pages.length - 1"> </v-divider>
              </template>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content
                v-for="(site, n) in pages"
                :step="n + 1"
                :key="`${n}-content`">
                <div :class="$vuetify.breakpoint.mdAndUp ? 'pa-7' : 'pa-0'">
                  <component
                    :is="site.component"
                    :key="`${n + 1}-component`"
                    :ref="`${n + 1}-ref`"
                    @removePage="removePage(site.id)"
                    @ErrorNoTarifOptions="showErrorDialog = true">
                  </component>

                  <br />
                  <v-row justify="space-between">
                    <v-col cols="6">
                      <v-btn
                        v-if="progress != 1"
                        @click="progress = progress - 1">
                        Zurück
                      </v-btn>
                    </v-col>
                    <v-col cols="6" class="d-flex flex-row-reverse">
                      <v-btn
                        color="secondary"
                        v-if="
                          progress != pages.length &&
                          progress != pages.length - 1
                        "
                        @click="validate(n + 1)">
                        Weiter
                      </v-btn>
                      <v-btn
                        color="secondary"
                        v-if="progress == pages.length - 1"
                        @click="validate(n + 1)">
                        Überprüfen und Buchen
                      </v-btn>
                      <v-btn
                        color="secondary"
                        v-if="progress == pages.length"
                        @click="supplyRequest(n + 1)"
                        :disabled="
                          showInfoAlert || showSuccessAlert || loading
                        ">
                        Kostenpflichtig Bestellen
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-alert
                    v-if="showSuccessAlert"
                    class="mt-7"
                    dense
                    text
                    type="success">
                    Ihre Bestellung ist erfolgreich verschickt! Sie werden in 3
                    Sekunden weitergeleitet...
                  </v-alert>
                  <v-alert
                    v-if="showInfoAlert"
                    class="mt-7"
                    dense
                    text
                    type="info">
                    Bestellung wird bearbeitet...
                  </v-alert>
                  <v-alert
                    v-if="showErrorAlert"
                    class="mt-7"
                    dense
                    text
                    type="error">
                    Leider ist ein Fehler aufgetreten. Fehlercode:
                    {{ errorAlertCode }}
                  </v-alert>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <Advantages />
        </v-col>
      </v-row>

      <v-dialog persistent v-model="showErrorDialog" width="40%">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Fehler beim Laden des Formulars
          </v-card-title>

          <v-card-text class="pt-5">
            Hinweis: Bitte laden Sie die Seite während Sie sich im Checkout
            befinden nicht neu, da sonst der Checkoutprozess abgebrochen
            wird!<br /><br />
            Nachdem Sie auf OK geklickt haben, werden Sie auf die Startseite
            weitergeleitet.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="
                showErrorDialog = false;
                $router.push({ path: '/?id=27612' });
              "
              class="secondary">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import CustomHeader from "../components/CustomHeader.vue";
import ConnSpace from "../components/connectors/ConnSpace.vue";
import Advantages from "../components/Internal/Advantages.vue";
import TarifHeader from "../components/Internal/TarifHeader.vue";
import { createSupplyRequest } from "../api/api";

export default {
  data() {
    return {
      headerProps: {
        brand: "newenergycloud",
        calculator: false,
        magazine: false,
        helpAndContact: false,
      },
      footerProps: {
        mode: "dark",
      },
      progress: 1,
      removedPages: [],
      showErrorDialog: false,
      showSuccessAlert: false,
      showInfoAlert: false,
      showErrorAlert: false,
      errorAlertCode: "Unbekannt",
    };
  },
  computed: {
    ...mapState({
      pageConfig: state => {
        return state.config
          ? state.config.pages.filter(page => page.path == "/checkout")[0]
          : null;
      },
      selectedTarifAndOptions: state => {
        return state.selectedTarifAndOptions ?? null;
      },
      loading: state => {
        return state.loading;
      },
    }),
    tariff: function () {
      return this.pageConfig.tariffs[this.selectedTarifAndOptions.tarifType];
    },
    pages: function () {
      if (!this.pageConfig) return [];
      var p = this.tariff.pages.filter(component => {
        return !this.removedPages.includes(component.id);
      });
      return p;
    },
  },
  components: {
    CustomHeader,
    ConnSpace,
    Advantages,
    TarifHeader,
  },
  mounted() {
    setTimeout(() => {
      this.$vuetify.goTo("#header", {});
    }, 100);
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  methods: {
    supplyRequest(n) {
      let ref = n + "-ref";
      if (!this.$refs[ref][0].validate()) return;
      this.$refs[ref][0].saveData();
      this.$store.commit("setLoading", true);
      var response = createSupplyRequest();
      this.showInfoAlert = true;
      this.showSuccessAlert = false;
      this.showErrorAlert = false;
      response.then(data => {
        if (data == "200") {
          this.showSuccessAlert = true;
          this.showInfoAlert = false;
          this.$store.commit("setLoading", false);
          setTimeout(() => {
            this.$router.push({ path: "belieferungsanfrage-erfolgreich" });
          }, 3000);
        } else {
          this.showSuccessAlert = false;
          this.showInfoAlert = false;
          this.errorAlertCode = data;
          this.showErrorAlert = true;
          this.$store.commit("setLoading", false);
        }
      });
    },
    removePage(id) {
      this.removedPages.push(id);
    },
    validate(n) {
      let ref = n + "-ref";
      if (this.$refs[ref][0].validate()) this.progress++;
      this.$refs[ref][0].saveData(n);
    },
    preventNav(event) {
      event.preventDefault();
      event.returnValue = "";
    },
  },
};
</script>

<style></style>
