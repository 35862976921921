<template>
  <v-sheet
    :id="`${props.target}`"
    :color="props.color_background"
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`">
    <v-container fluid v-if="props" class="container-padding">
      <v-row class="px-0" justify="space-between">
        <!-- Text -->
        <v-col class="px-0" cols="12" sm="12" md="5" lg="5" xl="5">
          <h4
            v-if="props.sub_headline && props.sub_headline[language]"
            class="mb-3 text-caption font-weight-bold"
            v-html="props.sub_headline[language]"></h4>
          <h2
            v-if="props.headline && props.headline[language]"
            class="mb-10 text-h5 font-weight-bold"
            v-html="props.headline[language]"></h2>
          <v-img
            class="mb-10"
            v-if="props.headline_img"
            max-height="60px"
            :src="props.headline_img"
            contain></v-img>
          <p
            v-if="props.text && props.text[language]"
            v-html="props.text[language]"></p>
          <!-- Button -->
          <v-row v-if="props.button_text && props.button_text[language]">
            <v-col align="center">
              <a :href="props.button_href">
                <v-btn
                  class="px-4 py-5"
                  color="secondary"
                  v-html="props.button_text[language]"></v-btn
              ></a>
            </v-col>
          </v-row>
          <!-- Image -->
        </v-col>
        <v-col class="px-0" cols="12" sm="12" md="7" lg="7" xl="7">
          <v-img v-if="props.img_href" :src="props.img_href"></v-img>
          <span
            v-if="props.img_source && props.img_source[language]"
            class="text-caption"
            v-html="props.img_source[language]"></span>
        </v-col>
      </v-row>

      <!-- Edit dialog -->
      <v-dialog v-if="edit" persistent v-model="edit" fullscreen>
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Component
          </v-card-title>
          <v-card-text class="pt-5">
            <Core
              name="Core"
              :editProps="editProps"
              @emitProps="setPreviewProps"
              @save="save"
              @cancel="cancel" />
            <!-- Preview -->
            <h2 class="my-2 text-h4 black--text">Preview:</h2>
            <CompInformationTextImage
              v-if="previewProps"
              name="CompInformationTextImage"
              :props="previewProps" />
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import CompInformationTextImage from "./CompInformationTextImage";
import Core from "../editor/Core.vue";

export default {
  name: "CompInformationTextImage",
  props: ["props", "edit", "index", "componentId"],
  data() {
    return {
      editProps: {},
      previewProps: null,
    };
  },
  watch: {
    edit: function () {
      this.editProps.target = this.props.target ?? "";
      this.editProps.pt = this.props.pt ?? 0;
      this.editProps.pb = this.props.pb ?? 0;

      this.editProps.color_background = {};
      this.editProps.color_background.type = "color";
      this.editProps.color_background.name = "color_background";
      this.editProps.color_background.value =
        this.props.color_background ?? "#FFFFFF";

      this.editProps.sub_headline = {};
      this.editProps.sub_headline.type = "text";
      this.editProps.sub_headline.name = "sub_headline";
      this.editProps.sub_headline.value = this.props.sub_headline ?? {};

      this.editProps.headline = {};
      this.editProps.headline.type = "text";
      this.editProps.headline.name = "headline";
      this.editProps.headline.value = this.props.headline ?? {};

      this.editProps.text = {};
      this.editProps.text.type = "text";
      this.editProps.text.name = "text";
      this.editProps.text.value = this.props.text ?? {};

      this.editProps.button_text = {};
      this.editProps.button_text.type = "text";
      this.editProps.button_text.name = "button_text";
      this.editProps.button_text.value = this.props.button_text ?? {};

      this.editProps.headline_img = {};
      this.editProps.headline_img.type = "image";
      this.editProps.headline_img.name = "headline_img";
      this.editProps.headline_img.value = this.props.headline_img ?? "";

      this.editProps.img_href = {};
      this.editProps.img_href.type = "image";
      this.editProps.img_href.name = "img_href";
      this.editProps.img_href.value = this.props.img_href ?? "";

      this.editProps.button_href = {};
      this.editProps.button_href.type = "link";
      this.editProps.button_href.name = "button_href";
      this.editProps.button_href.value = this.props.button_href ?? {};
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
      this.$emit("save", this.previewProps, this.index, this.componentId);
    },
    cancel() {
      this.$emit("cancel");
    },
    setPreviewProps(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
    },
  },
  components: {
    Core,
    CompInformationTextImage,
  },
};
</script>
