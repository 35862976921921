<template>
  <v-sheet
    :id="`${props.target}`"
    :color="props.color_background"
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`">
    <v-container fluid v-if="props" class="container-padding">
      <v-row justify="center">
        <!-- Headline -->
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <h3
            v-if="props.sub_headline && props.sub_headline[language]"
            v-html="props.sub_headline[language]"></h3>
          <h2
            v-if="props.headline && props.headline[language]"
            v-html="props.headline[language]"></h2>
        </v-col>
      </v-row>
      <v-row justify="center">
        <!-- Text -->
        <v-col cols="12" sm="5" md="5" lg="5" xl="5">
          <p
            class="mt-12"
            v-if="props.text && props.text[language]"
            v-html="props.text[language]"></p>
          <!-- Button -->
          <v-btn
            v-if="props.button_text && props.button_text[language]"
            class="mt-10 px-4 py-5"
            color="secondary"
            v-html="props.button_text[language]"
            to="tarifrechner"></v-btn>
          <!-- Image -->
        </v-col>
        <v-col cols="12" sm="7" md="7" lg="7" xl="7">
          <v-img
            class="mt-15"
            v-if="props.img_href"
            :src="props.img_href"></v-img>
          <span
            class="text-caption"
            v-if="props.img_source && props.img_source[language]"
            v-html="props.img_source[language]"></span>
        </v-col>
      </v-row>

      <!-- Edit dialog -->
      <v-dialog v-if="edit" persistent v-model="edit" fullscreen>
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Component
          </v-card-title>
          <v-card-text class="pt-5">
            <Core
              :editProps="editProps"
              @emitProps="setPreviewProps"
              @save="save"
              @cancel="cancel"
              name="Core" />
            <!-- Preview -->
            <h2 class="my-2 text-h4 black--text">Preview:</h2>
            <CompInformationToTarif
              v-if="previewProps"
              name="CompInformationToTarif"
              :props="previewProps" />
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import CompInformationToTarif from "./CompInformationToTarif";
import Core from "../editor/Core.vue";

export default {
  name: "CompInformationToTarif",
  props: ["props", "edit", "index", "componentId"],
  data() {
    return {
      editProps: {},
      previewProps: null,
    };
  },
  watch: {
    edit: function () {
      this.editProps.color_background = {};
      this.editProps.color_background.type = "color";
      this.editProps.color_background.name = "color_background";
      this.editProps.color_background.value =
        this.props.color_background ?? "#FFFFFF";

      this.editProps.target = this.props.target ?? "";
      this.editProps.pt = this.props.pt ?? 0;
      this.editProps.pb = this.props.pb ?? 0;

      this.editProps.headline = {};
      this.editProps.headline.type = "text";
      this.editProps.headline.name = "headline";
      this.editProps.headline.value = this.props.headline ?? {};

      this.editProps.sub_headline = {};
      this.editProps.sub_headline.type = "text";
      this.editProps.sub_headline.name = "sub_headline";
      this.editProps.sub_headline.value = this.props.sub_headline ?? {};

      this.editProps.text = {};
      this.editProps.text.type = "text";
      this.editProps.text.name = "text";
      this.editProps.text.value = this.props.text ?? {};

      this.editProps.button_text = {};
      this.editProps.button_text.type = "text";
      this.editProps.button_text.name = "button_text";
      this.editProps.button_text.value = this.props.button_text ?? {};

      this.editProps.img_href = {};
      this.editProps.img_href.type = "image";
      this.editProps.img_href.name = "img_href";
      this.editProps.img_href.value = this.props.img_href ?? "";
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
      this.$emit("save", this.previewProps, this.index, this.componentId);
    },
    cancel() {
      this.$emit("cancel");
    },
    setPreviewProps(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
    },
  },
  components: {
    Core,
    CompInformationToTarif,
  },
};
</script>
