<template>
  <div id="CustomFooter" v-if="props">
    <v-sheet :color="props.color" elevation="0">
      <v-footer padless :color="props.color">
        <v-container fluid class="container-padding">
          <v-card flat tile width="100%" :color="props.color">
            <v-card-text :class="`pt-8 ${props.mode == 'dark' ? 'white--text' : ''}`">
              <!-- Columns -->
              <v-row justify="space-between">
                <v-col cols="12" sm="6" md="4" lg="3" xl="3">
                  <span class="text-subtitle-1">
                    <v-icon :color="`${props.mode == 'dark' ? 'white' : ''}`" class="mr-3">mdi-phone-outline</v-icon><span
                      v-html="props.phone"></span>
                  </span>
                  <br />
                  <span class="text-subtitle-1">
                    <v-icon :color="`${props.mode == 'dark' ? 'white' : ''}`" class="mr-3">mdi-email-outline</v-icon><span
                      v-html="props.email"></span>
                  </span>
                  <br />
                  <br />
                  <span class="text-subtitle-1">
                    GreenStone Energy GmbH <br />
                    Manteuffelstrasse 77 <br />
                    <br />
                    10999 Berlin <br />
                    Deutschland <br />
                  </span>
                </v-col>
                <v-col v-for="(column, index) in props.columns" :key="index" class="text-subtitle-1" cols="12" sm="6"
                  md="4" lg="4" xl="4">
                  <b>{{ column.headline[language] }}</b>
                  <br />
                  <br />
                  <div v-for="(link, index) in column.links" :key="index">
                    <a :href="link.url" :style="`color: ${props.mode == 'dark' ? 'white' : 'black'
                      };`">{{ link.text[language] }}</a>
                  </div>
                  <v-img class="mt-2" v-if="props.image" max-width="350px" :src="props.image_url"></v-img>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>
            <!-- Company copyright -->
            <v-card-text>
              <v-row>
                <v-col cols="12" :class="`text-center ${props.mode == 'dark' ? 'grey--text' : ''
                  }`">
                  © GreenStone Energy {{ new Date().getFullYear() }} – Alle Rechte vorbehalten
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </v-footer>
    </v-sheet>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      props: state => {
        if (!state || !state.config || !state.config.common_components)
          return null;
        return state.config.common_components["custom_footer"];
      },
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
};
</script>
