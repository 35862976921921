<template>
  <v-app>
    <!-- navbar -->
    <CustomHeaderMobile />
    <CustomHeader v-if="$router.currentRoute.path != '/editor'" />

    <v-main :style="`background: ${mainBackgroundColor}`">
      <router-view></router-view>
    </v-main>

    <CustomFooter v-if="$router.currentRoute.path != '/editor'" />
    <CookieBanner />
    <!-- Loader -->
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card dark color="accent">
        <v-card-text>
          <p class="pt-2 ft-500-dark">Daten werden geladen...</p>
          <v-progress-linear indeterminate color="white" class="mb-0">
          </v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import CustomHeaderMobile from "./components/Internal/CustomHeaderMobile.vue";
import CookieBanner from "./components/Internal/CookieBanner.vue";
import { scrollIntoView } from "./helper";
export default {
  name: "App",
  data() {
    return {
      mainBackgroundColor: "white",
    };
  },
  mounted() {
    this.setBrand();
    if (this.$route.query.target) scrollIntoView(this.$route.query.target);
  },
  computed: {
    loading: {
      // getter
      get: function () {
        return this.$store.getters.getLoading;
      },
      // setter
      set: function (newValue) {
        this.$store.commit("setLoading", newValue);
      },
    },
  },
  watch: {
    "$route.query.target"() {
      if (this.$route.query.target) scrollIntoView(this.$route.query.target);
    },
  },
  methods: {
    setBrand() {
      /* eslint-disable-next-line */
      let brand = config.VUE_APP_ENV_BRAND || process.env.VUE_APP_ENV_BRAND;
      // aws config
      let configUrl = `https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/${brand}/config/${brand}.json`;
      let that = this;
      /* eslint-disable-next-line */
      $.getJSON(configUrl, function (data) {
        let config = data;
        that.$store.commit("setConfig", config);
        that.$store.commit("setDefaultLanguage", config.language_default);
        that.setTheme(config.theme);
        // const favicon = document.getElementById("favicon");
        // favicon.href = config.favicon;
        that.setMainBackgroundColor(config);
      });
    },
    setMainBackgroundColor(config) {
      for (let index = 0; index < config.pages.length; index++) {
        const page = config.pages[index];
        if (page.page == this.$router.currentRoute.path.replace("/", "")) {
          const component = page.components[page.components.length - 1];
          if (!component.props || !component.props.color_background) return;
          this.mainBackgroundColor = component.props.color_background;
        }
      }
    },
    setTheme(theme) {
      this.$vuetify.theme.themes.light.primary = theme.primary;
      this.$vuetify.theme.themes.light.secondary = theme.secondary;
      this.$vuetify.theme.themes.light.accent = theme.accent;
      this.$vuetify.theme.themes.light.text_light = theme.text_light;
      this.$vuetify.theme.themes.light.background_primary =
        theme.background_primary;
      this.$vuetify.theme.themes.light.background_secondary =
        theme.background_secondary;
    },
  },
  components: { CustomHeaderMobile, CookieBanner },
};
</script>

<style lang="sass">
@import '../node_modules/typeface-roboto/index.css'
</style>

<style>
a {
  text-decoration: none;
}
html {
  scroll-behavior: smooth;
}

.container-padding {
  padding: 0rem 35rem !important;
}

@media (max-width: 2300px) {
  .container-padding {
    padding: 0rem 25rem !important;
  }
}
@media (max-width: 1904px) {
  .container-padding {
    padding: 0rem 20rem !important;
  }
}
@media (max-width: 1600px) {
  .container-padding {
    padding: 0rem 15rem !important;
  }
}
@media (max-width: 1400px) {
  .container-padding {
    padding: 0rem 10rem !important;
  }
}
@media (max-width: 1264px) {
  .container-padding {
    padding: 0rem 4rem !important;
  }
}
@media (max-width: 960px) {
  .container-padding {
    padding: 0rem 2rem !important;
  }
}
@media (max-width: 600px) {
  .container-padding {
    padding: 0rem 1rem !important;
  }
}
</style>
