<template>
  <v-sheet>
    <h1 class="text-h5">Überprüfen und Buchen</h1>
    <div v-if="checkoutData">
      <div
        v-for="[sectionKey, section] in Object.entries(checkoutData)"
        :key="sectionKey"
        class="mt-5">
        <h2 class="text-h6">{{ section.data.text }}</h2>
        <div class="text--primary" v-if="section.enabled">
          <div
            v-for="[groupKey, group] in Object.entries(section.data).filter(
              group => group[0] != 'text'
            )"
            :key="groupKey">
            <h3 class="text-subtitle-1 mt-5 mb-1">{{ group.text }}</h3>
            <table class="form-overview--table" cellpadding="0" cellspacing="0">
              <tr
                v-for="[itemKey, item] in Object.entries(group).filter(
                  group => group[0] != 'text'
                )"
                :key="itemKey">
                <td style="width: 40%">
                  <b>{{ item.text }}</b>
                </td>
                <td>{{ item.value == "" ? "-" : item.value }}</td>
              </tr>
            </table>
          </div>
          <!-- <v-row>
            <v-col
              v-for="[groupKey, group] in Object.entries(section.data).filter(
                group => group[0] != 'text'
              )"
              :key="groupKey"
              cols="12"
              sm="6">
              <h3 class="text-subtitle-1">{{ group.text }}</h3>
              <br />
              <ul>
                <div
                  v-for="[itemKey, item] in Object.entries(group).filter(
                    group => group[0] != 'text'
                  )"
                  :key="itemKey">
                  <li>
                    <span class="text-overline font-weight-bold">{{
                      item.text
                    }}</span
                    ><br />
                    <span>{{ item.value == "" ? "-" : item.value }}</span>
                  </li>
                </div>
              </ul>
            </v-col>
          </v-row> -->
        </div>
        <div class="text--primary" v-else>
          <v-row>
            <v-col>
              <p>Nicht ausgewählt</p>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mt-10 mb-5"></v-divider>
      </div>
      <v-form ref="formOverview">
        <v-row>
          <v-col>
            <v-text-field
              v-model="voucherCode"
              label="Gutscheincode"></v-text-field>
            <v-checkbox
              v-model="contractConditionAgreement"
              :rules="[v => !!v || 'Bitte bestätigen um fortzufahren!']"
              required>
              <span slot="label"
                >Ich habe die
                <router-link class="secondary--text" to="/agb">
                  Vertrags- und Allgemeinen Geschäftsbedingungen
                </router-link>
                des Angebotes „NewEnergyCloud“ gelesen und akzeptiert.*
              </span>
            </v-checkbox>
            <v-checkbox
              v-model="authorityAgreement"
              label="Ich möchte den bisherigen Stromliefervertrag gemäß Ziffer 3 („Hausstrom“) kündigen und ggf. den bisherigen Stromliefervertrag gemäß Ziffer 4 zum nächstmöglichen Termin kündigen und mit Strom durch GreenStone Energy GmbH beliefert werden. Und erteile entsprechende Vollmacht.*"
              :rules="[v => !!v || 'Bitte bestätigen um fortzufahren!']"
              required></v-checkbox>
            <v-checkbox
              v-model="privacyAgreement"
              :rules="[v => !!v || 'Bitte bestätigen um fortzufahren!']"
              required>
              <span slot="label"
                >Ich akzeptiere den
                <router-link
                  class="secondary--text"
                  target="_blank"
                  to="/datenschutz">
                  Datenschutzhinweis
                </router-link>
                .*
              </span>
            </v-checkbox>
            <v-checkbox
              v-model="cancelationAgreement"
              :rules="[v => !!v || 'Bitte bestätigen um fortzufahren!']"
              required>
              <span slot="label"
                >Ich habe die
                <router-link class="secondary--text" to="/widerruf">
                  Widerrufsbelehrung
                </router-link>
                gelesen und akzeptiert.*
              </span>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      voucherCode: "",
      contractConditionAgreement: 0,
      authorityAgreement: 0,
      privacyAgreement: 0,
      cancelationAgreement: 0,
    };
  },
  computed: {
    ...mapState({
      checkoutData: state => {
        return state.checkoutData ?? null;
      },
    }),
  },
  methods: {
    validate() {
      return this.$refs.formOverview.validate();
    },
    reset() {
      this.$refs.formOverview.reset();
    },
    resetValidation() {
      this.$refs.formOverview.resetValidation();
    },
    saveData() {
      this.$store.commit("setVoucherCode", this.voucherCode);
    },
  },
};
</script>

<style>
.form-overview--table {
  width: 100%;
}
.form-overview--table tr:nth-child(2n-1) {
  background-color: rgb(230, 230, 230);
  border-collapse: collapse !important;
}
</style>
