<template>
  <div>
    <h1 class="text-h5 pb-10">Daten zur Wallbox</h1>
    <v-form ref="form">
      <v-checkbox
        v-model="checkbox"
        required
        label="Ich habe eine Wallbox für mein Elektro-Auto, die bereits mit einem separaten unterbrechbaren Stromzähler versehen ist."
        :rules="[v => !!v || 'Bitte bestätigen!']"></v-checkbox>
      <h3>STROMDATEN</h3>
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.energyData.currentSupplier.value"
            label="Bisheriger Stromlieferant (Wallbox)*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.energyData.customerNumberOld.value"
            label="Kundennummer Wallbox"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="form.energyData.meteringNumber.value"
            label="Zählernummer*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
      </v-row>

      <h3 class="pt-5">WECHSELGRUND*</h3>
      <v-row>
        <v-col>
          <v-radio-group v-model="changeReason" column class="mt-1" mandatory>
            <v-radio
              v-for="(item, index) in changeReasonItems"
              :label="item.name"
              :value="item.value"
              :key="`${index}-change-reason`"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="form.changeReason.date.value"
            :label="
              changeReason != -1
                ? changeReasonItems[changeReason - 1].requiredDate
                  ? `Datum auswählen*`
                  : `Datum auswählen`
                : `Datum auswählen`
            "
            :rules="
              changeReason != -1
                ? changeReasonItems[changeReason - 1].requiredDate
                  ? [v => !!v || 'Bitte ausfüllen!']
                  : []
                : []
            "
            type="date"></v-text-field>
        </v-col>
      </v-row>
      <p style="font-size: 8pt; color: grey">*Pflichtangaben</p>
    </v-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      changeReason: 1,
      changeReasonItems: [
        {
          name: "Lieferantenwechsel zum nächstmöglichen Zeitpunkt",
          value: 1,
          requiredDate: false,
        },
        { name: "Lieferantenwechsel zum", value: 2, requiredDate: true },
        { name: "Einzug/Umzug zum", value: 3, requiredDate: true },
        {
          name: "Vertrag durch Kunden bereits gekündigt zum",
          value: 4,
          requiredDate: true,
        },
        { name: "Neubau des Hauses", value: 5, requiredDate: true },
      ],
      menu: false,
      checkbox: false,
      form: {
        text: "Objektdaten Wallbox",
        energyData: {
          text: "Stromdaten",
          currentSupplier: { text: "Bisheriger Stromlieferant", value: "" },
          customerNumberOld: {
            text: "Kundennummer beim bisherigen Stromanbieter",
            value: "",
          },
          meteringNumber: { text: "Zählernummer", value: "" },
        },
        changeReason: {
          text: "Wechselgrund",
          reason: { text: "Wechselgrund", value: "" },
          date: { text: "Datum", value: "" },
        },
      },
    };
  },
  computed: {
    ...mapState({
      selectedTarifAndOptions: state => {
        return state.selectedTarifAndOptions ?? null;
      },
    }),
    checkPageVisibility() {
      if (!this.selectedTarifAndOptions.options) {
        this.$emit("ErrorNoTarifOptions");
        return false;
      }
      if (
        this.selectedTarifAndOptions &&
        !this.selectedTarifAndOptions.options.cloudToCar
      ) {
        this.$emit("removePage");
        return false;
      }
      return true;
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    checkDate(v) {
      var requiredDate = false;
      this.changeReasonItems.forEach(element => {
        if (this.changeReason == element.value && element.requiredDate)
          requiredDate = true;
      });
      return !(requiredDate && !v);
    },
    parseData() {
      this.form.changeReason.reason.value =
        this.changeReasonItems[this.changeReason - 1].name;
    },
    saveData(id) {
      this.parseData();
      let data = {
        id: id,
        section: "formSupplyCloudToCar",
        data: this.form,
        enabled: true,
      };
      this.$store.commit("setCheckoutData", data);
    },
  },
  mounted() {
    this.resetValidation();
    this.checkPageVisibility;
  },
};
</script>

<style></style>
