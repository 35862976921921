<template>
  <v-sheet
    :id="`${props.target}`"
    :color="props.color_background"
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`">
    <v-container fluid class="container-padding" v-if="props" :id="props.id">
      <!-- Headline -->
      <v-row justify="center" v-if="props.headline && props.headline[language]">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <h2 v-html="props.headline[language]"></h2>
        </v-col>
      </v-row>
      <!-- Row 1 -->
      <v-row justify="center">
        <v-col cols="12" sm="6" md="6" lg="4" xl="4">
          <v-sheet
            class="rounded-tl"
            elevation="0"
            height="100%"
            :color="
              props.color_primary ? props.color_primary : 'background_primary'
            ">
            <v-img
              v-if="props.img_href_1"
              class="rounded-tl"
              width="100%"
              height="100%"
              :src="props.img_href_1"></v-img>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="4" xl="4">
          <v-sheet
            style="position: relative"
            class="pa-8"
            elevation="0"
            height="100%"
            :color="
              props.color_primary ? props.color_primary : 'background_primary'
            ">
            <span
              v-if="props.text_1_0 && props.text_1_0[language]"
              v-html="props.text_1_0[language]"
              :class="
                props.mode_primary == 'dark' ? 'white--text ' : ''
              "></span>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="4" xl="4">
          <v-sheet
            style="position: relative"
            class="pa-8 rounded-tr"
            elevation="0"
            height="100%"
            :color="
              props.color_secondary
                ? props.color_secondary
                : 'background_secondary'
            ">
            <span
              v-if="props.text_2_0 && props.text_2_0[language]"
              v-html="props.text_2_0[language]"
              :class="
                props.mode_secondary == 'dark' ? 'white--text ' : ''
              "></span>
          </v-sheet>
        </v-col>
      </v-row>
      <!-- Row 2 -->
      <v-row justify="center">
        <v-col cols="12" sm="12" md="12" lg="4" xl="4">
          <v-sheet
            style="position: relative"
            class="pa-8"
            elevation="0"
            height="100%"
            :color="
              props.color_primary ? props.color_primary : 'background_primary'
            ">
            <span
              v-if="props.text_0_1 && props.text_0_1[language]"
              v-html="props.text_0_1[language]"
              :class="
                props.mode_primary == 'dark' ? 'white--text ' : ''
              "></span>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="4" xl="4">
          <v-sheet
            style="position: relative"
            class="pa-8"
            elevation="0"
            height="100%"
            :color="
              props.color_secondary
                ? props.color_secondary
                : 'background_secondary'
            ">
            <span
              v-if="props.text_1_1 && props.text_1_1[language]"
              v-html="props.text_1_1[language]"
              :class="
                props.mode_secondary == 'dark' ? 'white--text ' : ''
              "></span>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="4" xl="4">
          <v-sheet
            elevation="0"
            height="100%"
            :color="
              props.color_primary ? props.color_primary : 'background_primary'
            ">
            <v-img
              v-if="props.img_href_2"
              width="100%"
              height="100%"
              :src="props.img_href_2"></v-img>
          </v-sheet>
        </v-col>
      </v-row>
      <!-- Row 3 -->
      <v-row justify="center">
        <v-col cols="12" sm="6" md="6" lg="4" xl="4">
          <v-sheet
            style="position: relative"
            class="pa-8 rounded-bl"
            elevation="0"
            height="100%"
            :color="
              props.color_secondary
                ? props.color_secondary
                : 'background_secondary'
            ">
            <span
              v-if="props.text_0_2 && props.text_0_2[language]"
              v-html="props.text_0_2[language]"
              :class="
                props.mode_secondary == 'dark' ? 'white--text ' : ''
              "></span>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="4" xl="4">
          <v-sheet
            elevation="0"
            height="100%"
            :color="
              props.color_primary ? props.color_primary : 'background_primary'
            ">
            <v-img
              v-if="props.img_href_3"
              width="100%"
              height="100%"
              :src="props.img_href_3"></v-img>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="4" xl="4">
          <v-sheet
            style="position: relative"
            class="pa-8 rounded-br"
            elevation="0"
            height="100%"
            :color="
              props.color_primary ? props.color_primary : 'background_primary'
            ">
            <span
              v-if="props.text_2_2 && props.text_2_2[language]"
              v-html="props.text_2_2[language]"
              :class="
                props.mode_primary == 'dark' ? 'white--text ' : ''
              "></span>
          </v-sheet>
        </v-col>
      </v-row>

      <!-- Edit dialog -->
      <v-dialog v-if="edit" persistent v-model="edit" width="100%">
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Component
          </v-card-title>
          <v-card-text class="pt-5">
            <Core
              :editProps="editProps"
              @emitProps="setPreviewProps"
              @save="save"
              @cancel="cancel"
              name="Core" />
            <!-- Preview -->
            <h2 class="my-2 text-h4 black--text">Preview:</h2>
            <CompGrid3x3
              v-if="previewProps"
              name="CompGrid3x3"
              :props="previewProps" />
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import CompGrid3x3 from "./CompGrid3x3";
import Core from "../editor/Core.vue";

export default {
  name: "CompGrid3x3",
  props: ["props", "edit", "index", "componentId"],
  data() {
    return {
      editProps: {},
      previewProps: null,
    };
  },
  watch: {
    edit: function () {
      this.editProps.color_background = {};
      this.editProps.color_background.type = "color";
      this.editProps.color_background.name = "color_background";
      this.editProps.color_background.value =
        this.props.color_background ?? "#FFFFFF";

      this.editProps.target = this.props.target ?? "";
      this.editProps.pt = this.props.pt ?? 0;
      this.editProps.pb = this.props.pb ?? 0;

      this.editProps.headline = {};
      this.editProps.headline.type = "text";
      this.editProps.headline.name = "headline";
      this.editProps.headline.value = this.props.headline ?? {};

      this.editProps.text_1_0 = {};
      this.editProps.text_1_0.type = "text";
      this.editProps.text_1_0.name = "text_1_0";
      this.editProps.text_1_0.value = this.props.text_1_0 ?? {};

      this.editProps.text_2_0 = {};
      this.editProps.text_2_0.type = "text";
      this.editProps.text_2_0.name = "text_2_0";
      this.editProps.text_2_0.value = this.props.text_2_0 ?? {};

      this.editProps.text_0_1 = {};
      this.editProps.text_0_1.type = "text";
      this.editProps.text_0_1.name = "text_0_1";
      this.editProps.text_0_1.value = this.props.text_0_1 ?? {};

      this.editProps.text_1_1 = {};
      this.editProps.text_1_1.type = "text";
      this.editProps.text_1_1.name = "text_1_1";
      this.editProps.text_1_1.value = this.props.text_1_1 ?? {};

      this.editProps.text_0_2 = {};
      this.editProps.text_0_2.type = "text";
      this.editProps.text_0_2.name = "text_0_2";
      this.editProps.text_0_2.value = this.props.text_0_2 ?? {};

      this.editProps.text_2_2 = {};
      this.editProps.text_2_2.type = "text";
      this.editProps.text_2_2.name = "text_2_2";
      this.editProps.text_2_2.value = this.props.text_2_2 ?? {};

      this.editProps.img_href_1 = {};
      this.editProps.img_href_1.type = "image";
      this.editProps.img_href_1.name = "img_href_1";
      this.editProps.img_href_1.value = this.props.img_href_1 ?? "";

      this.editProps.img_href_2 = {};
      this.editProps.img_href_2.type = "image";
      this.editProps.img_href_2.name = "img_href_2";
      this.editProps.img_href_2.value = this.props.img_href_2 ?? "";

      this.editProps.img_href_3 = {};
      this.editProps.img_href_3.type = "image";
      this.editProps.img_href_3.name = "img_href_3";
      this.editProps.img_href_3.value = this.props.img_href_3 ?? "";

      this.editProps.color_primary = {};
      this.editProps.color_primary.type = "color";
      this.editProps.color_primary.name = "color_primary";
      this.editProps.color_primary.value =
        this.props.color_primary ?? "#FFFFFF";

      this.editProps.color_secondary = {};
      this.editProps.color_secondary.type = "color";
      this.editProps.color_secondary.name = "color_secondary";
      this.editProps.color_secondary.value =
        this.props.color_secondary ?? "#FFFFFF";
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
      this.$emit("save", this.previewProps, this.index, this.componentId);
    },
    cancel() {
      this.$emit("cancel");
    },
    setPreviewProps(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
    },
  },
  components: {
    Core,
    CompGrid3x3,
  },
};
</script>

<style></style>
