import axios from "axios";
import CryptoJS from "crypto-js";
import store from "../store/store.js";
import router from "../routes/index";

// Settings
/* eslint-disable-next-line */
const api_base =
  /* eslint-disable-next-line */
  config.VUE_APP_ENV_HYDRA_URL || process.env.VUE_APP_ENV_HYDRA_URL;
/* eslint-disable-next-line */
const ui_base =
  /* eslint-disable-next-line */
  config.VUE_APP_ENV_KRATOS_UI_URL || process.env.VUE_APP_ENV_KRATOS_UI_URL;
/* eslint-disable-next-line */
const client_id = config.VUE_APP_ENV_CLIENT || process.env.VUE_APP_ENV_CLIENT;

// local auth-code-client-local-portal-ui
const scope = "offline+mbf"; //TODO client is not allowed the scope refresh_token
export const settings_url = ui_base + "settings";
// ---------------------------------------------------
// Api config
const auth_instance = axios.create({
  baseURL: api_base,
  timeout: 5000,
  // headers: { 'X-Custom-Header': 'foobar' }
});

export function getInitialAuthUrl() {
  // Genrate 128 character code_verifer and use SHA256 to generate code_challenge
  var code_verifier = generateRandomString(128);
  var code_challenge = generateCodeChallenge(code_verifier);
  // Save the code_verifier for requesting the access_token later
  store.commit("set_code_verifier", code_verifier);
  // Inititate the auth code flow
  const state = generateRandomString(128);
  var authUrl = `${api_base}/oauth2/auth?client_id=${client_id}&response_type=code&scope=${scope}&state=${state}&code_challenge=${code_challenge}&code_challenge_method=S256`;
  return authUrl;
}

export function getLogoutAuthUrl() {
  var authUrl = `${api_base}/oauth2/sessions/logout`;
  return authUrl;
}

export async function getAccessToken(code) {
  // Create POST request for exchanging the code_verifier and code for the access_token
  const params = new URLSearchParams();
  params.append("client_id", client_id);
  params.append("code_verifier", store.getters.code_verifier);
  params.append("code", code);
  params.append("grant_type", "authorization_code");

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  // Initiate the exchange
  try {
    const res = await auth_instance.post("/oauth2/token", params, config);
    // Save the access_token, (id_token), (refresh_token) and expiry time
    store.commit("set_user_data", res.data);
    router.push({ path: "/editor" });
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function checkAndRefreshAccessToken() {
  // Check if access is expired
  let now = new Date();
  let expiryDate = new Date(store.state.expiration_time);
  if (now < expiryDate) {
    return;
  }
  // Create POST request for exchanging the refresh_token for the new access_token
  const params = new URLSearchParams();
  params.append("client_id", client_id);
  params.append("code_verifier", store.getters.code_verifier);
  params.append("refresh_token", store.getters.refresh_token);
  params.append("grant_type", "refresh_token");

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  // Initiate the exchange
  try {
    const res = await auth_instance.post("/oauth2/token", params, config);
    // Save the access_token, (id_token), (refresh_token) and expiry time
    store.commit("refresh_access_token", res.data);
  } catch (error) {
    console.log(error);
    window.location = getInitialAuthUrl();
  }
}

// Helper functions ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

function generateRandomString(length) {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

function generateCodeChallenge(code_verifier) {
  return base64URL(CryptoJS.SHA256(code_verifier));
}

function base64URL(string) {
  return string
    .toString(CryptoJS.enc.Base64)
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
}
