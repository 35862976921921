export function scrollIntoView(elementId) {
  setTimeout(() => {
    window.scrollTo({
      behavior: "smooth",
      top:
        document.getElementById(elementId).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        100,
    });
  }, "500");
}
