<template>
  <v-container fluid class="pa-0">
    <v-sheet :color="props.color ? props.color : 'primary'" elevation="0">
      <v-footer padless>
        <v-card
          flat
          tile
          width="100%"
          :color="props.color ? props.color : 'primary'">
          <v-card-text
            :class="`mt-5 ${props.mode == 'dark' ? 'white--text' : ''}`">
            <!-- Columns -->
            <v-row justify="center">
              <v-col cols="6" sm="5" md="3" lg="3" xl="3">
                <span class="text-h5">
                  <v-icon
                    :color="`${props.mode == 'dark' ? 'white' : ''}`"
                    class="mr-3"
                    >mdi-phone-outline</v-icon
                  >
                  +4930 54 907 3270
                </span>
                <br />
                <span class="text-h5">
                  <v-icon
                    :color="`${props.mode == 'dark' ? 'white' : ''}`"
                    class="mr-3"
                    >mdi-email-outline</v-icon
                  >
                  info@new<wbr />energy<wbr />cloud.de
                </span>
                <br />
                <br />
                <span class="text-subtitle-1">
                  GreenStone Energy GmbH <br />
                  Manteuffelstrasse 77 <br />
                  <br />
                  10999 Berlin <br />
                  Deutschland <br />
                </span>
              </v-col>
              <v-col
                cols="6"
                sm="5"
                md="2"
                lg="2"
                xl="2"
                class="text-subtitle-1">
                <b>RECHTLICHES</b>
                <br />
                <br />
                <a
                  href="impressum"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >Impressum</a
                >
                <br />
                <a
                  href="agb"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >AGB</a
                >
                <br />
                <a
                  href="datenschutz"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >Datenschutz</a
                >
                <br />
                <a
                  href="widerruf"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >Widerruf</a
                >
                <br />
                <a
                  href="streitschlichtung"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >Streitschlichtung</a
                >
                <br />
                <a
                  href="stromkennzeichnung"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >Strom<wbr />kenn<wbr />zeich<wbr />nung</a
                >
                <br />
                <a
                  href="https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/newenergycloud/documents/210104_sepa-einzeln.pdf"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >SEPA-Lastschriftmandat</a
                >
                <br />
                <a
                  href="umsatzsteuer"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >Umsatzsteuer</a
                >
              </v-col>
              <v-col
                cols="6"
                sm="5"
                md="2"
                lg="2"
                xl="2"
                class="text-subtitle-1">
                <b>SERVICE</b>
                <br />
                <br />
                <a
                  href="tarifrechner"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >Stromrechner</a
                ><br />
                <a
                  href="faq"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >Häufige Fragen</a
                ><br />
                <a
                  href="wie-es-funktioniert"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >Wie es funktioniert</a
                ><br />
                <a
                  href="blankoantrag"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >Blankoantrag</a
                ><br />
                <a
                  href="strom-sparen"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >Strom sparen</a
                >
              </v-col>
              <v-col
                cols="6"
                sm="5"
                md="2"
                lg="2"
                xl="2"
                class="text-subtitle-1">
                <b>WISSEN</b>
                <br />
                <br />
                <a
                  href="newenergycloud"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >NewEnergyCloud</a
                ><br />
                <a
                  href="best-preis-garantie"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >Best-Preis-Garantie</a
                ><br />
                <a
                  href="zero-cost-cloud"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >Zero-Cost-Cloud</a
                ><br />
                <a
                  href="solheat"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >SolHeat</a
                ><br />
                <a
                  href="cloud-to-car"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >Cloud-to-Car (Wallbox)</a
                ><br />
                <a
                  href="cloud-to-share"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >Cloud-to-Share</a
                ><br />
                <a
                  href="solarstrom"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >Solarstrom</a
                ><br />
                <a
                  href="stromspeicher"
                  :style="`color: ${props.mode == 'dark' ? 'white' : 'black'};`"
                  >Stromspeicher</a
                >
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider class="my-5"></v-divider>
          <!-- Company copyright -->
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                :class="`text-center ${
                  props.mode == 'dark' ? 'grey--text' : ''
                }`">
                © GreenStone Energy 2021 – Alle Rechte vorbehalten
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  props: ["props"],
};
</script>

<style></style>
