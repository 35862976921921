<template>
  <v-sheet
    :id="`${props.target}`"
    :color="
      props.color_background ? props.color_background : 'background_secondary'
    "
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`">
    <v-container fluid v-if="props" class="container-padding" id="CompThgForm">
      <v-row justify="center">
        <v-col>
          <v-card class="py-4 px-6 mt-4 mb-6">
            <v-form ref="formThg" class="black--text" lazy-validation>
              <h4 class="text-h4 mb-2">
                Formular ausfüllen und THG-Quote verkaufen
              </h4>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="form.salutation"
                    :items="[`Herr`, `Frau`, `Divers`]"
                    label="Anrede*"
                    :rules="[v => !!v || 'Bitte ausfüllen!']"
                    required></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="form.title"
                    :items="[`Prof.`, `Dr.`, `Ing.`, `Kfm.`]"
                    label="Titel"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.first_name"
                    label="Vorname*"
                    :rules="[v => !!v || 'Bitte ausfüllen!']"
                    required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.last_name"
                    label="Nachname*"
                    :rules="[v => !!v || 'Bitte ausfüllen!']"
                    required></v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.email"
                    label="E-Mail-Adresse*"
                    :rules="[v => !!v || 'Bitte ausfüllen!']"
                    required></v-text-field
                ></v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.phone"
                    label="Telefonnummer*"
                    :rules="[v => !!v || 'Bitte ausfüllen!']"
                    required></v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.birthday"
                    type="date"
                    label="Geburtsdatum*"
                    :rules="[v => !!v || 'Bitte ausfüllen!']"
                    required></v-text-field
                ></v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.license_plate"
                    label="KFZ-Kennzeichen*"
                    :rules="[v => !!v || 'Bitte ausfüllen!']"
                    required></v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="form.checkbox_thg"
                    required
                    :rules="[v => !!v || 'Bitte zustimmen!']"
                    label="Ich möchte, dass meine THG-Quote für mein Elektroauto durch die NewEnergyCloud vermarktet wird."></v-checkbox>
                  <v-checkbox
                    v-model="form.checkbox_agb"
                    required
                    :rules="[v => !!v || 'Bitte zustimmen!']">
                    <template v-slot:label>
                      <span class="vertical-align:middle">
                        Ich habe die
                        <a
                          @click="agbClicked"
                          style="color: #eb002e"
                          href="https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/newenergycloud/documents/211215_THG-Quotenvermarktung.pdf">
                          Vertrags- und Allgemeinen Geschäftsbedingungen des
                          Angebotes „Vermarktung THG-Quoten durch
                          NewEnergyCloud“ sowie die Widerrufsbelehrung
                        </a>
                        gelesen und akzeptiert.
                      </span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span>
                    Bitte laden Sie an dieser Stelle eine Kopie beider Seiten
                    (z.B. Foto, Scan) Ihres Fahrzeugscheins hoch. Diese
                    benötigen wir zur Einreichung Ihrer THG-Quotenberechtigung
                    beim Umweltbundesamt.
                  </span>
                  <v-file-input
                    v-model="form.files"
                    class="mt-4"
                    label="Kopie beider Seiten Ihres Fahrzeugscheins"
                    required
                    :rules="rules"
                    chips
                    counter
                    multiple
                    truncate-length="30"></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span>
                    Prüfen Sie nach Absenden des Formulars Ihr E-Mail-Postfach.
                    Sie erhalten eine Bestätigungsemail mit den Vertrags- und
                    Allgemeinen Geschäftsbedingungen des Angebotes „Vermarktung
                    THG-Quoten durch NewEnergyCloud“ sowie die
                    Widerrufsbelehrung.
                  </span>
                  <br />
                  <v-btn
                    @click="thgSubmit"
                    class="py-6 mt-2 mb-2"
                    color="secondary"
                    width="100%"
                    >THG-Bonus beantragen</v-btn
                  >
                  <br />
                  <p class="text-caption">
                    Bitte beachten Sie: Dieses Angebot gilt nur für Kund:innen
                    der NewEnergyCloud. Ihre Prämie wird mit der
                    Jahresendabrechnung des laufenden Lieferjahres verrechnet.
                  </p>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="py-0">
                  <v-alert v-if="showSuccessAlert" dense text type="success">
                    Ihre Anfrage ist erfolgreich verschickt!
                  </v-alert>
                  <v-alert v-if="showInfoAlert" dense text type="info">
                    Anfrage wird bearbeitet...
                  </v-alert>
                  <v-alert v-if="showErrorAlert" dense text type="error">
                    Leider ist ein Fehler aufgetreten. Versuchen Sie es bitte
                    erneut oder kontaktieren Sie uns per E-Mail.
                  </v-alert>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>

      <!-- Edit dialog -->
      <v-dialog v-if="edit" persistent v-model="edit" fullscreen>
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Component
          </v-card-title>
          <v-card-text class="pt-5">
            <Core
              :editProps="editProps"
              @emitProps="setPreviewProps"
              @save="save"
              @cancel="cancel"
              name="Core" />
            <!-- Preview -->
            <h2 class="my-2 text-h4 black--text">Preview:</h2>
            <CompThgForm
              v-if="previewProps"
              name="CompThgForm"
              :props="previewProps" />
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import CompThgForm from "./CompThgForm";
import Core from "../editor/Core.vue";
import { postThgQuotaRequest } from "../api/api";
export default {
  name: "CompThgForm",
  props: ["props", "edit", "index", "componentId"],
  data() {
    return {
      editProps: {},
      previewProps: null,
      form: {
        salutation: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        birthday: "",
        license_plate: "",
        checkbox_thg: false,
        checkbox_agb: false,
        files: null,
      },
      showErrorAlert: false,
      showInfoAlert: false,
      showSuccessAlert: false,
      rules: [
        value => {
          if (!value) return "Bitte ausfüllen!";
          let sizeSum = 0;
          for (let index = 0; index < value.length; index++) {
            const size = value[index].size;
            sizeSum += size;
          }
          if (sizeSum > 2000000)
            return "Bilder müssen weniger als 20 MB groß sein!";
          return true;
        },
      ],
    };
  },
  watch: {
    edit: function () {
      this.editProps.color_background = {};
      this.editProps.color_background.type = "color";
      this.editProps.color_background.name = "color_background";
      this.editProps.color_background.value =
        this.props.color_background ?? "#FFFFFF";

      this.editProps.target = this.props.target ?? "";
      this.editProps.pt = this.props.pt ?? 0;
      this.editProps.pb = this.props.pb ?? 0;

      this.editProps.id = this.props.id ?? "";
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
      this.$emit("save", this.previewProps, this.index, this.componentId);
    },
    cancel() {
      this.$emit("cancel");
    },
    setPreviewProps(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
    },
    thgSubmit() {
      if (this.$refs.formThg.validate()) {
        let payload = {
          salutation: this.form.salutation,
          title: this.form.title,
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          birthday: this.form.birthday,
          email: this.form.email,
          phone: this.form.phone,
          license_plate: this.form.license_plate,
          files: this.form.files,
        };
        this.showInfoAlert = true;
        this.showErrorAlert = false;
        this.showSuccessAlert = false;
        postThgQuotaRequest(payload)
          .then(() => {
            this.showInfoAlert = false;
            this.showSuccessAlert = true;
          })
          .catch(() => {
            this.showInfoAlert = false;
            this.showErrorAlert = true;
          });
      }
    },
    agbClicked() {
      window.open(
        "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/newenergycloud/documents/211215_THG-Quotenvermarktung.pdf",
        "_blank"
      );
    },
  },
  components: {
    Core,
    CompThgForm,
  },
};
</script>

<style>
#CompThgForm .v-input--selection-controls {
  margin-top: 0px !important;
}
</style>
