<template>
  <v-container fluid v-if="props">
    <v-row justify="center">
      <v-col>
        <v-form ref="formTarifCalculator" v-model="valid">
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="zip_city"
                required
                :rules="[v => !!v || 'Bitte aus der Liste auswählen!']"
                :label="props.label_zip_city"
                :placeholder="props.placeholder_zip_city"
                :items="items_zip_city"
                :loading="loadingGetAddressData"
                :search-input.sync="searchZipCity"
                item-text="region"
                item-value="region"
                return-object
                :no-data-text="getNoDataLabel()"></v-autocomplete>
            </v-col>
            <v-col cols="10" sm="4" md="5">
              <v-rating
                v-model="rating"
                background-color="grey lighten-2"
                length="6"
                value="1">
                <template v-slot:item="props">
                  <v-icon
                    large
                    :color="props.isFilled ? 'secondary' : 'grey lighten-1'"
                    @click="props.click"
                    class="pa-0">
                    mdi-human-male
                  </v-icon>
                </template>
              </v-rating>
            </v-col>
            <v-col cols="1" align="center">
              <h2>{{ rating }}</h2>
            </v-col>
          </v-row>
          <v-row v-if="items_streets && items_streets.length > 1">
            <v-col cols="12" md="8">
              <v-autocomplete
                v-model="street"
                required
                :rules="[v => !!v || 'Bitte aus der Liste auswählen!']"
                :label="props.label_street"
                :placeholder="props.placeholder_street"
                :items="items_streets"
                item-text="street"
                item-value="street"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="house_number"
                required
                :rules="[v => !!v || 'Bitte ausfüllen!']"
                type="number"
                :label="props.label_house_number"
                :placeholder="props.placeholder_house_number"
                auto-select-first></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" :md="hidePhotovoltaic ? 12 : 6">
              <v-text-field
                v-model="consumption"
                required
                :rules="[v => !!v || 'Bitte ausfüllen!']"
                type="number"
                :label="props.label_consumption"
                :placeholder="props.placeholder_consumption"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" v-if="!hidePhotovoltaic">
              <v-text-field
                v-model="photovoltaic"
                :label="props.label_photovoltaic"
                :placeholder="props.placeholder_photovoltaic"
                required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="!hidePowerStorage">
              <v-select
                v-model="powerStorage"
                :label="props.label_powerStorage"
                :placeholder="props.placeholder_powerStorage"
                :items="props.powerStorageItems"
                required></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                :loading="loadingGetRecommendedTarifs || loadingGetAddressData"
                @click="setTarifs"
                class="px-6 py-7"
                color="secondary"
                >{{ props.button_text }}</v-btn
              >
              <v-alert
                v-if="loadingGetRecommendedTarifs"
                class="mt-4"
                type="info">
                Ihre Anfrage wird bearbeitet, bitte haben Sie kurz Geduld.
              </v-alert>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-alert v-if="showErrorAlert" class="mt-7" dense text type="error">
      Leider konnten die Tarife aufgrund eines technischen Fehlers nicht geladen
      werden. Versuchen Sie es später erneut oder kontaktieren Sie unseren
      Kundenservice unter der Rufnummer +4930 54 907 3270.
    </v-alert>

    <!-- Edit Component -->
    <v-dialog persistent v-model="edit" width="70%">
      <v-card>
        <v-card-title class="text-h5 black white--text">
          Edit Component
        </v-card-title>

        <v-card-text class="pt-5">
          <h3 class="my-2">Zipcode or City Label</h3>
          <v-text-field v-model="editProps.label_zip_city"></v-text-field>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="save"> Save </v-btn>
          <v-btn color="error" text @click="cancel"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { getRecommendedTarifs, getAddressData } from "../../api/api";

export default {
  props: ["edit", "hidePhotovoltaic", "hidePowerStorage", "tarifType"],
  data() {
    return {
      editProps: { label_zip_city: "" },
      valid: false,
      zip_city: "",
      street: "",
      house_number: "",
      items_zip_city: [],
      items_house_number: ["test1", "test2"],
      persons: "",
      consumption: 3500,
      photovoltaic: "4,50",
      powerStorage: "",
      powerStorageItems: [],
      rating: 2,
      showErrorAlert: false,
      loadingGetAddressData: false,
      loadingGetRecommendedTarifs: false,
      searchZipCity: null,
      bdew_code: null,
    };
  },
  watch: {
    edit: function () {
      Object.assign(this.editProps, this.props);
    },
    rating: function () {
      if (this.tarifType == "cloud") {
        switch (this.rating) {
          case 1:
            this.consumption = 1750;
            break;
          case 2:
            this.consumption = 3500;
            break;
          case 3:
            this.consumption = 5250;
            break;
          case 4:
            this.consumption = 7000;
            break;
          case 5:
            this.consumption = 8750;
            break;
          case 6:
            this.consumption = 9500;
            break;
          default:
            break;
        }
      }
      if (this.tarifType == "friends") {
        switch (this.rating) {
          case 1:
            this.consumption = 1100;
            break;
          case 2:
            this.consumption = 2000;
            break;
          case 3:
            this.consumption = 2900;
            break;
          case 4:
            this.consumption = 3900;
            break;
          case 5:
            this.consumption = 5000;
            break;
          case 6:
            this.consumption = 6000;
            break;
          default:
            break;
        }
      }
    },
    searchZipCity(val) {
      if (!val) return;
      if (val.length != 5) return val;
      // Items have already been requested
      if (this.loadingGetAddressData) return val;
      this.loadingGetAddressData = true;

      // Lazily load input items
      getAddressData(val, "", "")
        .then(res => {
          this.items_zip_city = res.data;
        })
        .catch(err => {
          console.log(err);
          this.zip_city = "";
          this.items_zip_city = [];
        })
        .finally(() => (this.loadingGetAddressData = false));
      return val;
    },
  },
  methods: {
    save() {
      this.$emit("saveCalculator", this.editProps);
    },
    cancel() {
      this.$emit("cancelCalculator");
    },
    setTarifs() {
      if (!this.$refs.formTarifCalculator.validate()) return;
      this.bdew_code = this.zip_city.bdew_code;
      if (this.items_streets.length > 0) {
        this.loadingGetAddressData = true;
        getAddressData(
          this.zip_city.postal_code,
          this.street,
          this.house_number
        )
          .then(res => {
            this.bdew_code = res.data[0].bdew_code;
            this.loadingGetAddressData = false;
            this.loadingGetRecommendedTarifs = true;
            getRecommendedTarifs(
              this.bdew_code,
              this.zip_city.postal_code,
              this.consumption,
              this.tarifType
            )
              .then(res => {
                res.data.photovoltaic = this.photovoltaic;
                res.data.consumption = this.consumption;
                this.$emit("setTarif", {
                  ...res.data,
                  bdew_code: this.bdew_code,
                  zip_code: this.zip_city.postal_code,
                });
                this.showErrorAlert = false;
                this.loadingGetRecommendedTarifs = false;
              })
              .catch(() => {
                this.showErrorAlert = true;
                this.loadingGetRecommendedTarifs = false;
              });
          })
          .catch(err => {
            this.loadingGetAddressData = false;
            console.log(err);
          });
      } else {
        getRecommendedTarifs(
          this.bdew_code,
          this.zip_city.postal_code,
          this.consumption,
          this.tarifType
        )
          .then(res => {
            res.data.photovoltaic = this.photovoltaic;
            res.data.consumption = this.consumption;
            this.$emit("setTarif", {
              ...res.data,
              bdew_code: this.bdew_code,
              zip_code: this.zip_city.postal_code,
            });
            this.showErrorAlert = false;
          })
          .catch(() => {
            this.showErrorAlert = true;
          });
      }
    },
    getNoDataLabel() {
      let label = "";
      if (this.searchZipCity) {
        if (this.searchZipCity.length != 5 && !this.zip_city) {
          label = this.props.put_in_zip_text;
        } else {
          if (this.loadingGetAddressData) {
            label = this.props.loading_zip_text;
          } else {
            if (this.zip_city) {
              label = this.zip_city.region;
            } else {
              label = this.props.no_data_zip_text;
            }
          }
        }
      } else {
        label = this.props.put_in_zip_text;
      }
      return label;
    },
  },
  computed: {
    ...mapState({
      props: state => {
        return state.config.internal_components["tarif_calculator"] ?? [];
      },
      language: state => {
        return state.language ?? "de";
      },
    }),
    items_streets() {
      if (!this.items_zip_city || !this.zip_city) return [];
      return this.items_zip_city.filter(
        item => item.street != "" && item.region == this.zip_city.region
      );
    },
  },
};
</script>

<style></style>
