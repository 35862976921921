<template>
  <div v-if="pageConfig">
    <div v-for="item in pageConfig.components" :key="item.id">
      <component :is="item.component" :props="item.props"></component>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      pageConfigs: state => {
        return state.config ? state.config.pages : null;
      },
      articles: state => {
        return state.config ? state.config.articles : null;
      },
    }),
    pageConfig: function () {
      let page = this.pageConfigs
        ? this.pageConfigs.filter(page => page.path == this.$route.path)[0]
        : null;
      if (page) {
        return page;
      }

      return this.articles
        ? this.articles.filter(article => article.path == this.$route.path)[0]
        : null;
    },
  },
};
</script>

<style></style>
