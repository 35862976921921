<template>
  <v-container fluid class="my-10">
    <v-row justify="center">
      <v-col class="pa-0">
        <v-card class="rounded-lg mx-0 my-2" id="recommended">
          <v-card-text class="pb-8 px-8">
            <h3
              class="font-weight-bold text-h6 mb-4 black--text"
              style="border-bottom: 3px #eb002e solid; width: 140px">
              Unser Angebot
            </h3>
            <v-row align="center">
              <v-col class="pa-4" align="center">
                <div class="container-vertical">
                  <div class="vertical-center black--text">
                    <h2 class="text-h3">
                      {{
                        tarifData.price_per_month.toString().replace(".", ",")
                      }}
                    </h2>
                    <b class="text-body-2"
                      >{{ tarifData.price_per_month_unit }}
                    </b>
                  </div>
                </div>
              </v-col>
              <v-col class="text-body-1">
                <!-- {{ tarifData }} -->
                <p>
                  Grundpreis:
                  {{ tarifData.base_price.toString().replace(".", ",") }}
                  {{ tarifData.base_price_unit }}
                </p>
                <p>
                  Arbeitspreis:
                  {{ tarifData.working_price.toString().replace(".", ",") }}
                  {{ tarifData.working_price_unit }}
                </p>
                <p>Vertragsdauer: 30.10.2023</p>
                <p>(* inkl. USt)</p>
              </v-col>
              <v-col align="center">
                <v-btn
                  color="secondary"
                  width="250px"
                  class="py-6"
                  @click="book">
                  Jetzt bestellen
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["props", "edit", "index", "tarifData"],
  data() {
    return {};
  },
  methods: {
    book() {
      let tarifAndOptions = {
        isSelected: true,
        information: {
          consumption: this.tarifData.consumption,
        },
        options: {
          solHeat: false,
          cloudToShare: false,
          cloudToCar: false,
          bestPriceGuarantee: false,
          zeroCostCloud: false,
        },
        tarif: this.tarifData,
        tarifType: "friends",
      };
      this.$store.commit("setTarifAndOptions", tarifAndOptions);
      this.$router.push({ path: "checkout" });
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
};
</script>

<style>
.vertical-center {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%) translateX(-50%);
}
.container-vertical {
  position: relative;
  height: 200px;
  border: 3px #eb002e solid;
  border-radius: 50%;
  height: 200px;
  width: 200px;
}
</style>
