<template>
  <v-sheet
    :id="`${props.target}`"
    :color="props.color_background"
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`">
    <v-container
      fluid
      v-if="
        props &&
        mehrPrices &&
        packagePrices &&
        mehrPricesSpecial &&
        packagePricesSpecial
      "
      class="container-padding">
      <v-row class="justify-center">
        <v-col :cols="12" :md="12" :sm="12" :lg="9" :xl="9">
          <v-sheet class="pa-8 rounded-lg elevation-2">
            <h1 style="color: red">Preisübersicht</h1>
            <br />
            <h2 style="color: red">1. Paket</h2>
            <v-expansion-panels class="my-2">
              <v-expansion-panel>
                <v-expansion-panel-header
                  >Bitte beachten Sie, dass es für bestimmte Netzgebiete
                  besondere Paketpreise gibt:</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <h3 style="color: red">
                    Paketpreise für besondere Netzgebiete
                  </h3>
                  <b>Netzgebiete:</b>
                  <ul>
                    <li>SH- Netz AG</li>
                    <li>Edis Netz GmbH</li>
                    <li>Saerbecker Ver- und Entsorgungsnetzgesellschaft mbH</li>
                  </ul>
                  <p>
                    Für folgende Netzgebiete gelten abweichend von Ziffer 1 die
                    nachfolgenden Paketpreise. Maßgeblich ist immer der durch
                    System angezeigte Paketpreis in Abhängigkeit der
                    eingegebenen Postleitzahl.
                  </p>
                  <table
                    style="
                      width: 100%;
                      border-collapse: collapse;
                      text-align: left;
                      border-top: 2px solid black;
                    ">
                    <tr
                      style="
                        text-align: center;
                        border-bottom: 1px solid black;
                      ">
                      <td>B</td>
                      <td>C</td>
                      <td>D</td>
                      <td>E</td>
                      <td>F</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black">
                      <th>Name</th>
                      <th>Inkludierte Menge p.a.</th>
                      <th>Preis pro Monat Netto</th>
                      <th>Gesetzl. MwSt.</th>
                      <th>Preis pro Monat Brutto</th>
                    </tr>
                    <tr
                      v-for="(item, index) in tarifsSpecial"
                      :key="index"
                      :style="
                        index % 2 == 0
                          ? 'background-color: rgb(179, 179, 179)'
                          : ''
                      ">
                      <td>{{ item.tarif_name }}</td>
                      <td>
                        {{ item.amount_free }} {{ item.amount_free_unit }}
                      </td>
                      <td>
                        {{ formatNetto(item) }}
                      </td>
                      <td>
                        {{ formatMwst(item) }}
                      </td>
                      <td>
                        {{ formatBrutto(item) }}
                      </td>
                    </tr>
                  </table>
                  <br />
                  <h3>
                    <u>Inkludierte Menge (p.a.)</u>
                  </h3>
                  <p style="text-align: justify">
                    Mit diesem Paketpreis ist der Bezug von Strom in der im
                    jeweiligen Paket angegebene Menge an kWh enthalten
                    (inkludierte Menge), soweit diesem Bezug mindestens eine
                    gleichhohe Cloud-Menge gegenübersteht.
                  </p>
                  <h3><u>Mehr1</u></h3>
                  <p style="text-align: justify">
                    Soweit der Kunde mehr Strom als die inkludierte Menge aus
                    der Cloud entnimmt, bezahlt der Kunde
                    {{ formatedFull(this.mehrPricesSpecial.mehr_1) }} für die
                    zusätzliche Menge oberhalb der inkludierten Menge, sofern er
                    während des Vertragsjahres diese Cloud-Menge abgegeben hat.
                  </p>
                  <h3><u>Mehr2</u></h3>
                  <p style="text-align: justify">
                    Soweit der Kunde mehr Strom aus der Cloud entnimmt, als er
                    während des Vertragsjahres diese CloudMenge abgegeben hat,
                    zahlt er für diese Menge
                    {{ formatedFull(this.mehrPricesSpecial.mehr_2) }}.
                  </p>
                  <h2 style="color: red">2. Vergütung von Überschussstrom</h2>
                  <p style="text-align: justify">
                    NewEnergyCloud vergütet jede kWh Überschussstrom mit der für
                    die Erzeugungsanlage maßgeblichen Einspeisevergütung.
                    Überschussstrom im Sinne dieses Vertrages ist die
                    Strommenge, die der Kunde ins Netz der öffentlichen
                    Versorgung einspeist abzüglich der Menge, die der Kunde vom
                    Lieferanten bezieht. Maßgeblich für den Vergütungsanspruch
                    ist, dass der Lieferant die Einspeisevergütung für den Strom
                    erhält, den der Kunde ins Netz der öffentlichen Versorgung
                    einspeist.
                  </p>
                  <h2 style="color: black"><u>Zusatzoptionen</u></h2>
                  <br />
                  <h2 style="color: red">3. Best-Preis-Garantie</h2>
                  <p style="text-align: justify">
                    Mit Auswahl dieser Option wird der Lieferant bei der
                    Abrechnung jenes Paket zur Anwendung bringen, dass für den
                    Kunden mit den geringsten Kosten im Vergleich aller zur
                    Auswahl stehenden Pakete des Lieferanten führt.
                  </p>
                  <p style="text-align: justify">
                    Mit Auswahl der Best-Preis-Garantie erhöht sich das
                    jährliche Entgelt um
                    {{
                      formatedFull(
                        this.packagePricesSpecial.best_price_guarantee
                      )
                    }}.
                  </p>
                  <h2 style="color: red">4. Zero-Cost-Cloud</h2>
                  <p style="text-align: justify">
                    Mit Auswahl dieser Option verzichtet der Lieferant auf die
                    Zahlung von Abschlägen während des Jahres. Die Abrechnung
                    erfolgt auf der Grundlage des vom Kunden gewählten Paketes
                    und der vom Kunden ausgewählten Zusatzoptionen. Diese Option
                    ist wählbar ab der in Spalte C aufgeführten Mindest-Größe
                    der PVAnlage. Der jährliche Grundpreis erhöht sich bei
                    Auswahl dieser Option um
                    {{
                      formatedFull(this.packagePricesSpecial.zero_cost_cloud)
                    }}.
                  </p>
                  <h2 style="color: red">5. SolHeat (für Wärmepumpen)</h2>
                  <p style="text-align: justify">
                    Die Zusatzoption SolHeat ist nur auswählbar, sofern die
                    Wärmepumpe oder Nachtspeicherheizung über einen
                    <b>eigenen Zähler</b> angeschlossen und der Strombezug
                    seitens des Verteilnetzbetreibers unterbrechbar ist.
                  </p>
                  <table
                    style="
                      width: 100%;
                      border-collapse: collapse;
                      text-align: left;
                      border-top: 2px solid black;
                    ">
                    <tr
                      style="
                        text-align: center;
                        border-bottom: 1px solid black;
                      ">
                      <td>B</td>
                      <td>C</td>
                      <td>D</td>
                      <td>E</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black">
                      <th>Name</th>
                      <th>Preis pro Monat Netto</th>
                      <th>Gesetzl. MwSt.</th>
                      <th>Preis pro Monat Brutto</th>
                    </tr>
                    <tr style="background-color: rgb(179, 179, 179)">
                      <td>SolHeat</td>
                      <td>
                        {{
                          this.formatNetto(this.packagePricesSpecial.sol_heat)
                        }}
                      </td>
                      <td>
                        {{
                          this.formatMwst(this.packagePricesSpecial.sol_heat)
                        }}
                      </td>
                      <td>
                        {{
                          this.formatBrutto(this.packagePricesSpecial.sol_heat)
                        }}
                      </td>
                    </tr>
                  </table>
                  <br />
                  <p style="text-align: justify">
                    Der Grundpreis erhöht sich pro Monat um
                    {{ formatedFull(this.packagePricesSpecial.sol_heat) }}.
                    Zusätzlich wird der Verbrauch berechnet mit:
                  </p>
                  <h3><u>Mehr3</u></h3>
                  <p style="text-align: justify">
                    Soweit der Kunde Strom an dem die Wärmepumpe angeschlossenen
                    Zähler aus der Cloud entnimmt und der Kunde während des
                    Vertragsjahres diese Cloud-Menge abgegeben hat, bezahlt der
                    Kunde
                    {{ formatedFull(this.mehrPricesSpecial.mehr_3) }} für diese
                    Menge.
                  </p>
                  <h3><u>Mehr4</u></h3>
                  <p style="text-align: justify">
                    Soweit der Kunde mehr Strom an dem die Wärmepumpe
                    angeschlossenen Zähler aus der Cloud entnimmt, als er
                    während des Vertragsjahres diese Cloud-Menge abgegeben hat,
                    zahlt er für diese Menge
                    {{ formatedFull(this.mehrPricesSpecial.mehr_4) }}.
                  </p>
                  <h2 style="color: red">
                    6. Cloud-to-Share (zusätzliche Abnahmestelle)
                  </h2>
                  <p style="text-align: justify">
                    Mit der Zusatzoption Cloud-to-Share kann der Kunde eine
                    weitere Abnahmestelle in die Cloud integrieren, unabhängig
                    davon, wo die Abnahmestelle ist (innerhalb der
                    Bundesrepublik Deutschland).
                  </p>
                  <table
                    style="
                      width: 100%;
                      border-collapse: collapse;
                      text-align: left;
                      border-top: 2px solid black;
                    ">
                    <tr
                      style="
                        text-align: center;
                        border-bottom: 1px solid black;
                      ">
                      <td>B</td>
                      <td>C</td>
                      <td>D</td>
                      <td>E</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black">
                      <th>Name</th>
                      <th>Preis pro Monat Netto</th>
                      <th>Gesetzl. MwSt.</th>
                      <th>Preis pro Monat Brutto</th>
                    </tr>
                    <tr style="background-color: rgb(179, 179, 179)">
                      <td>Cloud-to-Share</td>
                      <td>
                        {{
                          this.formatNetto(
                            this.packagePricesSpecial.cloud_to_share
                          )
                        }}
                      </td>
                      <td>
                        {{
                          this.formatMwst(
                            this.packagePricesSpecial.cloud_to_share
                          )
                        }}
                      </td>
                      <td>
                        {{
                          this.formatBrutto(
                            this.packagePricesSpecial.cloud_to_share
                          )
                        }}
                      </td>
                    </tr>
                  </table>
                  <br />
                  <p style="text-align: justify">
                    Der Grundpreis erhöht sich pro Monat um
                    {{
                      formatedFull(this.packagePricesSpecial.cloud_to_share)
                    }}. Zusätzlich wird der Verbrauch berechnet. Der Verbrauch
                    der zusätzlichen Abnahmestelle wird separat auf der
                    Abrechnung ausgewiesen, aber der Cloud-Entnahme
                    hinzugerechnet. (Preise für Mehr1 und Mehr2 siehe Ziffer 1
                    des Antrages).
                  </p>
                  <h2 style="color: red">7. Cloud-to-Car (für Wallbox)</h2>
                  <p style="text-align: justify">
                    Die Zusatzoption Cloud-to-Car ist nur auswählbar, sofern die
                    Wallbox über einen <b>eigenen Zähler</b> angeschlossen und
                    der Strombezug seitens des Verteilnetzbetreibers
                    <b>unterbrechbar</b> ist.
                  </p>
                  <table
                    style="
                      width: 100%;
                      border-collapse: collapse;
                      text-align: left;
                      border-top: 2px solid black;
                    ">
                    <tr
                      style="
                        text-align: center;
                        border-bottom: 1px solid black;
                      ">
                      <td>B</td>
                      <td>C</td>
                      <td>D</td>
                      <td>E</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black">
                      <th>Name</th>
                      <th>Preis pro Monat Netto</th>
                      <th>Gesetzl. MwSt.</th>
                      <th>Preis pro Monat Brutto</th>
                    </tr>
                    <tr style="background-color: rgb(179, 179, 179)">
                      <td>Cloud-to-Car</td>
                      <td>
                        {{
                          this.formatNetto(
                            this.packagePricesSpecial.cloud_to_car
                          )
                        }}
                      </td>
                      <td>
                        {{
                          this.formatMwst(
                            this.packagePricesSpecial.cloud_to_car
                          )
                        }}
                      </td>
                      <td>
                        {{
                          this.formatBrutto(
                            this.packagePricesSpecial.cloud_to_car
                          )
                        }}
                      </td>
                    </tr>
                  </table>
                  <br />
                  <p style="text-align: justify">
                    Der Grundpreis erhöht sich pro Monat um
                    {{ formatedFull(this.packagePricesSpecial.cloud_to_car) }}.
                    Zusätzlich wird der Verbrauch berechnet mit:
                  </p>
                  <h3><u>Mehr3</u></h3>
                  <p style="text-align: justify">
                    Soweit der Kunde Strom an dem die Wallbox angeschlossenen
                    Zähler aus der Cloud entnimmt und der Kunde während des
                    Vertragsjahres diese Cloud-Menge abgegeben hat, bezahlt der
                    Kunde
                    {{ formatedFull(this.mehrPricesSpecial.mehr_3) }}. für diese
                    Menge.
                  </p>
                  <h3><u>Mehr4</u></h3>
                  <p style="text-align: justify">
                    Soweit der Kunde mehr Strom an dem die Wallbox
                    angeschlossenen Zähler aus der Cloud entnimmt, als er
                    während des Vertragsjahres diese Cloud-Menge abgegeben hat,
                    zahlt er für diese Menge
                    {{ formatedFull(this.mehrPricesSpecial.mehr_4) }}.
                  </p>
                  <br />
                  <h2 style="color: red">8. Sondertarif "Friends"</h2>
                  <p style="text-align: justify">
                    Dieser Tarif wird in Bezug Grund- und Arbeitspreis
                    standortspezifisch berechnet.
                  </p>
                  <p style="text-align: justify">
                    Dies ist nur ein Auszug aus dem Antrag auf Abschluss des
                    Vertrages NewEnergyCloud. Maßgeblich sind nur die Regelungen
                    im Vertrag selbst.
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <br />
            <table
              style="
                width: 100%;
                border-collapse: collapse;
                text-align: left;
                border-top: 2px solid black;
              ">
              <tr style="text-align: center; border-bottom: 1px solid black">
                <td>B</td>
                <td>C</td>
                <td>D</td>
                <td>E</td>
                <td>F</td>
              </tr>
              <tr style="border-bottom: 1px solid black">
                <th>Name</th>
                <th>Inkludierte Menge p.a.</th>
                <th>Preis pro Monat Netto</th>
                <th>Gesetzl. MwSt.</th>
                <th>Preis pro Monat Brutto</th>
              </tr>
              <tr
                v-for="(item, index) in tarifs"
                :key="index"
                :style="
                  index % 2 == 0 ? 'background-color: rgb(179, 179, 179)' : ''
                ">
                <td>{{ item.tarif_name }}</td>
                <td>{{ item.amount_free }} {{ item.amount_free_unit }}</td>
                <td>
                  {{ formatNetto(item) }}
                </td>
                <td>
                  {{ formatMwst(item) }}
                </td>
                <td>
                  {{ formatBrutto(item) }}
                </td>
              </tr>
            </table>
            <br />
            <h3>
              <u>Inkludierte Menge</u>
            </h3>
            <p style="text-align: justify">
              Mit diesem Paketpreis ist der Bezug von Strom in der im jeweiligen
              Paket angegebene Menge an kWh enthalten (inkludierte Menge),
              soweit diesem Bezug mindestens eine gleichhohe Cloud-Menge
              gegenübersteht.
            </p>
            <h3><u>Mehr1</u></h3>
            <p style="text-align: justify">
              Soweit der Kunde mehr Strom als die inkludierte Menge aus der
              Cloud entnimmt, bezahlt der Kunde
              {{ formatedFull(this.mehrPrices.mehr_1) }} für die zusätzliche
              Menge oberhalb der inkludierten Menge, sofern er während des
              Vertragsjahres diese Cloud-Menge abgegeben hat.
            </p>
            <h3><u>Mehr2</u></h3>
            <p style="text-align: justify">
              Soweit der Kunde mehr Strom aus der Cloud entnimmt, als er während
              des Vertragsjahres diese CloudMenge abgegeben hat, zahlt er für
              diese Menge
              {{ formatedFull(this.mehrPrices.mehr_2) }}.
            </p>
            <h2 style="color: red">2. Vergütung von Überschussstrom</h2>
            <p style="text-align: justify">
              NewEnergyCloud vergütet jede kWh Überschussstrom mit der für die
              Erzeugungsanlage maßgeblichen Einspeisevergütung. Überschussstrom
              im Sinne dieses Vertrages ist die Strommenge, die der Kunde ins
              Netz der öffentlichen Versorgung einspeist abzüglich der Menge,
              die der Kunde vom Lieferanten bezieht. Maßgeblich für den
              Vergütungsanspruch ist, dass der Lieferant die Einspeisevergütung
              für den Strom erhält, den der Kunde ins Netz der öffentlichen
              Versorgung einspeist.
            </p>
            <h2 style="color: black"><u>Zusatzoptionen</u></h2>
            <br />
            <h2 style="color: red">3. Best-Preis-Garantie</h2>
            <p style="text-align: justify">
              Mit Auswahl dieser Option wird der Lieferant bei der Abrechnung
              jenes Paket zur Anwendung bringen, dass für den Kunden mit den
              geringsten Kosten im Vergleich aller zur Auswahl stehenden Pakete
              des Lieferanten führt.
            </p>
            <p style="text-align: justify">
              Mit Auswahl der Best-Preis-Garantie erhöht sich das jährliche
              Entgelt um
              {{ formatedFull(this.packagePrices.best_price_guarantee) }}.
            </p>
            <h2 style="color: red">4. Zero-Cost-Cloud</h2>
            <p style="text-align: justify">
              Mit Auswahl dieser Option verzichtet der Lieferant auf die Zahlung
              von Abschlägen während des Jahres. Die Abrechnung erfolgt auf der
              Grundlage des vom Kunden gewählten Paketes und der vom Kunden
              ausgewählten Zusatzoptionen. Diese Option ist wählbar ab der in
              Spalte C aufgeführten Mindest-Größe der PVAnlage. Der jährliche
              Grundpreis erhöht sich bei Auswahl dieser Option um
              {{ formatedFull(this.packagePrices.zero_cost_cloud) }}.
            </p>
            <h2 style="color: red">5. SolHeat (für Wärmepumpen)</h2>
            <p style="text-align: justify">
              Die Zusatzoption SolHeat ist nur auswählbar, sofern die Wärmepumpe
              oder Nachtspeicherheizung über einen
              <b>eigenen Zähler</b> angeschlossen und der Strombezug seitens des
              Verteilnetzbetreibers unterbrechbar ist.
            </p>
            <table
              style="
                width: 100%;
                border-collapse: collapse;
                text-align: left;
                border-top: 2px solid black;
              ">
              <tr style="text-align: center; border-bottom: 1px solid black">
                <td>B</td>
                <td>C</td>
                <td>D</td>
                <td>E</td>
              </tr>
              <tr style="border-bottom: 1px solid black">
                <th>Name</th>
                <th>Preis pro Monat Netto</th>
                <th>Gesetzl. MwSt.</th>
                <th>Preis pro Monat Brutto</th>
              </tr>
              <tr style="background-color: rgb(179, 179, 179)">
                <td>SolHeat</td>
                <td>
                  {{ this.formatNetto(this.packagePrices.sol_heat) }}
                </td>
                <td>
                  {{ this.formatMwst(this.packagePrices.sol_heat) }}
                </td>
                <td>
                  {{ this.formatBrutto(this.packagePrices.sol_heat) }}
                </td>
              </tr>
            </table>
            <br />
            <p style="text-align: justify">
              Der Grundpreis erhöht sich pro Monat um
              {{ formatedFull(this.packagePrices.sol_heat) }}. Zusätzlich wird
              der Verbrauch berechnet mit:
            </p>
            <h3><u>Mehr3</u></h3>
            <p style="text-align: justify">
              Soweit der Kunde Strom an dem die Wärmepumpe angeschlossenen
              Zähler aus der Cloud entnimmt und der Kunde während des
              Vertragsjahres diese Cloud-Menge abgegeben hat, bezahlt der Kunde
              {{ formatedFull(this.mehrPrices.mehr_3) }} für diese Menge.
            </p>
            <h3><u>Mehr4</u></h3>
            <p style="text-align: justify">
              Soweit der Kunde mehr Strom an dem die Wärmepumpe angeschlossenen
              Zähler aus der Cloud entnimmt, als er während des Vertragsjahres
              diese Cloud-Menge abgegeben hat, zahlt er für diese Menge
              {{ formatedFull(this.mehrPrices.mehr_4) }}.
            </p>
            <h2 style="color: red">
              6. Cloud-to-Share (zusätzliche Abnahmestelle)
            </h2>
            <p style="text-align: justify">
              Mit der Zusatzoption Cloud-to-Share kann der Kunde eine weitere
              Abnahmestelle in die Cloud integrieren, unabhängig davon, wo die
              Abnahmestelle ist (innerhalb der Bundesrepublik Deutschland).
            </p>
            <table
              style="
                width: 100%;
                border-collapse: collapse;
                text-align: left;
                border-top: 2px solid black;
              ">
              <tr style="text-align: center; border-bottom: 1px solid black">
                <td>B</td>
                <td>C</td>
                <td>D</td>
                <td>E</td>
              </tr>
              <tr style="border-bottom: 1px solid black">
                <th>Name</th>
                <th>Preis pro Monat Netto</th>
                <th>Gesetzl. MwSt.</th>
                <th>Preis pro Monat Brutto</th>
              </tr>
              <tr style="background-color: rgb(179, 179, 179)">
                <td>Cloud-to-Share</td>
                <td>
                  {{ this.formatNetto(this.packagePrices.cloud_to_share) }}
                </td>
                <td>
                  {{ this.formatMwst(this.packagePrices.cloud_to_share) }}
                </td>
                <td>
                  {{ this.formatBrutto(this.packagePrices.cloud_to_share) }}
                </td>
              </tr>
            </table>
            <br />
            <p style="text-align: justify">
              Der Grundpreis erhöht sich pro Monat um
              {{ formatedFull(this.packagePrices.cloud_to_share) }}. Zusätzlich
              wird der Verbrauch berechnet. Der Verbrauch der zusätzlichen
              Abnahmestelle wird separat auf der Abrechnung ausgewiesen, aber
              der Cloud-Entnahme hinzugerechnet. (Preise für Mehr1 und Mehr2
              siehe Ziffer 1 des Antrages).
            </p>
            <h2 style="color: red">7. Cloud-to-Car (für Wallbox)</h2>
            <p style="text-align: justify">
              Die Zusatzoption Cloud-to-Car ist nur auswählbar, sofern die
              Wallbox über einen <b>eigenen Zähler</b> angeschlossen und der
              Strombezug seitens des Verteilnetzbetreibers
              <b>unterbrechbar</b> ist.
            </p>
            <table
              style="
                width: 100%;
                border-collapse: collapse;
                text-align: left;
                border-top: 2px solid black;
              ">
              <tr style="text-align: center; border-bottom: 1px solid black">
                <td>B</td>
                <td>C</td>
                <td>D</td>
                <td>E</td>
              </tr>
              <tr style="border-bottom: 1px solid black">
                <th>Name</th>
                <th>Preis pro Monat Netto</th>
                <th>Gesetzl. MwSt.</th>
                <th>Preis pro Monat Brutto</th>
              </tr>
              <tr style="background-color: rgb(179, 179, 179)">
                <td>Cloud-to-Car</td>
                <td>
                  {{ this.formatNetto(this.packagePrices.cloud_to_car) }}
                </td>
                <td>
                  {{ this.formatMwst(this.packagePrices.cloud_to_car) }}
                </td>
                <td>
                  {{ this.formatBrutto(this.packagePrices.cloud_to_car) }}
                </td>
              </tr>
            </table>
            <br />
            <p style="text-align: justify">
              Der Grundpreis erhöht sich pro Monat um
              {{ formatedFull(this.packagePrices.cloud_to_car) }}. Zusätzlich
              wird der Verbrauch berechnet mit:
            </p>
            <h3><u>Mehr3</u></h3>
            <p style="text-align: justify">
              Soweit der Kunde Strom an dem die Wallbox angeschlossenen Zähler
              aus der Cloud entnimmt und der Kunde während des Vertragsjahres
              diese Cloud-Menge abgegeben hat, bezahlt der Kunde
              {{ formatedFull(this.mehrPrices.mehr_3) }}. für diese Menge.
            </p>
            <h3><u>Mehr4</u></h3>
            <p style="text-align: justify">
              Soweit der Kunde mehr Strom an dem die Wallbox angeschlossenen
              Zähler aus der Cloud entnimmt, als er während des Vertragsjahres
              diese Cloud-Menge abgegeben hat, zahlt er für diese Menge
              {{ formatedFull(this.mehrPrices.mehr_4) }}.
            </p>
            <br />
            <h2 style="color: red">8. Sondertarif "Friends"</h2>
            <p style="text-align: justify">
              Dieser Tarif wird in Bezug Grund- und Arbeitspreis
              standortspezifisch berechnet.
            </p>
            <p style="text-align: justify">
              Dies ist nur ein Auszug aus dem Antrag auf Abschluss des Vertrages
              NewEnergyCloud. Maßgeblich sind nur die Regelungen im Vertrag
              selbst.
            </p>
            <!-- <p style="text-align: justify">
              <a
                href="https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/newenergycloud/documents/220615_Preisblatt.pdf"
                style="color: red"
                >Preisblatt zum Download</a
              >
            </p> -->
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="3" xl="3">
          <Advantages name="Advantages" />
        </v-col>
      </v-row>

      <!-- Edit dialog -->
      <v-dialog v-if="edit" persistent v-model="edit" fullscreen>
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Component
          </v-card-title>
          <v-card-text class="pt-5">
            <Core
              :editProps="editProps"
              @emitProps="setPreviewProps"
              @save="save"
              @cancel="cancel"
              name="Core" />
            <!-- Preview -->
            <h2 class="my-2 text-h4 black--text">Preview:</h2>
            <CompPriceOverview
              v-if="previewProps"
              name="CompPriceOverview"
              :props="previewProps" />
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import CompPriceOverview from "./CompPriceOverview";
import Advantages from "./Internal/Advantages";
import Core from "../editor/Core.vue";
import {
  getMehrPrices,
  getPackagePrices,
  getRecommendedTarifs,
} from "../api/api";

export default {
  name: "CompPriceOverview",
  props: ["props", "edit", "index", "componentId"],
  data() {
    return {
      editProps: {},
      previewProps: null,
      mehrPrices: null,
      mehrPricesSpecial: null,
      packagePrices: null,
      packagePricesSpecial: null,
      tarifs: null,
      tarifsSpecial: null,
    };
  },
  watch: {
    edit: function () {
      this.editProps.color_background = {};
      this.editProps.color_background.type = "color";
      this.editProps.color_background.name = "color_background";
      this.editProps.color_background.value =
        this.props.color_background ?? "#FFFFFF";

      this.editProps.target = this.props.target ?? "";
      this.editProps.pt = this.props.pt ?? 0;
      this.editProps.pb = this.props.pb ?? 0;
    },
  },
  async mounted() {
    this.$store.commit("setLoading", true);
    this.tarifs = await getRecommendedTarifs(
      9900080000007,
      10777,
      3500,
      "cloud"
    );
    this.tarifs = this.tarifs.data.tarifs;
    this.tarifsSpecial = await getRecommendedTarifs(
      9901000000001,
      18211,
      3500,
      "cloud"
    );
    this.tarifsSpecial = this.tarifsSpecial.data.tarifs;
    this.mehrPrices = await getMehrPrices(9900080000007, 10777);
    this.mehrPrices = this.mehrPrices.data;
    this.mehrPricesSpecial = await getMehrPrices(9901000000001, 18211);
    this.mehrPricesSpecial = this.mehrPricesSpecial.data;
    this.packagePrices = await getPackagePrices(9900080000007, 10777);
    this.packagePrices = this.packagePrices.data;
    this.packagePricesSpecial = await getPackagePrices(9901000000001, 18211);
    this.packagePricesSpecial = this.packagePricesSpecial.data;
    this.$store.commit("setLoading", false);
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
      this.$emit("save", this.previewProps, this.index, this.componentId);
    },
    cancel() {
      this.$emit("cancel");
    },
    setPreviewProps(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
    },
    formatNetto(priceObject) {
      return (
        (((priceObject.price / 1.19) * 100) / 100)
          .toFixed(2)
          .replace(".", ",") + priceObject.price_unit
      );
    },
    formatMwst(priceObject) {
      return (
        (((priceObject.price - priceObject.price / 1.19) * 100) / 100)
          .toFixed(2)
          .replace(".", ",") + priceObject.price_unit
      );
    },
    formatBrutto(priceObject) {
      return (
        ((priceObject.price * 100) / 100).toFixed(2).replace(".", ",") +
        priceObject.price_unit
      );
    },
    formatedFull(priceObject) {
      return `${this.formatBrutto(priceObject)} (brutto, ${this.formatNetto(
        priceObject
      )} netto, ${this.formatMwst(priceObject)} MwSt.)`;
    },
  },
  components: {
    Core,
    CompPriceOverview,
    Advantages,
  },
};
</script>
