import Vue from "vue";
import Header from "@/components/Header";
import CustomHeader from "@/components/CustomHeader";
import Footer from "@/components/Footer";
import CustomFooter from "@/components/CustomFooter";
import CompImg from "@/components/CompImg";
import CompText from "@/components/CompText";
import CompTimeline from "@/components/CompTimeline";
import CompSpeechBubble from "@/components/CompSpeechBubble";
import CompText2Cols from "@/components/CompText2Cols";
import CompInformation from "@/components/CompInformation";
import CompGrid3x3 from "@/components/CompGrid3x3";
import CompGrid3x1 from "@/components/CompGrid3x1";
import CompGrid2x1 from "@/components/CompGrid2x1";
import CompGrid3x1Links from "@/components/CompGrid3x1Links";
import CompInformationToTarif from "@/components/CompInformationToTarif";
import CompInformationToTarifBigImage from "@/components/CompInformationToTarifBigImage";
import CompBannerToTarif from "@/components/CompBannerToTarif";
import Comp3Steps from "@/components/Comp3Steps";
import CompSeals from "@/components/CompSeals";
import CompTarifFull from "@/components/CompTarifFull";
import FormContact from "@/components/checkout/FormContact";
import FormSupplyObject from "@/components/checkout/FormSupplyObject";
import CompTextImgText from "@/components/CompTextImgText";
import FormPayment from "@/components/checkout/FormPayment";
import FormOverview from "@/components/checkout/FormOverview";
import FormSupplyObjectPV from "@/components/checkout/FormSupplyObjectPV";
import FormSupplyObjectWarmpump from "@/components/checkout/FormSupplyObjectWarmpump";
import FormSupplyCloudToCar from "@/components/checkout/FormSupplyCloudToCar";
import FormSupplyCloudToShare from "@/components/checkout/FormSupplyCloudToShare";
import CompInformationTextImage from "@/components/CompInformationTextImage";
import ConnSpace from "@/components/connectors/ConnSpace";
import CompContractTermination from "@/components/CompContractTermination";
import CompTarifFriends from "@/components/CompTarifFriends";
import CompThgForm from "@/components/CompThgForm";
import TarifHeaderFriends from "../components/Internal/TarifHeaderFriends.vue";
import CompPriceOverview from "@/components/CompPriceOverview";
import CompTextImageFullWidth from "@/components/CompTextImageFullWidth";
import CompTextImageOverlap from "@/components/CompTextImageOverlap";
import CompBgImageButton from "@/components/CompBgImageButton";
import CompBlackBox from "@/components/CompBlackBox";
import CompCareerForm from "@/components/CompCareerForm";

Vue.component("Header", Header);
Vue.component("CustomHeader", CustomHeader);
Vue.component("Footer", Footer);
Vue.component("CompImg", CompImg);
Vue.component("CompText", CompText);
Vue.component("CompTimeline", CompTimeline);
Vue.component("CompSpeechBubble", CompSpeechBubble);
Vue.component("CompText2Cols", CompText2Cols);
Vue.component("CompInformation", CompInformation);
Vue.component("CompGrid3x3", CompGrid3x3);
Vue.component("CompInformationToTarif", CompInformationToTarif);
Vue.component("CompInformationToTarifBigImage", CompInformationToTarifBigImage);
Vue.component("CompBannerToTarif", CompBannerToTarif);
Vue.component("CompGrid3x1", CompGrid3x1);
Vue.component("CompGrid2x1", CompGrid2x1);
Vue.component("CompGrid3x1Links", CompGrid3x1Links);
Vue.component("Comp3Steps", Comp3Steps);
Vue.component("CompSeals", CompSeals);
Vue.component("CompTarifFull", CompTarifFull);
Vue.component("FormContact", FormContact);
Vue.component("FormSupplyObject", FormSupplyObject);
Vue.component("CompTextImgText", CompTextImgText);
Vue.component("FormPayment", FormPayment);
Vue.component("FormOverview", FormOverview);
Vue.component("FormSupplyObjectPV", FormSupplyObjectPV);
Vue.component("FormSupplyObjectWarmpump", FormSupplyObjectWarmpump);
Vue.component("FormSupplyCloudToCar", FormSupplyCloudToCar);
Vue.component("FormSupplyCloudToShare", FormSupplyCloudToShare);
Vue.component("CompInformationTextImage", CompInformationTextImage);
Vue.component("CustomFooter", CustomFooter);
Vue.component("ConnSpace", ConnSpace);
Vue.component("CompContractTermination", CompContractTermination);
Vue.component("CompTarifFriends", CompTarifFriends);
Vue.component("TarifHeaderFriends", TarifHeaderFriends);
Vue.component("CompThgForm", CompThgForm);
Vue.component("CompPriceOverview", CompPriceOverview);
Vue.component("CompTextImageFullWidth", CompTextImageFullWidth);
Vue.component("CompTextImageOverlap", CompTextImageOverlap);
Vue.component("CompBgImageButton", CompBgImageButton);
Vue.component("CompBlackBox", CompBlackBox);
Vue.component("CompCareerForm", CompCareerForm);
