<template>
  <v-snackbar
    v-if="props"
    v-model="cookieBanner"
    :timeout="-1"
    color="white"
    max-width="1000"
    transition="scroll-y-transition"
    elevation="8">
    <div class="pa-3">
      <div class="black--text" v-if="!settings" v-html="props.text"></div>
      <div class="black--text" v-if="settings">
        <div v-html="props.text_settings"></div>
        <v-expansion-panels light class="py-2">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div v-html="props.header_functional"></div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row justify="center">
                <v-col cols="10">
                  <div v-html="props.text_functional"></div>
                </v-col>
                <v-col cols="2" align="right">
                  <v-switch
                    disabled
                    v-model="marketing"
                    color="green"></v-switch>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <div v-html="props.header_statistics"></div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row justify="center">
                <v-col cols="10">
                  <div v-html="props.text_statistics"></div>
                </v-col>
                <v-col cols="2" align="right">
                  <v-switch v-model="marketing" color="black"></v-switch>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <div v-html="props.header_marketing"></div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row justify="center">
                <v-col cols="10">
                  <div v-html="props.text_marketing"></div>
                </v-col>
                <v-col cols="2" align="right">
                  <v-switch v-model="marketing" color="black"></v-switch>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <br />
      <v-row class="px-2">
        <v-col cols="4" align="left" class="pa-0">
          <v-btn
            color="success"
            v-html="props.text_btn_1"
            @click="$store.commit('setCookieBanner', false)"></v-btn>
        </v-col>
        <v-col cols="4" align="center" class="pa-0">
          <v-btn
            color="primary"
            v-html="props.text_btn_2"
            @click="$store.commit('setCookieBanner', false)"></v-btn>
        </v-col>
        <v-col v-show="!settings" cols="4" align="right" class="pa-0">
          <v-btn
            color="primary"
            v-html="props.text_btn_3"
            @click="settings = true"></v-btn>
        </v-col>
        <v-col v-show="settings" cols="4" align="right" class="pa-0">
          <v-btn
            color="primary"
            v-html="props.text_btn_3_settings"
            @click="$store.commit('setCookieBanner', false)"></v-btn>
        </v-col>
      </v-row>
      <v-row class="px-2">
        <v-col cols="3" align="left" class="pb-1 px-0">
          <a :href="props.link_1" v-html="props.text_link_1"></a>
        </v-col>
        <v-col cols="6" align="center" class="pb-1 px-0">
          <a :href="props.link_2" v-html="props.text_link_2"></a>
        </v-col>
        <v-col cols="3" align="right" class="pb-1 px-0">
          <a :href="props.link_3" v-html="props.text_link_3"></a>
        </v-col>
      </v-row>
    </div>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CookieBanner",
  data() {
    return {
      settings: false,
      functional: true,
      statistics: true,
      marketing: true,
    };
  },
  computed: {
    ...mapState({
      props: state => {
        if (!state.config) return [];
        return state.config.internal_components["cookie_banner"] ?? null;
      },
      cookieBanner: state => {
        return state.cookie_banner;
      },
    }),
  },
};
</script>

<style></style>
