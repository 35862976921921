<template>
  <v-sheet
    :id="`${props.target}`"
    :color="props.color_background"
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`">
    <v-container fluid v-if="props" class="container-padding">
      <v-row justify="center">
        <v-col>
          <b
            v-if="props.sub_headline && props.sub_headline[language]"
            v-html="props.sub_headline[language]"></b>
          <h2
            v-if="props.headline && props.headline[language]"
            v-html="props.headline[language]"></h2>
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.xl" cols="12" sm="12" md="12" lg="3">
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-10">
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-row>
            <v-col>
              <p class="text-h1 secondary--text">01</p>
            </v-col>
            <v-col cols="12">
              <h4
                v-if="props.headline_step_1 && props.headline_step_1[language]"
                v-html="props.headline_step_1[language]"></h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p
                v-if="props.text_step_1 && props.text_step_1[language]"
                v-html="props.text_step_1[language]"></p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-row>
            <v-col>
              <p class="text-h1 secondary--text">02</p>
            </v-col>
            <v-col cols="12">
              <h4
                class="text-h4"
                v-if="props.headline_step_2 && props.headline_step_2[language]"
                v-html="props.headline_step_2[language]"></h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p
                v-if="props.text_step_2 && props.text_step_2[language]"
                v-html="props.text_step_2[language]"></p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-row>
            <v-col>
              <p class="text-h1 secondary--text">03</p>
            </v-col>
            <v-col cols="12">
              <h4
                class="text-h4"
                v-if="props.headline_step_3 && props.headline_step_3[language]"
                v-html="props.headline_step_3[language]"></h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p
                v-if="props.text_step_3 && props.text_step_3[language]"
                v-html="props.text_step_3[language]"></p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Edit dialog -->
      <v-dialog v-if="edit" persistent v-model="edit" fullscreen>
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Component
          </v-card-title>
          <v-card-text class="pt-5">
            <Core
              name="Core"
              :editProps="editProps"
              @emitProps="setPreviewProps"
              @save="save"
              @cancel="cancel" />
            <!-- Preview -->
            <h2 class="my-2 text-h4 black--text">Preview:</h2>
            <Comp3Steps
              v-if="previewProps"
              name="Comp3Steps"
              :props="previewProps" />
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import Comp3Steps from "./Comp3Steps";
import Core from "../editor/Core.vue";

export default {
  name: "Comp3Steps",
  props: ["props", "edit", "index", "componentId"],
  data() {
    return {
      editProps: {},
      previewProps: null,
    };
  },
  watch: {
    edit: function () {
      this.editProps.target = this.props.target ?? "";
      this.editProps.pt = this.props.pt ?? 0;
      this.editProps.pb = this.props.pb ?? 0;

      this.editProps.color_background = {};
      this.editProps.color_background.type = "color";
      this.editProps.color_background.name = "color_background";
      this.editProps.color_background.value =
        this.props.color_background ?? "#FFFFFF";

      this.editProps.sub_headline = {};
      this.editProps.sub_headline.type = "text";
      this.editProps.sub_headline.name = "sub_headline";
      this.editProps.sub_headline.value = this.props.sub_headline ?? {};

      this.editProps.headline = {};
      this.editProps.headline.type = "text";
      this.editProps.headline.name = "headline";
      this.editProps.headline.value = this.props.headline ?? {};

      this.editProps.headline_step_1 = {};
      this.editProps.headline_step_1.type = "text";
      this.editProps.headline_step_1.name = "headline_step_1";
      this.editProps.headline_step_1.value = this.props.headline_step_1 ?? {};

      this.editProps.headline_step_2 = {};
      this.editProps.headline_step_2.type = "text";
      this.editProps.headline_step_2.name = "headline_step_2";
      this.editProps.headline_step_2.value = this.props.headline_step_2 ?? {};

      this.editProps.headline_step_3 = {};
      this.editProps.headline_step_3.type = "text";
      this.editProps.headline_step_3.name = "headline_step_3";
      this.editProps.headline_step_3.value = this.props.headline_step_3 ?? {};

      this.editProps.text_step_1 = {};
      this.editProps.text_step_1.type = "text";
      this.editProps.text_step_1.name = "text_step_1";
      this.editProps.text_step_1.value = this.props.text_step_1 ?? {};

      this.editProps.text_step_2 = {};
      this.editProps.text_step_2.type = "text";
      this.editProps.text_step_2.name = "text_step_2";
      this.editProps.text_step_2.value = this.props.text_step_2 ?? {};

      this.editProps.text_step_3 = {};
      this.editProps.text_step_3.type = "text";
      this.editProps.text_step_3.name = "text_step_3";
      this.editProps.text_step_3.value = this.props.text_step_3 ?? {};
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
      this.$emit("save", this.previewProps, this.index, this.componentId);
    },
    cancel() {
      this.$emit("cancel");
    },
    setPreviewProps(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
    },
  },
  components: {
    Core,
    Comp3Steps,
  },
};
</script>

<style></style>
