export function getDefaultProps(componentName) {
  switch (componentName) {
    case "ConnSpace":
      return {
        height: 200,
      };
    case "Header":
      return {
        brand: "[brand]",
        sub_headline: "[message]",
        calculator: true,
        magazine: true,
        helpAndContact: true,
      };
    case "Footer":
      return {};
    case "CompText":
      return {
        text: { de: "[text]", en: "[text]" },
      };
    case "CompText2Cols":
      return {
        text_col_1: { de: "[text_col_1]", en: "[text_col_1]" },
        text_col_2: { de: "[text_col_2]", en: "[text_col_2]" },
      };
    case "CompTextImgText":
      return {
        headline: { de: "[headline]", en: "[headline]" },
        text_1: { de: "[text_1]", en: "[text_1]" },
        text_2: { de: "[text_2]", en: "[text_2]" },
        img_href:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        advantages: true,
      };
    case "CompImg":
      return {
        height: 200,
        img_href:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
      };
    case "CompTarifrechner":
      return {
        headline: "[tarif calculator title]",
        showRabattcode: false,
        submitButtonText: "[button text]",
      };
    case "CompInformation":
      return {
        headline: { de: "[headline]", en: "[headline]" },
        sub_headline: { de: "[sub_headline]", en: "[sub_headline]" },
        text: { de: "[text]", en: "[text]" },
      };
    case "CompInformationTextImage":
      return {
        headline: { de: "[headline]", en: "[headline]" },
        sub_headline: { de: "[sub_headline]", en: "[sub_headline]" },
        text: { de: "[text]", en: "[text]" },
        button_text: { de: "[button_text]", en: "[button_text]" },
        headline_img:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        img_href:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
      };
    case "CompInformationToTarif":
      return {
        headline: { de: "[headline]", en: "[headline]" },
        sub_headline: { de: "[sub_headline]", en: "[sub_headline]" },
        text: { de: "[text]", en: "[text]" },
        button_text: { de: "[button_text]", en: "[button_text]" },
        img_href:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
      };
    case "CompInformationToTarifBigImage":
      return {
        headline: { de: "[headline]", en: "[headline]" },
        points: { de: "[points]", en: "[points]" },
        button_text: { de: "[button_text]", en: "[button_text]" },
        img_href:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
      };
    case "CompGrid3x3":
      return {
        headline: { de: "[headline]", en: "[headline]" },
        text_1_0: { de: "[text_1_0]", en: "[text_1_0]" },
        text_2_0: { de: "[text_2_0]", en: "[text_2_0]" },
        text_0_1: { de: "[text_0_1]", en: "[text_0_1]" },
        text_1_1: { de: "[text_1_1]", en: "[text_1_1]" },
        text_0_2: { de: "[text_0_2]", en: "[text_0_2]" },
        text_2_2: { de: "[text_2_2]", en: "[text_2_2]" },
        img_href_1:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        img_href_2:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        img_href_3:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
      };
    case "CompGrid2x1":
      return {
        img_href_1_0:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        headline: { de: "[headline]", en: "[headline]" },
        headline_1_0: { de: "[headline_1_0]", en: "[headline_1_0]" },
        text_1_0: { de: "[text_1_0]", en: "[text_1_0]" },
        img_href_2_0:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        headline_2_0: { de: "[headline_2_0]", en: "[headline_2_0]" },
        text_2_0: { de: "[text_2_0]", en: "[text_2_0]" },
      };
    case "CompGrid3x1":
      return {
        img_href_1_0:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        headline: { de: "[headline]", en: "[headline]" },
        headline_1_0: { de: "[headline_1_0]", en: "[headline_1_0]" },
        text_1_0: { de: "[text_1_0]", en: "[text_1_0]" },
        img_href_2_0:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        headline_2_0: { de: "[headline_2_0]", en: "[headline_2_0]" },
        text_2_0: { de: "[text_2_0]", en: "[text_2_0]" },
        img_href_3_0:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        headline_3_0: { de: "[headline_3_0]", en: "[headline_3_0]" },
        text_3_0: { de: "[text_3_0]", en: "[text_3_0]" },
      };
    case "CompGrid3x1Links":
      return {
        img_href_1_0:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        headline: { de: "[headline]", en: "[headline]" },
        headline_1_0: { de: "[headline_1_0]", en: "[headline_1_0]" },
        text_1_0: { de: "[text_1_0]", en: "[text_1_0]" },
        link_text_1_0: { de: "[link_text_1_0]", en: "[link_text_1_0]" },
        img_href_2_0:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        headline_2_0: { de: "[headline_2_0]", en: "[headline_2_0]" },
        text_2_0: { de: "[text_2_0]", en: "[text_2_0]" },
        link_text_2_0: { de: "[link_text_2_0]", en: "[link_text_2_0]" },
        img_href_3_0:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        headline_3_0: { de: "[headline_3_0]", en: "[headline_3_0]" },
        text_3_0: { de: "[text_3_0]", en: "[text_3_0]" },
        link_text_3_0: { de: "[link_text_3_0]", en: "[link_text_3_0]" },
      };
    case "Comp3Steps":
      return {
        sub_headline: { de: "[sub_headline]", en: "[sub_headline]" },
        headline: { de: "[headline]", en: "[headline]" },
        img_href_step_1:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        headline_step_1: { de: "[headline_step_1]", en: "[headline_step_1]" },
        text_step_1: { de: "[text_step_1]", en: "[text_step_1]" },
        img_href_step_2:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        headline_step_2: { de: "[headline_step_2]", en: "[headline_step_2]" },
        text_step_2: { de: "[text_step_2]", en: "[text_step_2]" },
        img_href_step_3:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        headline_step_3: { de: "[headline_step_3]", en: "[headline_step_3]" },
        text_step_3: { de: "[text_step_3]", en: "[text_step_3]" },
      };
    case "CompBannerToTarif":
      return {
        headline: { de: "[headline]", en: "[headline]" },
        button_text: { de: "[button_text]", en: "[button_text]" },
        img_href:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
      };
    case "CompSeals":
      return {
        img_href_1:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        caption_1: { de: "[caption_1]", en: "[caption_1]" },
        img_href_2:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        caption_2: { de: "[caption_2]", en: "[caption_2]" },
        img_href_3:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        caption_3: { de: "[caption_3]", en: "[caption_3]" },
        text: { de: "[text]", en: "[text]" },
      };
    case "CompSpeechBubble":
      return {
        img_href:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        text: { de: "[text]", en: "[text]" },
        caption: { de: "[caption]", en: "[caption]" },
        headline: { de: "[headline]", en: "[headline]" },
      };
    case "CompTimeline":
      return {
        headline: { de: "[headline]", en: "[headline]" },
        sub_headline: { de: "[sub_headline]", en: "[sub_headline]" },
      };
    case "CompTextImageFullWidth":
      return {
        text: { de: "[text]", en: "[text]" },
        img_href:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        button_text: { de: "[button_text]", en: "[button_text]" },
      };
    case "CompTextImageOverlap":
      return {
        headline: { de: "[headline]", en: "[headline]" },
        text: { de: "[text]", en: "[text]" },
        img_href:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        button_text: { de: "[button_text]", en: "[button_text]" },
      };
    case "CompBgImageButton":
      return {
        text: { de: "[text]", en: "[text]" },
        button_text: { de: "[button_text]", en: "[button_text]" },
        height: 700,
        img_href:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
        mobile_img_href:
          "https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/common/images/placeholder-image.png",
      };
    case "CompBlackBox":
      return {
        text: { de: "[text]", en: "[text]" },
        button_text: { de: "[button_text]", en: "[button_text]" },
        link_text: { de: "[link_text]", en: "[link_text]" },
      };
    default:
      return {};
  }
}

export function getSelectItems(types) {
  switch (types) {
    case "aligns":
      return ["left", "center", "right"];

    default:
      return [];
  }
}
