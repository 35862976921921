var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('h1',{staticClass:"text-h5 pb-10"},[_vm._v("Objektdaten Hausstrom")]),_c('h3',[_vm._v("STROMDATEN")]),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Bisheriger Stromlieferant (Hausstrom)*","rules":[function (v) { return !!v || 'Bitte ausfüllen!'; }],"required":""},model:{value:(_vm.form.energyData.currentSupplier.value),callback:function ($$v) {_vm.$set(_vm.form.energyData.currentSupplier, "value", $$v)},expression:"form.energyData.currentSupplier.value"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Kundennummer beim bisherigen Stromanbieter"},model:{value:(_vm.form.energyData.customerNumberOld.value),callback:function ($$v) {_vm.$set(_vm.form.energyData.customerNumberOld, "value", $$v)},expression:"form.energyData.customerNumberOld.value"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Zählernummer*","rules":[function (v) { return !!v || 'Bitte ausfüllen!'; }],"required":""},model:{value:(_vm.form.energyData.meteringNumber.value),callback:function ($$v) {_vm.$set(_vm.form.energyData.meteringNumber, "value", $$v)},expression:"form.energyData.meteringNumber.value"}})],1)],1),_c('h3',{staticClass:"pt-5"},[_vm._v("WECHSELGRUND*")]),_c('v-row',[_c('v-col',[_c('v-radio-group',{staticClass:"mt-1",attrs:{"column":"","mandatory":""},model:{value:(_vm.changeReason),callback:function ($$v) {_vm.changeReason=$$v},expression:"changeReason"}},_vm._l((_vm.changeReasonItems),function(item,index){return _c('v-radio',{key:(index + "-change-reason"),attrs:{"label":item.name,"value":item.value}})}),1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.changeReason != -1
            ? _vm.changeReasonItems[_vm.changeReason - 1].requiredDate
              ? "Datum auswählen*"
              : "Datum auswählen"
            : "Datum auswählen","rules":_vm.changeReason != -1
            ? _vm.changeReasonItems[_vm.changeReason - 1].requiredDate
              ? [function (v) { return !!v || 'Bitte ausfüllen!'; }]
              : []
            : [],"type":"date"},model:{value:(_vm.form.changeReason.date.value),callback:function ($$v) {_vm.$set(_vm.form.changeReason.date, "value", $$v)},expression:"form.changeReason.date.value"}})],1)],1),_c('p',{staticStyle:{"font-size":"8pt","color":"grey"}},[_vm._v("*Pflichtangaben")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }