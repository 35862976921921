import VueRouter from "vue-router";

import Callback from "@/pages/Callback";
// import Landing from "@/pages/Landing";
// import TarifRechner from "@/pages/TarifRechner";
import Editor from "@/editor/Editor";
import Checkout from "@/pages/Checkout";
import MeterUpload from "@/pages/MeterUpload";
// import ContractTermination from "@/pages/ContractTermination";
// import Information from "@/pages/Information";
// import MagazineOverview from "@/pages/MagazineOverview";
// import Article from "@/pages/Article";
import AbstractPage from "@/pages/AbstractPage";
import store from "@/store/store";

const routes = [
  {
    path: "/callback",
    name: "Callback",
    component: Callback,
  },
  { path: "/editor", component: Editor },
  { path: "/checkout", component: Checkout },
  { path: "/meter-upload", component: MeterUpload },
  { path: "/", component: AbstractPage },
  { path: "/:page", component: AbstractPage },
  { path: "/:type/:page", component: AbstractPage },
  // { path: "/brand/:data", component: Information },
  // { path: "/magazin/:article", component: Article },
  // { path: "/pm/:article", component: Article },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    from.path == "/checkout" &&
    to.path != "/belieferungsanfrage-erfolgreich" &&
    !(to.path == "/" && to.query.id == "27612")
  ) {
    if (
      !window.confirm(
        "Sind sie sicher, dass Sie den Checkout verlassen wollen?"
      )
    ) {
      return;
    }
  }
  if (from.path == "/editor") {
    console.log("Leave editor");
    if (
      !window.confirm(
        "Are you sure you want to leave the editor, unsaved changes will be lost?"
      )
    ) {
      return;
    }
  }
  if (to.path == "/checkout") {
    if (!store.getters.getselectedTarifAndOptions.isSelected) {
      next((to = "/"));
    }
  }
  next();
});
export default router;
