import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueRouter from "vue-router";
import "@/components";

import router from "./routes/index";
import store from "./store/store";

Vue.use(VueRouter);
Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount("#app");

window.snapSaveState = () => {
  document.querySelector("#app").setAttribute("data-server-rendered", "true");
};
