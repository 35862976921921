<template>
  <v-sheet
    :id="`${props.target}`"
    :color="props.color_background"
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`">
    <v-container fluid v-if="props" class="container-padding">
      <v-row class="mt-10" justify="center">
        <v-col>
          <h4
            v-if="props.headline && props.headline[language]"
            class="text-h5 font-weight-bold mb-8"
            v-html="props.headline[language]"></h4>
          <h5
            v-if="props.sub_headline && props.sub_headline[language]"
            class="text-h6"
            v-html="props.sub_headline[language]"></h5>
        </v-col>
      </v-row>
      <v-row justify="center" class="mb-10">
        <v-col class="pa-0" cols="12" sm="12" md="10" lg="10" xl="9">
          <!-- TODO vifs for items -->
          <v-timeline
            :dense="$vuetify.breakpoint.smAndDown"
            class="timeline-elements">
            <v-timeline-item
              v-for="(item, i) in props.items"
              :key="i"
              :color="item.color"
              :icon="item.icon"
              fill-dot
              small>
              <span
                :style="`color: ${item.color}`"
                slot="opposite"
                class="text-h5"
                v-html="item.year"></span>
              <div
                :class="$vuetify.breakpoint.smAndDown ? 'py-4 pr-5' : 'py-4'">
                <h2
                  class="text-h6 mb-3 font-weight-light"
                  v-html="item.headline[language]"></h2>
                <span v-html="item.text[language]"></span>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>

      <!-- Edit dialog -->
      <v-dialog v-if="edit" persistent v-model="edit" fullscreen>
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Component
          </v-card-title>
          <v-card-text class="pt-5">
            <Core
              :editProps="editProps"
              @emitProps="setPreviewProps"
              @save="save"
              @cancel="cancel" />
            <!-- Preview -->
            <h2 class="my-2 text-h4 black--text">Preview:</h2>
            <CompBannerToTarif
              v-if="previewProps"
              name="CompBannerToTarif"
              :props="previewProps" />
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import CompBannerToTarif from "./CompBannerToTarif";
import Core from "../editor/Core.vue";

export default {
  name: "CompBannerToTarif",
  props: ["props", "edit", "index", "componentId"],
  data() {
    return {
      editProps: {},
      previewProps: null,
    };
  },
  watch: {
    edit: function () {
      this.editProps.color_background = {};
      this.editProps.color_background.type = "color";
      this.editProps.color_background.name = "color_background";
      this.editProps.color_background.value =
        this.props.color_background ?? "#FFFFFF";

      this.editProps.target = this.props.target ?? "";
      this.editProps.pt = this.props.pt ?? 0;
      this.editProps.pb = this.props.pb ?? 0;

      this.editProps.headline = {};
      this.editProps.headline.type = "text";
      this.editProps.headline.name = "headline";
      this.editProps.headline.value = this.props.headline ?? {};

      this.editProps.sub_headline = {};
      this.editProps.sub_headline.type = "text";
      this.editProps.sub_headline.name = "sub_headline";
      this.editProps.sub_headline.value = this.props.sub_headline ?? {};
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
      this.$emit("save", this.previewProps, this.index, this.componentId);
    },
    cancel() {
      this.$emit("cancel");
    },
    setPreviewProps(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
    },
  },
  components: {
    Core,
    CompBannerToTarif,
  },
};
</script>
