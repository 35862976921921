<template>
  <v-card>
    <v-card-text class="pt-5">
      <v-btn
        v-if="tabSelected != 2"
        @click="dialogAddPage = true"
        dark
        small
        color="green lighten-1"
        >Add new</v-btn
      >
      <v-data-table
        loading-text="Loading... Please wait"
        :headers="headersConfig"
        :items="objects"
        :items-per-page="20"
        class="elevation-0">
        <!-- eslint-disable-next-line  -->
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" color="green" @click="edit(item)">
            mdi-pencil
          </v-icon>
          <v-icon color="red" @click="deleteObject(item)"> mdi-delete </v-icon>
        </template>
        <!-- eslint-disable-next-line  -->
        <template v-slot:item.is_production_file="{ item }">
          <v-icon v-if="item.is_production_file" color="success"
            >mdi-check</v-icon
          >
          <v-icon v-if="!item.is_production_file" color="error"
            >mdi-close</v-icon
          >
        </template>
      </v-data-table>
    </v-card-text>
    <v-divider></v-divider>
    <!-- Add dialog -->
    <v-dialog persistent v-model="dialogAddPage" fullscreen>
      <v-card :loading="uploadConfigLoading">
        <v-card-title class="text-h5 black white--text">
          Add a new Page of type {{ tabSelected }}
        </v-card-title>

        <v-card-text class="pt-5">
          <v-form ref="formAddPage" lazy-validation>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="formAddPage.version"
                  label="Version*"
                  :rules="[v => !!v || 'Bitte ausfüllen!']"
                  required></v-text-field>
                <v-text-field
                  v-model="formAddPage.path"
                  label="Path*"
                  :rules="[v => !!v || 'Bitte ausfüllen!']"
                  required></v-text-field>
                <v-select
                  :items="[
                    { text: 'Page', value: 'page' },
                    { text: 'Article', value: 'press' },
                  ]"
                  v-model="formAddPage.type"
                  label="Type"></v-select>
              </v-col>
            </v-row>
            <!--TODO finish form for props  -->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="addPage"> Add </v-btn>
          <v-btn color="error" text @click="dialogAddPage = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { savePageConfig } from "../../api/api";
export default {
  props: ["tabSelected", "config"],
  data() {
    return {
      dialogAddPage: false,
      uploadConfigLoading: false,
      formAddPage: {
        path: "",
        version: "",
        type: "",
        props: {},
        components: [],
      },
    };
  },
  computed: {
    objects() {
      if (!this.config) return [];
      switch (this.tabSelected) {
        case 0:
          return this.config.data.pages.filter(item => {
            if (
              item.type == "page" &&
              !["/tarifrechner", "/checkout"].includes(item.path)
            )
              return true;
            return false;
          });
        case 1:
          return this.config.data.pages.filter(item => item.type == "press");
        case 2: {
          let components = Object.keys(this.config.data.common_components);
          let componentsList = [];
          components.forEach(cmp => componentsList.push({ component: cmp }));
          return componentsList;
        }
        default:
          return this.config.data.pages.filter(item => item.type == "page");
      }
    },
    headersConfig() {
      if (!this.config) return [];
      switch (this.tabSelected) {
        case 0:
          return [
            { text: "Path", value: "path" },
            { text: "Name", value: "name" },
            { text: "Description", value: "description" },
            {
              text: "Actions",
              value: "actions",
              sortable: false,
              align: "end",
            },
          ];
        case 1:
          return [
            { text: "Path", value: "path" },
            { text: "Author", value: "props.author" },
            { text: "Publisher", value: "props.publisher" },
            { text: "Publish Date", value: "props.publish_date" },
            { text: "Publish Region", value: "props.publish_region" },
            { text: "Title", value: "props.title.de" },
            { text: "Sub Title", value: "props.sub_title.de" },
            { text: "Category", value: "props.category.de" },
            { text: "Tags", value: "props.tags" },
            {
              text: "Actions",
              value: "actions",
              sortable: false,
              align: "end",
            },
          ];
        case 2:
          return [
            { text: "Component", value: "component" },
            // {
            //   text: "Actions",
            //   value: "actions",
            //   sortable: false,
            //   align: "end",
            // },
          ];

        default:
          return [
            { text: "Path", value: "path" },
            {
              text: "Actions",
              value: "actions",
              sortable: false,
              align: "end",
            },
          ];
      }
    },
  },
  methods: {
    edit(item) {
      this.$emit("editObject", item);
    },
    deleteObject() {},
    addPage() {
      if (this.$refs.formAddPage.validate()) {
        let page = {
          path: this.formAddPage.path,
          type: this.formAddPage.type,
          props: this.formAddPage.props,
          components: this.formAddPage.components,
        };

        this.uploadConfigLoading = true;
        savePageConfig(this.config.meta_data.identifier, page, {
          version: this.formAddPage.version,
          comment: "Created page at path " + this.formAddPage.path,
        })
          .then(() => {
            this.uploadConfigLoading = false;
            this.dialogAddPage = false;
            location.reload();
          })
          .catch(error => {
            console.log(error);
            this.uploadConfigLoading = false;
            this.dialogAddPage = false;
          });
      }
    },
  },
  watch: {
    dialogAddPage() {
      this.formAddPage.type = this.tabSelected == 0 ? "page" : "press";
    },
  },
};
</script>

<style></style>
