import axios from "axios";
import store from "../store/store";
import { checkAndRefreshAccessToken } from "../auth/auth";

/* eslint-disable-next-line */
var backendURL =
  /* eslint-disable-next-line */
  config.VUE_APP_ENV_BACKEND_URL || process.env.VUE_APP_ENV_BACKEND_URL;

var brand =
  /* eslint-disable-next-line */
  config.VUE_APP_ENV_BRAND || process.env.VUE_APP_ENV_BRAND;
// Api base configuration
const api_instance = axios.create({
  baseURL: backendURL,
  timeout: 60000,
});

export async function createSupplyRequest() {
  try {
    let input = store.getters.getCheckoutData;
    let tarif = store.getters.getselectedTarifAndOptions;
    let supply_request = {
      data: input,
      tarif: tarif,
    };
    await api_instance.post(
      "/v1/newenergycloud/supply_request",
      supply_request
    );
  } catch (error) {
    if (error.toString().includes("timeout of")) return "1001";
    return "1000";
  }
  return "200";
}

export async function getPackagePrices(bdew_code, postal_code) {
  let price_list = await api_instance.get(
    `/v1/newenergycloud/additional_packages/list?bdew_code=${bdew_code}&postal_code=${postal_code}`
  );
  return price_list;
}

export async function getMehrPrices(bdew_code, postal_code) {
  let price_list = await api_instance.get(
    `/v1/newenergycloud/mehrpreis/list?bdew_code=${bdew_code}&postal_code=${postal_code}`
  );
  return price_list;
}

export async function getTarifs() {
  let price_list = await api_instance.get("/v1/newenergycloud/tarifs/list");
  return price_list;
}

export async function getRecommendedTarifs(
  bdew_code,
  postal_code,
  consumption,
  type
) {
  let recommendedTarifs = await api_instance.get(
    `/v1/newenergycloud/tarifs?consumption=${consumption}&postal_code=${postal_code}&bdew_code=${bdew_code}&type=${type}`
  );
  return recommendedTarifs;
}

export async function getConfigs() {
  await checkAndRefreshAccessToken();
  let config = {
    headers: {
      Authorization: `Bearer ${store.getters.access_token}`,
    },
  };
  let configList = await api_instance.get(
    `/v1/mbf/configuration/list?brand=${brand}`,
    config
  );
  return configList;
}

export async function getConfigByUUID(uuid) {
  await checkAndRefreshAccessToken();
  let config = {
    headers: {
      Authorization: `Bearer ${store.getters.access_token}`,
    },
  };
  let configByUUID = await api_instance.get(
    `/v1/mbf/configuration?brand=${brand}&config_name=${brand}_${uuid}.json`,
    config
  );
  return configByUUID;
}

export async function savePageConfig(uuid, content, meta_input) {
  await checkAndRefreshAccessToken();
  let config = {
    headers: {
      Authorization: `Bearer ${store.getters.access_token}`,
    },
  };
  let data = {
    content: content,
    meta_input: {
      comment: meta_input.comment,
      version: meta_input.version,
    },
  };
  let configPage = await api_instance.put(
    `/v1/mbf/configuration?brand=${brand}&config_name=${brand}_${uuid}.json`,
    data,
    config
  );
  return configPage;
}

export async function getImages() {
  await checkAndRefreshAccessToken();
  let config = {
    headers: {
      Authorization: `Bearer ${store.getters.access_token}`,
    },
  };
  let images = await api_instance.get(
    `/v1/mbf/image/list?brand=${brand}`,
    config
  );
  return images;
}

export async function uploadImage(image) {
  await checkAndRefreshAccessToken();
  let config = {
    headers: {
      Authorization: `Bearer ${store.getters.access_token}`,
    },
  };
  const formData = new FormData();
  formData.append("image", image, image.name);
  let res = await api_instance.post(
    `/v1/mbf/image?brand=${brand}&file_name=${image.name}`,
    formData,
    config
  );
  return res;
}

export async function toProduction(uuid) {
  await checkAndRefreshAccessToken();
  let config = {
    headers: {
      Authorization: `Bearer ${store.getters.access_token}`,
    },
  };
  let res = await api_instance.post(
    `/v1/mbf/configuration/to_production?config_name=${brand}_${uuid}.json&brand=${brand}`,
    {},
    config
  );
  return res;
}

export async function deleteConfig(uuid) {
  await checkAndRefreshAccessToken();
  let config = {
    headers: {
      Authorization: `Bearer ${store.getters.access_token}`,
    },
  };
  let res = await api_instance.delete(
    `/v1/mbf/configuration?config_name=${brand}_${uuid}.json&brand=${brand}`,
    config
  );
  return res;
}

export async function terminationRequest(data) {
  let res = await api_instance.post(
    `/v1/newenergycloud/contract_termination`,
    data
  );
  return res;
}

export async function getAddressData(postal_code, street, house_number) {
  let url = `/v1/address_data?postal_code=${postal_code}`;
  if (street) url += `&street=${street}`;
  if (house_number) url += `&house_number=${house_number}`;

  let addresses = await api_instance.get(url);
  return addresses;
}

export async function postThgQuotaRequest(payload) {
  let formData = new FormData();
  formData.append("salutation", payload.salutation);
  formData.append("first_name", payload.first_name);
  formData.append("last_name", payload.last_name);
  formData.append("email", payload.email);
  formData.append("phone", payload.phone);
  formData.append("license_plate", payload.license_plate);

  payload.files.forEach(file => formData.append("files", file));

  let res = await api_instance.post(
    `/v1/newenergycloud/thg_rate_request?&title=${payload.title}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return res;
}

export async function getHashedValues(hash) {
  let customer_data = await api_instance.get(
    `/v1/newenergycloud/meter-upload?payload=${hash}`
  );
  return customer_data;
}

export async function postMeterUploadRequest(payload) {
  let res = await api_instance.post(
    `/v1/newenergycloud/meter-upload?payload=${payload.payload}&to_email=${payload.email}`,
    payload.body
  );

  return res;
}

export async function postCareerRequest(payload) {
  let formData = new FormData();
  formData.append("position", payload.position);
  formData.append("salutation", payload.salutation);
  formData.append("title", payload.title);
  formData.append("first_name", payload.first_name);
  formData.append("last_name", payload.last_name);
  formData.append("street_and_number", payload.street_and_number);
  formData.append("zip_code", payload.zip_code);
  formData.append("city", payload.city);
  formData.append("country", payload.country);
  formData.append("phone", payload.phone);
  formData.append("email", payload.email);
  formData.append("start_date", payload.start_date);
  formData.append("week_hours", payload.week_hours);

  payload.application_letter.forEach(application_letter =>
    formData.append("application_letter", application_letter)
  );
  payload.cv.forEach(cv => formData.append("cv", cv));
  if (payload.qualifications) {
    payload.qualifications.forEach(qualifications =>
      formData.append("qualifications", qualifications)
    );
  }

  if (payload.work_samples) {
    payload.work_samples.forEach(work_samples =>
      formData.append("work_samples", work_samples)
    );
  }

  let res = await api_instance.post(`/v1/gsenergy/career_request`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return res;
}
