<template>
  <v-sheet
    :id="`${props.target}`"
    :color="props.color_background"
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`">
    <v-container fluid v-if="props" class="container-padding">
      <!-- Seals -->
      <v-row justify="center" class="px-lg-16">
        <v-col class="pa-0" cols="6" sm="2" md="2" lg="2" xl="2" align="center">
          <v-img
            v-if="props.img_href_1"
            width="88px"
            :src="props.img_href_1"></v-img>
          <span
            v-if="props.caption_1 && props.caption_1[language]"
            v-html="props.caption_1[language]"></span>
        </v-col>
        <v-col class="pa-0" cols="6" sm="2" md="2" lg="2" xl="2" align="center">
          <v-img
            v-if="props.img_href_2"
            width="88px"
            :src="props.img_href_2"></v-img>
          <span
            v-if="props.caption_2 && props.caption_2[language]"
            v-html="props.caption_2[language]"></span>
        </v-col>
        <v-col
          class="pa-0"
          cols="12"
          sm="2"
          md="2"
          lg="2"
          xl="2"
          align="center">
          <v-img
            v-if="props.img_href_3"
            width="88px"
            :src="props.img_href_3"></v-img>
          <span
            v-if="props.caption_3 && props.caption_3[language]"
            v-html="props.caption_3[language]"></span>
        </v-col>
      </v-row>
      <!-- Text benefits -->
      <v-row justify="center" class="px-lg-16 mt-10">
        <v-col
          v-if="props.text && props.text[language]"
          cols="12"
          sm="6"
          md="6"
          lg="6"
          xl="6">
          <p v-html="props.text[language]"></p>
        </v-col>
      </v-row>

      <!-- Edit dialog -->
      <v-dialog v-if="edit" persistent v-model="edit" fullscreen>
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Component
          </v-card-title>
          <v-card-text class="pt-5">
            <Core
              :editProps="editProps"
              @emitProps="setPreviewProps"
              @save="save"
              @cancel="cancel"
              name="Core" />
            <!-- Preview -->
            <h2 class="my-2 text-h4 black--text">Preview:</h2>
            <CompSeals
              v-if="previewProps"
              name="CompSeals"
              :props="previewProps" />
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import CompSeals from "./CompSeals";
import Core from "../editor/Core.vue";

export default {
  name: "CompSeals",
  props: ["props", "edit", "index", "componentId"],
  data() {
    return {
      editProps: {},
      previewProps: null,
    };
  },
  watch: {
    edit: function () {
      this.editProps.color_background = {};
      this.editProps.color_background.type = "color";
      this.editProps.color_background.name = "color_background";
      this.editProps.color_background.value =
        this.props.color_background ?? "#FFFFFF";

      this.editProps.target = this.props.target ?? "";
      this.editProps.pt = this.props.pt ?? 0;
      this.editProps.pb = this.props.pb ?? 0;

      this.editProps.caption_1 = {};
      this.editProps.caption_1.type = "text";
      this.editProps.caption_1.name = "caption_1";
      this.editProps.caption_1.value = this.props.caption_1 ?? {};

      this.editProps.caption_2 = {};
      this.editProps.caption_2.type = "text";
      this.editProps.caption_2.name = "caption_2";
      this.editProps.caption_2.value = this.props.caption_2 ?? {};

      this.editProps.caption_3 = {};
      this.editProps.caption_3.type = "text";
      this.editProps.caption_3.name = "caption_3";
      this.editProps.caption_3.value = this.props.caption_3 ?? {};

      this.editProps.img_href_1 = {};
      this.editProps.img_href_1.type = "image";
      this.editProps.img_href_1.name = "img_href_1";
      this.editProps.img_href_1.value = this.props.img_href_1 ?? "";

      this.editProps.img_href_2 = {};
      this.editProps.img_href_2.type = "image";
      this.editProps.img_href_2.name = "img_href_2";
      this.editProps.img_href_2.value = this.props.img_href_2 ?? "";

      this.editProps.img_href_3 = {};
      this.editProps.img_href_3.type = "image";
      this.editProps.img_href_3.name = "img_href_3";
      this.editProps.img_href_3.value = this.props.img_href_3 ?? "";

      this.editProps.text = {};
      this.editProps.text.type = "text";
      this.editProps.text.name = "text";
      this.editProps.text.value = this.props.text ?? {};
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
      this.$emit("save", this.previewProps, this.index, this.componentId);
    },
    cancel() {
      this.$emit("cancel");
    },
    setPreviewProps(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
    },
  },
  components: {
    Core,
    CompSeals,
  },
};
</script>

<style></style>
