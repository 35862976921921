<template>
  <div class="mt-4">
    <v-container>
      <v-row justify="center">
        <v-col v-if="!error" align="center"><b>Loading...</b></v-col>
        <v-col v-if="error" align="center">
          <b>There was an error getting the access token. :(</b>
        </v-col>
        <v-row> </v-row>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { getAccessToken } from "../auth/auth";
export default {
  data() {
    return {
      error: false,
    };
  },
  mounted() {
    // Save the access token
    getAccessToken(this.$route.query.code).catch(() => {
      this.error = true;
      this.$store.commit("reset_user_data");
      // window.location.href = getInitialAuthUrl();
    });
  },
};
</script>
