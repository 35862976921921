<template>
  <v-sheet
    :color="props.color_background"
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`">
    <v-container fluid v-if="props" class="container-padding">
      <v-card class="rounded-lg">
        <v-row>
          <!-- Headline sheet -->
          <v-col class="pl-3 py-0" cols="12" sm="12" md="4" lg="4" xl="4">
            <v-sheet
              :class="`pa-15 ${
                $vuetify.breakpoint.mdAndUp ? 'rounded-l-lg' : 'rounded-t-lg'
              }`"
              color="background_primary"
              height="100%">
              <h5
                class="text-caption font-weight-bold d-block"
                v-html="props.sub_headline[language]"></h5>
              <h1
                class="text-h4 d-block mt-4"
                v-html="props.headline[language]"></h1>
            </v-sheet>
          </v-col>
          <!-- Calculator form component -->
          <v-col class="pa-15" cols="12" sm="12" md="8" lg="8" xl="8">
            <TarifCalculator
              :edit="editCalculator"
              @saveCalculator="saveCalculator"
              @cancelCalculator="cancelCalculator"
              @setTarif="setTarif"
              :hidePhotovoltaic="true"
              :hidePowerStorage="true"
              tarifType="friends" />
          </v-col>
        </v-row>
      </v-card>
      <!-- Tarif Overview -->
      <TarifOverviewFriends
        v-if="tarifData"
        ref="tarifOverviewFriends"
        :tarifData="tarifData" />

      <!-- Edit Component -->
      <v-dialog persistent v-model="edit" width="70%">
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Component
          </v-card-title>

          <v-card-text class="pt-5">
            <h3 class="my-2">Headline</h3>
            <quill-editor v-model="editProps.headline" />
            <h3 class="my-2">Subheadling</h3>
            <quill-editor v-model="editProps.sub_headline" />
            <h3 class="my-2">Tarif Calculator</h3>
            <v-btn @click="editCalculator = true">Edit</v-btn>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="save"> Save </v-btn>
            <v-btn color="error" text @click="cancel"> Cancel </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import TarifCalculator from "./Internal/TarifCalculator.vue";
import TarifOverviewFriends from "./Internal/TarifOverviewFriends.vue";
export default {
  props: ["props", "edit", "index"],
  data() {
    return {
      editProps: { sub_headline: "", headline: "" },
      editCalculator: false,
      tarifData: null,
    };
  },
  watch: {
    edit: function () {
      Object.assign(this.editProps, this.props);
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save() {
      this.$emit("save", this.editProps, this.index);
    },
    cancel() {
      this.$emit("cancel");
    },
    saveCalculator(propsCalculator) {
      this.$emit("saveInternal", "tarif_calculator", propsCalculator);
      this.editCalculator = false;
    },
    cancelCalculator() {
      this.editCalculator = false;
    },
    setTarif(tarifData) {
      this.tarifData = tarifData;
    },
  },
  components: {
    TarifCalculator,
    TarifOverviewFriends,
  },
};
</script>

<style></style>
