<template>
  <v-container fluid v-if="selectedTarifAndOptions && mehrPrices">
    <v-expansion-panels>
      <v-expansion-panel class="rounded-lg">
        <!-- Header -->
        <v-expansion-panel-header disable-icon-rotate class="pa-10">
          <v-row justify="space-between" align="center">
            <v-col cols="12" sm="4" md="4" lg="4" xl="4">
              <h4 class="mb-2 text-h4">
                Basistarif
                {{
                  selectedTarifAndOptions.tarif
                    ? selectedTarifAndOptions.tarif.tarif_name
                    : "--"
                }}
              </h4>
              <p>(Hausstrom)</p></v-col
            >
            <v-col cols="12" sm="4" md="4" lg="4" xl="4">
              <div class="text-body-1">
                <p class="my-1" v-if="selectedTarifAndOptions.options.solHeat">
                  <span class="secondary--text font-weight-bold">+</span>
                  SolHeat
                </p>
                <p
                  class="my-1"
                  v-if="selectedTarifAndOptions.options.cloudToCar">
                  <span class="secondary--text font-weight-bold">+</span>
                  Cloud-to-Car
                </p>
                <p
                  class="my-1"
                  v-if="selectedTarifAndOptions.options.cloudToShare">
                  <span class="secondary--text font-weight-bold">+</span>
                  Cloud-to-Share
                </p>
                <p
                  class="my-1"
                  v-if="selectedTarifAndOptions.options.bestPriceGuarantee">
                  <span class="secondary--text font-weight-bold">+</span>
                  Best-Preis-Garantie
                </p>
                <p
                  class="my-1"
                  v-if="selectedTarifAndOptions.options.zeroCostCloud">
                  <span class="secondary--text font-weight-bold">+</span>
                  Zero-Cost-Paket
                </p>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              md="3"
              lg="3"
              xl="3"
              :class="`mt-4 mr-2 ${
                $vuetify.breakpoint.smAndUp ? 'text-right' : ''
              }`">
              <p class="mr-2 text-body-1 font-weight-bold">
                Vertrags<wbr />informationen
              </p>
            </v-col>
          </v-row>
          <template v-slot:actions>
            <v-icon color="black" medium> mdi-chevron-down </v-icon>
          </template>
        </v-expansion-panel-header>
        <!-- Content -->
        <v-expansion-panel-content id="TarifHeader" class="pa-0 text-body-2">
          <v-sheet class="pa-10 pb-16" color="background_primary">
            <h2
              :class="`text-h5 mb-10 ${
                $vuetify.breakpoint.smAndUp ? 'px-9' : 'px-0'
              } mt-2`">
              Vertragsdetails
            </h2>
            <v-row :class="`${$vuetify.breakpoint.smAndUp ? 'px-10' : 'px-0'}`">
              <v-col cols="12" sm="6">
                <!-- Consumption row -->
                <v-row>
                  <v-col cols="7" class="py-0">
                    <span class="font-weight-bold"
                      >Angegebener Verbrauch pro Jahr:</span
                    >
                  </v-col>
                  <v-col class="text-right py-0">
                    <span
                      ><nobr
                        >{{
                          selectedTarifAndOptions.information.consumption
                        }}
                        kWh</nobr
                      ></span
                    >
                  </v-col>
                </v-row>
                <!-- Photovoltaic row -->
                <v-row>
                  <v-col cols="7" class="py-0">
                    <span class="font-weight-bold"
                      >Leistung der PV-Anlage:</span
                    >
                  </v-col>
                  <v-col class="text-right py-0">
                    <span
                      ><nobr
                        >{{
                          selectedTarifAndOptions.information.photovoltaic
                        }}
                        kWp</nobr
                      ></span
                    >
                  </v-col>
                </v-row>
                <!-- Price row -->
                <v-row class="mt-10">
                  <v-col cols="7" class="py-0">
                    <span class="font-weight-bold"
                      >Kalkulierter Abschlag pro Monat</span
                    >
                  </v-col>
                  <v-col class="text-h4 text-right py-0">
                    <span
                      ><nobr
                        >{{
                          selectedTarifAndOptions.tarif.price
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")
                        }}
                        {{ selectedTarifAndOptions.tarif.price_unit }}</nobr
                      ></span
                    >
                  </v-col>
                </v-row>
                <!-- List row -->
                <v-row class="mt-10">
                  <v-col class="py-0 pl-2">
                    <ul>
                      <li>
                        Freie Rückliefermenge:
                        {{ selectedTarifAndOptions.tarif.amount_free }}
                        {{ selectedTarifAndOptions.tarif.amount_free_unit }} /
                        Jahr
                      </li>
                      <li>
                        {{ mehrPrices.mehr_1.name_short }}
                        <nobr
                          >{{
                            mehrPrices.mehr_1.price
                              .toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}
                          {{ mehrPrices.mehr_1.price_unit }}
                          <a href="faq?target=mehr1" target="_blank">
                            <v-icon
                              style="vertical-align: baseline; cursor: pointer"
                              small
                              >mdi-information-outline
                            </v-icon>
                          </a>
                        </nobr>
                      </li>
                      <li>
                        {{ mehrPrices.mehr_2.name_short }}
                        <nobr
                          >{{
                            mehrPrices.mehr_2.price
                              .toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}
                          {{ mehrPrices.mehr_2.price_unit }}
                          <a href="faq?target=mehr2" target="_blank">
                            <v-icon
                              style="vertical-align: baseline; cursor: pointer"
                              small
                              >mdi-information-outline
                            </v-icon>
                          </a></nobr
                        >
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-col>
              <!-- Cancelation data -->
              <v-col offset-sm="1" cols="12" sm="5">
                <!-- Cancelation row -->
                <v-row :class="`${$vuetify.breakpoint.smAndUp ? '' : 'mt-3'}`">
                  <v-col cols="9" class="py-0">
                    <span class="font-weight-bold">Frühstens kündbar zum</span>
                  </v-col>
                  <v-col cols="3" class="text-right py-0">
                    <span>30.10.2023 </span>
                  </v-col>
                </v-row>
                <!-- Cancelation time row -->
                <v-row>
                  <v-col cols="9" class="py-0">
                    <span class="font-weight-bold">Kündigungsfrist</span>
                  </v-col>
                  <v-col cols="3" class="text-right py-0">
                    <span>4 Wochen </span>
                  </v-col>
                </v-row>
                <!-- Priceguarantee row -->
                <v-row>
                  <v-col cols="9" class="py-0">
                    <span class="font-weight-bold">Preisgarantie</span>
                  </v-col>
                  <v-col cols="3" class="text-right py-0">
                    <span>30.10.2023 </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-sheet>
          <!-- Zusatzoptionen -->
          <v-sheet
            :class="`rounded-b-lg ${
              $vuetify.breakpoint.smAndUp ? 'pa-10' : 'pa-4'
            } pb-16`">
            <v-row
              :class="`text-caption font-weight-bold ${
                $vuetify.breakpoint.smAndUp ? 'px-10' : 'px-0'
              }`">
              <v-col
                cols="3"
                sm="3"
                :class="`${$vuetify.breakpoint.smAndUp ? '' : 'pr-0'}`">
                <span>+ ZUSATZ<wbr />OPTIONEN</span>
              </v-col>
              <v-col
                cols="7"
                sm="6"
                :class="`${$vuetify.breakpoint.smAndUp ? '' : 'px-0'}`"
                ><span>BESCHREIBUNG</span>
              </v-col>
              <v-col
                cols="2"
                sm="3"
                :class="`text-right ${
                  $vuetify.breakpoint.smAndUp ? '' : 'pl-0'
                }`"
                ><span>PREIS PRO JAHR</span></v-col
              >
            </v-row>
            <!-- Big divider -->
            <v-row :class="`${$vuetify.breakpoint.smAndUp ? 'px-10' : 'px-0'}`">
              <v-col class="pt-2">
                <v-divider></v-divider>
                <v-divider></v-divider>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <!-- SolHeat -->
            <v-row
              v-if="selectedTarifAndOptions.options.solHeat"
              :class="`text-body-2 ${
                $vuetify.breakpoint.smAndUp ? 'px-10' : 'px-0'
              }`">
              <v-col
                cols="3"
                sm="3"
                :class="`${$vuetify.breakpoint.smAndUp ? '' : 'pr-0'}`">
                <p class="font-weight-bold mb-1">
                  {{ selectedTarifAndOptions.options.sol_heat.name }}
                </p>
                <span>(für Wärme<wbr />pumpe)</span>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                :class="`${$vuetify.breakpoint.smAndUp ? '' : 'px-0'}`">
                <ul>
                  <li>
                    Preis pro Monat:
                    <nobr>
                      {{
                        selectedTarifAndOptions.options.sol_heat.price
                          .toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}
                      {{
                        selectedTarifAndOptions.options.sol_heat.price_unit
                      }}</nobr
                    >
                  </li>
                  <li>
                    {{ mehrPrices.mehr_3.name_short }}
                    <nobr
                      >{{
                        mehrPrices.mehr_3.price
                          .toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}
                      {{ mehrPrices.mehr_3.price_unit }}
                      <a href="faq?target=mehr3" target="_blank">
                        <v-icon
                          style="vertical-align: baseline; cursor: pointer"
                          small
                          >mdi-information-outline
                        </v-icon>
                      </a></nobr
                    >
                  </li>
                  <li>
                    {{ mehrPrices.mehr_4.name_short }}
                    <nobr
                      >{{
                        mehrPrices.mehr_4.price
                          .toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}
                      {{ mehrPrices.mehr_4.price_unit }}
                      <a href="faq?target=mehr4" target="_blank">
                        <v-icon
                          style="vertical-align: baseline; cursor: pointer"
                          small
                          >mdi-information-outline
                        </v-icon>
                      </a></nobr
                    >
                  </li>
                </ul>
              </v-col>
              <v-col
                cols="3"
                sm="3"
                :class="`text-right ${
                  $vuetify.breakpoint.smAndUp ? '' : 'pl-0'
                }`"
                ><span
                  ><nobr>
                    {{
                      (selectedTarifAndOptions.options.sol_heat.price * 12)
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")
                    }}
                    {{
                      selectedTarifAndOptions.options.sol_heat.price_unit
                    }}</nobr
                  ></span
                ></v-col
              >
            </v-row>
            <!-- Cloud to car -->
            <v-row
              v-if="selectedTarifAndOptions.options.cloudToCar"
              :class="`text-body-2 ${
                $vuetify.breakpoint.smAndUp ? 'px-10' : 'px-0'
              }`">
              <v-col
                cols="3"
                sm="3"
                :class="`${$vuetify.breakpoint.smAndUp ? '' : 'pr-0'}`">
                <p class="font-weight-bold mb-1">
                  {{ selectedTarifAndOptions.options.cloud_to_car.name }}
                </p>
                <span>(Strom für Wallbox)</span>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                :class="`${$vuetify.breakpoint.smAndUp ? '' : 'px-0'}`">
                <ul>
                  <li>
                    Preis pro Monat:
                    <nobr
                      >{{
                        selectedTarifAndOptions.options.cloud_to_car.price
                          .toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}
                      {{
                        selectedTarifAndOptions.options.cloud_to_car.price_unit
                      }}</nobr
                    >
                  </li>
                  <li>
                    {{ mehrPrices.mehr_3.name_short }}
                    <nobr
                      >{{
                        mehrPrices.mehr_3.price
                          .toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}
                      {{ mehrPrices.mehr_3.price_unit }}
                      <a href="faq?target=mehr3" target="_blank">
                        <v-icon
                          style="vertical-align: baseline; cursor: pointer"
                          small
                          >mdi-information-outline
                        </v-icon>
                      </a></nobr
                    >
                  </li>
                  <li>
                    {{ mehrPrices.mehr_4.name_short }}
                    <nobr
                      >{{
                        mehrPrices.mehr_4.price
                          .toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}
                      {{ mehrPrices.mehr_4.price_unit }}
                      <a href="faq?target=mehr4" target="_blank">
                        <v-icon
                          style="vertical-align: baseline; cursor: pointer"
                          small
                          >mdi-information-outline
                        </v-icon>
                      </a></nobr
                    >
                  </li>
                </ul>
              </v-col>
              <v-col
                cols="3"
                sm="3"
                :class="`text-right ${
                  $vuetify.breakpoint.smAndUp ? '' : 'pl-0'
                }`"
                ><span
                  ><nobr
                    >{{
                      (selectedTarifAndOptions.options.cloud_to_car.price * 12)
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")
                    }}
                    {{
                      selectedTarifAndOptions.options.cloud_to_car.price_unit
                    }}</nobr
                  ></span
                ></v-col
              >
            </v-row>
            <!-- Cloud to share -->
            <v-row
              v-if="selectedTarifAndOptions.options.cloudToShare"
              :class="`text-body-2 ${
                $vuetify.breakpoint.smAndUp ? 'px-10' : 'px-0'
              }`">
              <v-col
                cols="3"
                sm="3"
                :class="`${$vuetify.breakpoint.smAndUp ? '' : 'pr-0'}`">
                <p class="font-weight-bold mb-1">
                  {{ selectedTarifAndOptions.options.cloud_to_share.name }}
                </p>
                <span>(Strom für eine zusätzliche Abnahmestelle)</span>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                :class="`${$vuetify.breakpoint.smAndUp ? '' : 'px-0'}`">
                <ul>
                  <li>
                    Preis pro Monat:
                    <nobr
                      >{{
                        selectedTarifAndOptions.options.cloud_to_share.price
                          .toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}
                      {{
                        selectedTarifAndOptions.options.cloud_to_share
                          .price_unit
                      }}</nobr
                    >
                  </li>
                  <li>Zusätzliche Abnahmestelle</li>
                  <li>
                    Verbrauch wird auf Verbrauch der 1. Abnahmestelle addiert
                  </li>
                </ul>
              </v-col>
              <v-col
                cols="3"
                sm="3"
                :class="`text-right ${
                  $vuetify.breakpoint.smAndUp ? '' : 'pl-0'
                }`"
                ><span
                  ><nobr
                    >{{
                      (
                        selectedTarifAndOptions.options.cloud_to_share.price *
                        12
                      )
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")
                    }}
                    {{
                      selectedTarifAndOptions.options.cloud_to_share.price_unit
                    }}</nobr
                  ></span
                ></v-col
              >
            </v-row>
            <!-- Best price guarantee -->
            <v-row
              v-if="selectedTarifAndOptions.options.bestPriceGuarantee"
              :class="`text-body-2 ${
                $vuetify.breakpoint.smAndUp ? 'px-10' : 'px-0'
              }`">
              <v-col
                cols="3"
                :class="`${$vuetify.breakpoint.smAndUp ? '' : 'pr-0'}`">
                <p class="font-weight-bold mb-1">
                  {{
                    selectedTarifAndOptions.options.best_price_guarantee.name
                  }}
                </p>
                <span></span>
              </v-col>
              <v-col
                cols="6"
                :class="`${$vuetify.breakpoint.smAndUp ? '' : 'px-0'}`">
                <span>Automatische Einstufung in den günstigsten Tarif</span>
              </v-col>
              <v-col
                cols="3"
                :class="`text-right ${
                  $vuetify.breakpoint.smAndUp ? '' : 'pl-0'
                }`"
                ><span
                  ><nobr
                    >{{
                      selectedTarifAndOptions.options.best_price_guarantee.price
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")
                    }}
                    {{
                      selectedTarifAndOptions.options.best_price_guarantee
                        .price_unit
                    }}</nobr
                  ></span
                ></v-col
              >
            </v-row>
            <v-row
              v-if="selectedTarifAndOptions.options.bestPriceGuarantee"
              :class="`${$vuetify.breakpoint.smAndUp ? 'px-10' : 'px-0'}`">
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <!-- Zero cost cloud -->
            <v-row
              v-if="selectedTarifAndOptions.options.zeroCostCloud"
              :class="`text-body-2 ${
                $vuetify.breakpoint.smAndUp ? 'px-10' : 'px-0'
              }`">
              <v-col
                cols="3"
                :class="`${$vuetify.breakpoint.smAndUp ? '' : 'pr-0'}`">
                <p class="font-weight-bold mb-1">
                  {{ selectedTarifAndOptions.options.zero_cost_cloud.name }}
                </p>
                <span>(Individuelle Prüfung nach Abschluss)</span>
              </v-col>
              <v-col
                cols="6"
                :class="`${$vuetify.breakpoint.smAndUp ? '' : 'px-0'}`">
                <span
                  >Verrechnung nach 12 Monaten mit der Jahresendabrechnung
                  (Verzicht auf Erhebung von Abschlägen)
                </span>
              </v-col>
              <v-col
                cols="3"
                :class="`text-right ${
                  $vuetify.breakpoint.smAndUp ? '' : 'pl-0'
                }`"
                ><span
                  ><nobr
                    >{{
                      selectedTarifAndOptions.options.zero_cost_cloud.price
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")
                    }}
                    {{
                      selectedTarifAndOptions.options.zero_cost_cloud.price_unit
                    }}</nobr
                  ></span
                ></v-col
              >
            </v-row>
            <v-row
              v-if="selectedTarifAndOptions.options.zeroCostCloud"
              :class="`${$vuetify.breakpoint.smAndUp ? 'px-10' : 'px-0'}`">
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <!-- Sum -->
            <v-row
              :class="`text-body-2 mt-5 ${
                $vuetify.breakpoint.smAndUp ? 'px-10' : 'px-0'
              }`">
              <v-col
                cols="9"
                :class="`${$vuetify.breakpoint.smAndUp ? '' : 'pr-0'}`">
                <h3 class="text-h4">Summe für 12 Monate</h3>
              </v-col>
              <v-col
                cols="3"
                :class="`text-right ${
                  $vuetify.breakpoint.smAndUp ? '' : 'pl-0'
                }`">
                <span class="text-h6"
                  ><nobr>{{ price }} €</nobr></span
                >
              </v-col>
            </v-row>
          </v-sheet>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { getMehrPrices } from "../../api/api";

export default {
  props: ["props", "edit", "index"],
  data() {
    return {
      mehrPrices: null,
    };
  },
  computed: {
    ...mapState({
      selectedTarifAndOptions: state => {
        return state.selectedTarifAndOptions;
      },
      language: state => {
        return state.language ?? "de";
      },
    }),
    price: function () {
      let price = this.selectedTarifAndOptions.tarif.price * 12;
      if (this.selectedTarifAndOptions.options.solHeat) {
        price += this.selectedTarifAndOptions.options.sol_heat.price * 12;
      }
      if (this.selectedTarifAndOptions.options.cloudToCar) {
        price += this.selectedTarifAndOptions.options.cloud_to_car.price * 12;
      }
      if (this.selectedTarifAndOptions.options.cloudToShare) {
        price += this.selectedTarifAndOptions.options.cloud_to_share.price * 12;
      }
      if (this.selectedTarifAndOptions.options.bestPriceGuarantee) {
        price +=
          this.selectedTarifAndOptions.options.best_price_guarantee.price;
      }
      if (this.selectedTarifAndOptions.options.zeroCostCloud) {
        price += this.selectedTarifAndOptions.options.zero_cost_cloud.price;
      }
      return price.toFixed(2).toString().replace(".", ",");
    },
  },
  mounted() {
    getMehrPrices(
      this.selectedTarifAndOptions.information.bdew_code,
      this.selectedTarifAndOptions.information.zip_code
    )
      .then(res => {
        this.mehrPrices = res.data;
      })
      .catch(() => {});
  },
};
</script>
<style>
#TarifHeader .v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>
