<template>
  <v-navigation-drawer
    v-if="props"
    v-model="showNavbar"
    left
    app
    temporary
    style="z-index: 1001">
    <v-row justify="center">
      <v-col cols="10" class="pt-5">
        <img :src="props.logo_href" alt="Zurück zur Startseite" height="50px" />
      </v-col>
    </v-row>
    <v-row class="ma-0" justify="center">
      <v-col cols="12"><v-divider></v-divider></v-col>
    </v-row>

    <v-list nav dense>
      <div
        v-for="menu_item in props.menu_items"
        :key="menu_item.text[language]">
        <!-- no sub group -->
        <v-list-item
          v-if="menu_item.sub_menu_items.length == 0"
          @click="goTo(menu_item.url, menu_item.is_different_page)"
          link>
          <v-list-item-title
            class="secondary--text text-body-1 font-weight-bold"
            v-html="menu_item.text[language]"></v-list-item-title>
        </v-list-item>

        <!-- sub group -->
        <v-list-group v-else no-action :sub-group="false">
          <template v-slot:activator>
            <v-list-item-content
              @click="goTo(menu_item.url, menu_item.is_different_page)"
              link>
              <v-list-item-title
                v-html="menu_item.text[language]"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(item, index) in menu_item.sub_menu_items"
            :key="index"
            link
            @click="goTo(item.url, item.is_different_page)">
            <v-list-item-title v-html="item.text[language]"></v-list-item-title>
          </v-list-item>
        </v-list-group>
      </div>
    </v-list>
    <div
      class="pl-4 py-1 d-inline-block"
      v-for="(item, index) in languages"
      :key="index">
      <v-img
        style="cursor: pointer"
        width="40px"
        :aspect-ratio="16 / 9"
        :src="getImgUrl(item.key)"
        @click="$store.commit('setLanguage', item.key)"></v-img>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      editProps: {
        logo: "",
        calculator: true,
        magazine: true,
        helpAndContact: true,
        scrollPosition: null,
      },
      drawer: true,
    };
  },
  watch: {
    edit: function () {
      Object.assign(this.editProps, this.props);
    },
  },
  computed: {
    ...mapState({
      props: state => {
        return state.config
          ? state.config.common_components.custom_header
          : null;
      },
      language: state => {
        return state.language ?? "de";
      },
      languages: state => {
        return state.config.languages;
      },
    }),
    showNavbar: {
      // getter
      get: function () {
        return this.$store.getters.getShowNavbar;
      },
      // setter
      set: function (newValue) {
        this.$store.commit("setShowNavbar", newValue);
      },
    },
  },
  methods: {
    save() {
      this.$emit("save", this.editProps, this.index);
    },
    cancel() {
      this.$emit("cancel");
    },
    goTo(url, is_different_page) {
      if (
        !url ||
        url.replace("/", "") ==
          this.$router.history.current.path.replace("/", "")
      )
        return;
      is_different_page
        ? this.$router.push({ path: url })
        : this.$vuetify.goTo(url, { offset: 70 });
    },
    getImgUrl(language) {
      return require(`../../../src/assets/${language}.png`);
    },
  },
};
</script>

<style>
.customHeader {
  position: fixed;
  height: 100px;
  width: 100%;
  background-color: white;
  z-index: 1000;
}
.CustomHeader .v-toolbar__content {
  padding: 0 !important;
}
</style>
