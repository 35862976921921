<template>
  <v-sheet color="background_secondary" class="mt-3">
    <v-container fluid class="container-padding" id="MeterUpload">
      <v-row justify="center">
        <v-col>
          <v-card class="py-2 py-md-4 px-4 px-md-6 mt-4 mb-6">
            <v-form ref="formMeterUpload" class="black--text" lazy-validation>
              <div v-if="dataLoaded">
                <h4 class="text-h4 my-4 mb-10">
                  Formular ausfüllen und Zählerstände hochladen
                </h4>
                <p class="mb-8">
                  {{ getSalutation() }},
                  <br />
                  <br />
                  bitte laden Sie hier ganz einfach Ihre Zählerstände hoch. Sie
                  erhalten eine Bestätigung per E-Mail, sobald wir Ihre Daten
                  erhalten haben.
                  <br />
                  Sollten Sie mehrere Zähler haben, Ihnen jedoch aktuell noch
                  nicht alle Daten für jeden einzelnen Zähler vorliegen, so ist
                  das kein Problem. Geben Sie einfach die Daten ein, die Sie im
                  Moment haben, Sie können die fehlenden Daten dann zu einem
                  späteren Zeitpunkt eingeben.
                  <br />Der Link ist bis zum
                  <span class="info--text">{{
                    new Date(
                      customer_data.validity_end_date
                    ).toLocaleDateString("de-DE", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })
                  }}</span>
                  aktiv.
                </p>
                <p
                  style="font-family: Manrope, sans-serif; font-weight: 600"
                  v-if="special_text">
                  Bitte beachten Sie, dass die durch Ihre Erzeugungsanlage
                  eingespeiste Menge über die an uns abgetretene
                  Einspeisevergütung ermittelt wird. Die Ablesung des Wertes zum
                  OBIS-Code 2.8.0 wird daher durch uns nicht abgefragt und ist
                  auch nicht notwendig. Wir bitten darum, auf die Übermittlung
                  dieses Wertes per E-Mail oder Telefon zu verzichten. Vielen
                  Dank!
                </p>
                <b class="mr-2">Legende:</b>
                <v-chip small class="mb-1">Noch nicht hochgeladen</v-chip>
                <v-chip small class="mx-1 mb-1" color="success"
                  >Hochgeladen</v-chip
                >
                <v-chip small class="mb-1" color="error"
                  >Fehler beim Hochladen</v-chip
                >
                <h3 class="mt-4">Zählernummer auswählen:</h3>
                <v-chip
                  :color="meter_number.state"
                  :style="`cursor: pointer; ${
                    meter_number_selected &&
                    meter_number.meter_number ==
                      meter_number_selected.meter_number
                      ? ' box-shadow: #4b86ed 0px 0px 10px;'
                      : ''
                  }`"
                  :class="`my-2 mr-1`"
                  :disabled="meter_number.state == 'success'"
                  @click="selectMeterReading(meter_number)"
                  v-for="meter_number in customer_data.meter_numbers"
                  :key="meter_number.meter_number">
                  {{ meter_number.meter_number }} (Vertrag
                  {{ meter_number.contract_number }})
                </v-chip>
              </div>
              <div v-if="meter_number_selected" class="mt-6">
                <div
                  v-for="obis_code in meter_number_selected.obis_codes"
                  :key="
                    meter_number_selected.meter_number + obis_code.obis_code
                  ">
                  <span v-if="obis_code.last_meter_reading">
                    Ihr letzter Zählerstand
                    {{
                      meter_number_selected.obis_codes.length > 1
                        ? "für den OBIS-Code " + obis_code.obis_code
                        : ""
                    }}
                    war {{ Number(obis_code.last_meter_reading) }} kWh am
                    {{ obis_code.last_meter_reading_date }}.
                  </span>
                  <v-row v-if="!obis_code.obis_code.includes('2.8.0')">
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="
                          meter_reading_values[
                            meter_number_selected.meter_number
                          ][obis_code.obis_code].value
                        "
                        class="red-hint"
                        :label="` ${
                          meter_number_selected.obis_codes.length > 1
                            ? 'Zählerstand für OBIS-Code ' + obis_code.obis_code
                            : 'Zählerstand'
                        }`"
                        :rules="[
                          v => !!v || 'Bitte ausfüllen!',
                          v =>
                            !/[.,]/.test(v) ||
                            'Bitte ohne Nachkommastellen eingeben',
                          v => /^\d+$/.test(v) || 'Bitte nur Ziffern eingeben',
                          v =>
                            !obis_code.last_meter_reading ||
                            Number(v) >= Number(obis_code.last_meter_reading) ||
                            'Der Zählerstand darf nicht kleiner sein als der letzte Zählerstand',
                        ]"
                        type="number"
                        :hint="
                          meter_reading_values[
                            meter_number_selected.meter_number
                          ][obis_code.obis_code].hint
                        "
                        @input="updateNumberHint(obis_code)"
                        persistent-hint
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="
                          meter_reading_values[
                            meter_number_selected.meter_number
                          ][obis_code.obis_code].date
                        "
                        type="date"
                        label="Ablesedatum*"
                        :rules="[
                          v => !!v || 'Bitte ausfüllen!',
                          v =>
                            new Date(v) <= new Date() ||
                            'Datum darf nicht in der Zukunft liegen',
                        ]"
                        :max="new Date().toISOString().split('T')[0]"
                        required></v-text-field
                    ></v-col>
                  </v-row>
                </div>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-btn
                      @click="upload_meter_reading"
                      :disabled="
                        meter_number_selected.state == 'success' ||
                        showInfoAlertUpload
                      "
                      class="py-6 mt-2 mb-2"
                      color="secondary"
                      width="100%">
                      {{
                        meter_number_selected.obis_codes.length > 1
                          ? "Zählerstände"
                          : "Zählerstand"
                      }}
                      für Zähler abschicken
                    </v-btn>
                    <v-row class="mt-4">
                      <v-col class="py-0">
                        <v-alert
                          v-if="showSuccessAlertUpload"
                          dense
                          text
                          type="success">
                          Ihre Zählerstände wurden erfolgreich verschickt!
                        </v-alert>
                        <v-alert
                          v-if="showInfoAlertUpload"
                          dense
                          text
                          type="info">
                          Anfrage wird bearbeitet
                          <v-progress-circular
                            class="ml-2"
                            indeterminate
                            :size="20"
                            color="info"></v-progress-circular>
                        </v-alert>
                        <v-alert
                          v-if="showErrorAlertUpload"
                          dense
                          text
                          type="error">
                          Leider ist ein Fehler beim Hochladen der Zählerstände
                          aufgetreten. Versuchen Sie es bitte erneut oder
                          kontaktieren Sie unseren Kundenservice.
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <v-row :class="`${dataLoaded ? 'mt-10' : 'mt-2'}`">
                <v-col class="py-0">
                  <v-alert v-if="showInfoAlert" dense text type="info">
                    Anfrage wird bearbeitet
                    <v-progress-circular
                      class="ml-2"
                      indeterminate
                      :size="20"
                      color="info"></v-progress-circular>
                  </v-alert>
                  <v-alert v-if="showErrorAlert" dense text type="error">
                    {{
                      error.response.status == 410
                        ? "Leider ist dieser Link nicht mehr gültig."
                        : "Leider ist ein Fehler beim Laden der Zählerdaten aufgetreten. Versuchen Sie es bitte erneut oder kontaktieren Sie uns per E-Mail unter info@new-energycloud.com."
                    }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import { getHashedValues, postMeterUploadRequest } from "../api/api";
import { computeHighNumberHint } from "../api/util";
export default {
  data() {
    return {
      dataLoaded: false,
      error: null,
      showErrorAlert: false,
      showInfoAlert: false,
      showErrorAlertUpload: false,
      showSuccessAlertUpload: false,
      showInfoAlertUpload: false,
      customer_data: {},
      meter_reading_values: {},
      meter_number_selected: null,
      special_text: false,
    };
  },
  mounted() {
    this.showInfoAlert = true;
    getHashedValues(this.$route.query.hash)
      .then(customer_data => {
        this.customer_data = customer_data.data;
        for (let meter_number of this.customer_data.meter_numbers) {
          this.meter_reading_values[meter_number.meter_number] = {};
          for (let obis_code of meter_number.obis_codes) {
            if (obis_code.obis_code.includes("2.8.0")) this.special_text = true;
            this.meter_reading_values[meter_number.meter_number][
              obis_code.obis_code
            ] = {
              value: "",
              date: this.customer_data.expected_meter_reading_date,
              hint: "",
            };
          }
        }
        this.selectMeterReading(this.customer_data.meter_numbers[0]);
        this.dataLoaded = true;
        this.showInfoAlert = false;
      })
      .catch(error => {
        console.log(error);
        this.error = error;
        this.showInfoAlert = false;
        this.showErrorAlert = true;
      });
  },
  methods: {
    updateNumberHint(obis_code) {
      this.meter_reading_values[this.meter_number_selected.meter_number][
        obis_code.obis_code
      ].hint = computeHighNumberHint(
        this.meter_reading_values[this.meter_number_selected.meter_number][
          obis_code.obis_code
        ].value,
        obis_code.last_meter_reading,
        obis_code.last_meter_reading_date,
        obis_code.high_consumption_factor,
        this.meter_number_selected.consumption,
        this.meter_reading_values[this.meter_number_selected.meter_number][
          obis_code.obis_code
        ].date
      );
      this.meter_reading_values = { ...this.meter_reading_values };
    },
    selectMeterReading(meter_reading) {
      this.meter_number_selected = meter_reading;
    },
    upload_meter_reading() {
      if (this.$refs.formMeterUpload.validate()) {
        let payload = { body: [] };
        for (
          let oIndex = 0;
          oIndex < this.meter_number_selected.obis_codes.length;
          oIndex++
        ) {
          let obis_code =
            this.meter_number_selected.obis_codes[oIndex].obis_code;
          if (obis_code.includes("2.8.0")) continue;
          let payload_for_obis_code = {
            contract_id: this.meter_number_selected.contract_id,
            meter_number: this.meter_number_selected.meter_number,
            obis_code: obis_code,
            meter_reading:
              this.meter_reading_values[
                this.meter_number_selected.meter_number
              ][obis_code].value,
            meter_reading_date:
              this.meter_reading_values[
                this.meter_number_selected.meter_number
              ][obis_code].date,
          };
          payload.body.push(payload_for_obis_code);
        }
        this.showErrorAlertUpload = false;
        this.showInfoAlertUpload = true;
        payload.email = this.customer_data.customer.email;
        payload.payload = this.$route.query.hash;
        postMeterUploadRequest(payload)
          .then(() => {
            this.setMeterNumberState(
              this.meter_number_selected.meter_number,
              "success"
            );
            this.showInfoAlertUpload = false;
            this.showSuccessAlertUpload = true;
          })
          .catch(error => {
            console.log(error);
            this.setMeterNumberState(
              this.meter_number_selected.meter_number,
              "error"
            );
            this.showInfoAlertUpload = false;
            this.showErrorAlertUpload = true;
          });
      }
    },
    setMeterNumberState(meter_number, state) {
      this.customer_data.meter_numbers.find(
        item => item.meter_number === meter_number
      ).state = state;
    },
    getSalutation() {
      let greeting = "";
      if (
        this.customer_data.customer.salutation &&
        this.customer_data.customer.last_name
      ) {
        if (this.customer_data.customer.salutation == "Herr") {
          greeting =
            "Sehr geehrter Herr " +
            this.customer_data.customer.title +
            " " +
            this.customer_data.customer.last_name;
        } else if (this.customer_data.customer.salutation == "Frau") {
          greeting =
            "Sehr geehrte Frau " +
            this.customer_data.customer.title +
            " " +
            this.customer_data.customer.last_name;
        } else if (
          this.customer_data.customer.salutation == "Divers" &&
          this.customer_data.customer.first_name
        ) {
          greeting =
            "Guten Tag " +
            this.customer_data.customer.title +
            " " +
            this.customer_data.customer.first_name +
            " " +
            this.customer_data.customer.last_name;
        }
      } else {
        greeting = "Sehr geehrte Damen und Herren";
      }
      if (this.customer_data.customer_type == "company") {
        greeting = greeting + " von " + this.customer_data.organization_name;
      }
      return greeting.replace(" false ", " ");
    },
  },
};
</script>

<style>
.red-hint .v-messages:not(.error--text) .v-messages__message {
  color: red !important;
}
</style>
