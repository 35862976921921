<template>
  <v-container fluid v-if="props">
    <v-row justify="center">
      <v-col cols="4" sm="3">
        <!-- Brand logos -->
        <router-link to="/">
          <v-img
            max-width="175"
            :src="props.logo_href"
            alt="Zurück zur Startseite"
            contain></v-img>
        </router-link>
      </v-col>
      <!-- Sub headline -->
      <v-col cols="1" class="align-self-center">
        <span v-html="props.sub_headline"></span>
      </v-col>
      <!-- Links -->
      <v-col cols="5" sm="7" md="5" class="text-right align-self-center">
        <v-icon
          v-if="$vuetify.breakpoint.xs && props.menu"
          @click="drawer = true"
          >mdi-menu</v-icon
        >
        <div v-if="!$vuetify.breakpoint.xs">
          <span v-if="props.calculator" class="mr-6" style="cursor: pointer">
            <router-link to="/tarifrechner" style="text-decoration: none">
              <span class="secondary--text text-body-2 ml-1 font-weight-bold"
                >Tarifrechner</span
              >
            </router-link>
          </span>
          <span v-if="props.magazine" class="mr-6" style="cursor: pointer">
            <router-link to="/magazin" style="text-decoration: none">
              <span class="secondary--text text-body-2 ml-1 font-weight-bold"
                >Magazin</span
              >
            </router-link>
          </span>
          <span
            v-if="props.helpAndContact"
            class="mr-6"
            style="cursor: pointer">
            <router-link to="/hilfe-und-kontakt" style="text-decoration: none">
              <span class="secondary--text text-body-2 ml-1 font-weight-bold"
                >Hilfe & Kontakt</span
              >
            </router-link>
          </span>
        </div>
      </v-col>
    </v-row>
    <!-- Mobile header -->
    <v-navigation-drawer v-model="drawer" absolute right>
      <v-list nav dense>
        <v-list-item-group class="mt-6">
          <v-list-item v-if="props.calculator">
            <v-list-item-title>
              <span class="mr-6" style="cursor: pointer">
                <router-link to="/tarifrechner" style="text-decoration: none">
                  <span
                    class="secondary--text text-body-2 ml-1 font-weight-bold"
                    >Tarifrechner</span
                  >
                </router-link>
              </span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="props.magazine">
            <v-list-item-title>
              <span class="mr-6" style="cursor: pointer">
                <router-link to="/magazin" style="text-decoration: none">
                  <span
                    class="secondary--text text-body-2 ml-1 font-weight-bold"
                    >Magazin</span
                  >
                </router-link>
              </span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="props.helpAndContact">
            <v-list-item-title
              ><span class="mr-6" style="cursor: pointer">
                <router-link
                  to="/hilfe-und-kontakt"
                  style="text-decoration: none">
                  <span
                    class="secondary--text text-body-2 ml-1 font-weight-bold"
                    >Hilfe & Kontakt</span
                  >
                </router-link>
              </span>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- Edit dialog -->
    <v-dialog persistent v-model="edit" width="50%">
      <v-card>
        <v-card-title class="text-h5 black white--text">
          Edit Header
        </v-card-title>

        <v-card-text class="pt-5">
          <v-switch
            v-model="editProps.calculator"
            label="Tarifrechner"></v-switch>
          <v-switch v-model="editProps.magazine" label="Magazin"></v-switch>
          <v-switch
            v-model="editProps.helpAndContact"
            label="Hilfe & Kontakt"></v-switch>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="save"> Save </v-btn>
          <v-btn color="error" text @click="cancel"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: ["props", "edit", "index"],
  data() {
    return {
      editProps: {
        logo: "",
        calculator: true,
        magazine: true,
        helpAndContact: true,
      },
      drawer: false,
    };
  },
  watch: {
    edit: function () {
      Object.assign(this.editProps, this.props);
    },
  },
  methods: {
    save() {
      this.$emit("save", this.editProps, this.index);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style></style>
