<template>
  <v-sheet
    :color="props.color_background"
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`">
    <v-container fluid class="container-padding" v-if="props" :id="props.id">
      <v-row justify="center">
        <v-col cols="12" class="pa-0">
          <v-card class="pa-6 rounded-lg">
            <v-card-text>
              <v-form
                ref="formContact"
                class="black--text"
                v-model="valid"
                lazy-validation>
                <h1 class="text-h5 pb-10">
                  Persönliche Daten für ihre/n Kündigung/Widerruf
                </h1>
                <!-- Private Customer -->
                <div>
                  <h3 class="pt-5 pb-2">KUNDENDATEN</h3>
                  <v-row>
                    <v-col cols="3">
                      <v-select
                        v-model="form.salutation"
                        :items="[`Herr`, `Frau`, `Divers`]"
                        label="Anrede*"
                        :rules="[v => !!v || 'Bitte ausfüllen!']"
                        required></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="form.firstName"
                        label="Vorname*"
                        :rules="[v => !!v || 'Bitte ausfüllen!']"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="form.lastName"
                        label="Nachname*"
                        :rules="[v => !!v || 'Bitte ausfüllen!']"
                        required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="form.customerNumber"
                        label="Kundennummer*"
                        :rules="[v => !!v || 'Bitte ausfüllen!']"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="form.email"
                        label="E-Mail*"
                        :rules="[v => !!v || 'Bitte ausfüllen!']"
                        required></v-text-field>
                    </v-col>
                  </v-row>
                  <h3 class="pt-5">LIEFERADRESSE</h3>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="form.street"
                        label="Straße*"
                        :rules="[v => !!v || 'Bitte ausfüllen!']"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="form.number"
                        label="Hausnummer*"
                        :rules="[v => !!v || 'Bitte ausfüllen!']"
                        required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="form.postalcode"
                        label="Postleitzahl*"
                        :rules="[v => !!v || 'Bitte ausfüllen!']"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="form.region"
                        label="Ort*"
                        :rules="[v => !!v || 'Bitte ausfüllen!']"
                        required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-radio-group v-model="form.issue_type">
                        <v-radio
                          label="Widerruf innerhalb der gesetzlichen Frist"
                          value="revocation"></v-radio>
                        <v-radio
                          label="Kündigung zum nächstmöglichen Datum"
                          value="termination_next_date"></v-radio>
                        <v-radio
                          label="Kündigung zu einem spezifischen Datum"
                          value="termination_specific_date"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="pt-0">
                      <v-text-field
                        v-if="form.issue_type == 'termination_specific_date'"
                        v-model="form.date"
                        label="Datum*"
                        type="date"
                        :rules="[v => !!v || 'Bitte ausfüllen!']"
                        required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        v-model="form.reason"
                        auto-grow
                        rows="4"
                        label="Kündigungsgrund"></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn color="secondary" @click="submit">Absenden</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-alert
                        v-if="showSuccessAlert"
                        class="mt-7"
                        dense
                        text
                        type="success">
                        Ihre Anfrage ist erfolgreich verschickt!
                      </v-alert>
                      <v-alert
                        v-if="showInfoAlert"
                        class="mt-7"
                        dense
                        text
                        type="info">
                        Anfrage wird bearbeitet...
                      </v-alert>
                      <v-alert
                        v-if="showErrorAlert"
                        class="mt-7"
                        dense
                        text
                        type="error">
                        Leider ist ein Fehler aufgetreten. Versuchen Sie es
                        bitte erneut oder kontaktieren Sie uns per E-Mail.
                      </v-alert>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Edit dialog -->
      <v-dialog persistent v-model="edit" width="50%">
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Text Component
          </v-card-title>

          <v-card-text class="pt-5">
            <quill-editor v-model="editProps.text" />
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="save"> Save </v-btn>
            <v-btn color="error" text @click="cancel"> Cancel </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import { terminationRequest } from "../api/api";

export default {
  props: ["props", "edit", "index"],
  data() {
    return {
      editProps: { text: "" },
      valid: true,
      showInfoAlert: false,
      showSuccessAlert: false,
      showErrorAlert: false,
      form: {
        customerType: 2,
        salutation: "",
        firstName: "",
        lastName: "",
        customerNumber: "",
        email: "",
        street: "",
        number: "",
        postalcode: "",
        region: "",
        reason: "",
        issue_type: "revocation",
        date: "",
      },
    };
  },
  watch: {
    edit: function () {
      Object.assign(this.editProps, this.props);
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save() {
      this.$emit("save", this.editProps, this.index);
    },
    cancel() {
      this.$emit("cancel");
    },
    submit() {
      this.showInfoAlert = false;
      this.showSuccessAlert = false;
      this.showErrorAlert = false;
      let dateFormated = this.form.date
        ? new Date(this.form.date).toLocaleDateString("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
        : null;
      if (this.$refs.formContact.validate()) {
        let data = {
          salutation: this.form.salutation,
          first_name: this.form.firstName,
          last_name: this.form.lastName,
          email: this.form.email,
          customer_number: this.form.customerNumber,
          street_name: this.form.street,
          street_number: this.form.number,
          postalcode: this.form.postalcode,
          city: this.form.region,
          reason: this.form.reason,
          date: dateFormated,
          issue_type: this.form.issue_type,
        };
        this.showInfoAlert = true;
        terminationRequest(data)
          .then(() => {
            this.showInfoAlert = false;
            this.showSuccessAlert = true;
          })
          .catch(() => {
            this.showInfoAlert = false;
            this.showErrorAlert = true;
          });
      }
    },
  },
};
</script>

<style></style>
