<template>
  <v-container fluid v-if="props" class="container-padding">
    <!-- Spacer for fixed header -->
    <div style="height: 100px"></div>
    <!-- end -->
    <v-app-bar
      height="100"
      class="container-padding CustomHeader"
      color="white"
      elevation-1
      fixed
      id="header">
      <v-row justify="center" class="pr-2">
        <v-col cols="2" v-if="!$vuetify.breakpoint.mdAndUp">
          <v-app-bar-nav-icon
            @click.stop="
              $store.commit(`setShowNavbar`, !$store.getters.getShowNavbar)
            "></v-app-bar-nav-icon>
        </v-col>
        <v-col cols="10" sm="10" md="2" lg="2" xl="2" class="pa-0">
          <router-link to="/" style="cursor: pointer">
            <img
              :src="props.logo_href"
              alt="Zurück zur Startseite"
              :width="`${props.logo_width}px`" />
          </router-link>
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.mdAndUp"
          :cols="languages ? 9 : 10"
          :sm="languages ? 9 : 10"
          :md="languages ? 9 : 10"
          :lg="languages ? 9 : 10"
          :xl="languages ? 9 : 10"
          align="right"
          align-self="center">
          <v-menu
            v-for="(menu_item, index) in props.menu_items"
            :key="index"
            transition="slide-y-transition"
            open-on-hover
            offset-y>
            <template v-slot:activator="{ on, attrs }">
              <a
                style="cursor: pointer; text-decoration: none"
                :href="menu_item.url"
                v-bind="attrs"
                v-on="on"
                v-html="menu_item.text[language]" />
            </template>
            <v-list v-if="menu_item.sub_menu_items.length != 0">
              <v-list-item
                v-for="(item, index) in menu_item.sub_menu_items"
                :key="index">
                <v-list-item-title
                  ><a
                    style="cursor: pointer"
                    :href="item.url"
                    v-html="item.text[language]"></a
                ></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <!-- Language see https://www.countryflags.com/ for flags -->
        <v-col
          cols="1"
          align-self="center"
          align="right"
          v-if="languages && $vuetify.breakpoint.mdAndUp">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                style="cursor: pointer"
                width="35px"
                :aspect-ratio="16 / 9"
                :src="getImgUrl(language)"
                v-bind="attrs"
                v-on="on"></v-img>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in languages" :key="index">
                <v-list-item-title
                  @click="$store.commit('setLanguage', item.key)"
                  style="cursor: pointer"
                  :class="props.class_languages">
                  {{ item.value }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>

    <!-- Edit dialog -->
    <v-dialog persistent v-model="edit" width="50%">
      <v-card>
        <v-card-title class="text-h5 black white--text">
          Edit Header
        </v-card-title>

        <v-card-text class="pt-5">
          <v-switch
            v-model="editProps.calculator"
            label="Tarifrechner"></v-switch>
          <v-switch v-model="editProps.magazine" label="Magazin"></v-switch>
          <v-switch
            v-model="editProps.helpAndContact"
            label="Hilfe & Kontakt"></v-switch>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="save"> Save </v-btn>
          <v-btn color="error" text @click="cancel"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["edit", "index"],
  data() {
    return {
      editProps: {
        logo: "",
        calculator: true,
        magazine: true,
        helpAndContact: true,
        scrollPosition: null,
      },
      drawer: false,
    };
  },
  computed: {
    ...mapState({
      props: state => {
        if (!state || !state.config || !state.config.common_components)
          return null;
        return state.config.common_components["custom_header"];
      },
      language: state => {
        return state.language ?? "de";
      },
      languages: state => {
        return state.config.languages;
      },
    }),
  },
  watch: {
    edit: function () {
      Object.assign(this.editProps, this.props);
    },
  },

  methods: {
    save() {
      this.$emit("save", this.editProps, this.index);
    },
    cancel() {
      this.$emit("cancel");
    },
    goTo(url, is_different_page) {
      this.$emit("onLinkClick");
      if (
        !url ||
        url.replace("/", "") ==
          this.$router.history.current.path.replace("/", "")
      )
        return;
      is_different_page
        ? this.$router.push({ path: url })
        : this.$vuetify.goTo(url, { offset: 70 });
    },
    getImgUrl(language) {
      return require(`../../src/assets/${language}.png`);
    },
  },
};
</script>

<style>
.customHeader {
  position: fixed;
  height: 100px;
  width: 100%;
  background-color: white;
  z-index: 1000;
}
.CustomHeader .v-toolbar__content {
  padding: 0 !important;
}
</style>
