<template>
  <v-sheet
    :id="`${props.target}`"
    :color="props.color_background"
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`">
    <v-container fluid v-if="props" class="container-padding">
      <v-row v-if="props.headline && props.headline[language]">
        <v-col>
          <h2 v-html="props.headline[language]"></h2>
        </v-col>
      </v-row>
      <v-row class="pb-15" justify="center" align="center">
        <v-col cols="12" sm="7" md="7" lg="7" xl="7" class="px-0">
          <v-sheet
            class="pa-10 rounded-xl"
            :color="props.color ? props.color : 'secondary'"
            align="center"
            elevation="4">
            <div v-if="$vuetify.breakpoint.xs">
              <v-avatar :size="180">
                <img v-if="props.img_href" :src="props.img_href" alt="John"
              /></v-avatar>
              <v-sheet
                style="position: relative; margin-top: -30px; z-index: 1"
                width="200">
                <p
                  v-if="props.caption && props.caption[language]"
                  v-html="props.caption[language]"></p>
              </v-sheet>
            </div>
            <p
              v-if="props.text && props.text[language]"
              v-html="props.text[language]"></p>
          </v-sheet>
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.smAndUp"
          cols="1"
          sm="1"
          md="1"
          lg="1"
          xl="1"
          class="pa-0">
          <v-icon
            style="margin-left: -70px"
            :color="props.color ? props.color : 'secondary'"
            size="150"
            >mdi-play</v-icon
          >
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.smAndUp"
          cols="4"
          sm="3"
          md="2"
          lg="2"
          xl="2"
          align="right"
          class>
          <v-avatar :size="$vuetify.breakpoint.mdAndUp ? 200 : 150">
            <img :src="props.img_href" alt="John"
          /></v-avatar>
          <v-sheet
            style="position: relative; margin-top: -30px; z-index: 1"
            :width="$vuetify.breakpoint.mdAndUp ? 200 : 150"
            class="px-8 py-2 rounded text-center"
            ><span v-html="props.caption[language]"> </span>
          </v-sheet>
        </v-col>
      </v-row>

      <!-- Edit dialog -->
      <v-dialog v-if="edit" persistent v-model="edit" fullscreen>
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Component
          </v-card-title>
          <v-card-text class="pt-5">
            <Core
              :editProps="editProps"
              @emitProps="setPreviewProps"
              @save="save"
              @cancel="cancel"
              name="Core" />
            <!-- Preview -->
            <h2 class="my-2 text-h4 black--text">Preview:</h2>
            <CompSpeechBubble
              v-if="previewProps"
              name="CompSpeechBubble"
              :props="previewProps" />
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import CompSpeechBubble from "./CompSpeechBubble";
import Core from "../editor/Core.vue";

export default {
  name: "CompSpeechBubble",
  props: ["props", "edit", "index", "componentId"],
  data() {
    return {
      editProps: {},
      previewProps: null,
    };
  },
  watch: {
    edit: function () {
      this.editProps.color_background = {};
      this.editProps.color_background.type = "color";
      this.editProps.color_background.name = "color_background";
      this.editProps.color_background.value =
        this.props.color_background ?? "#FFFFFF";

      this.editProps.target = this.props.target ?? "";
      this.editProps.pt = this.props.pt ?? 0;
      this.editProps.pb = this.props.pb ?? 0;

      this.editProps.text = {};
      this.editProps.text.type = "text";
      this.editProps.text.name = "text";
      this.editProps.text.value = this.props.text ?? {};

      this.editProps.caption = {};
      this.editProps.caption.type = "text";
      this.editProps.caption.name = "caption";
      this.editProps.caption.value = this.props.caption ?? {};

      this.editProps.headline = {};
      this.editProps.headline.type = "text";
      this.editProps.headline.name = "headline";
      this.editProps.headline.value = this.props.headline ?? {};

      this.editProps.img_href = {};
      this.editProps.img_href.type = "image";
      this.editProps.img_href.name = "img_href";
      this.editProps.img_href.value = this.props.img_href ?? "";

      this.editProps.color = {};
      this.editProps.color.type = "color";
      this.editProps.color.name = "color";
      this.editProps.color.value = this.props.color ?? "#FFFFFF";
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
      this.$emit("save", this.previewProps, this.index, this.componentId);
    },
    cancel() {
      this.$emit("cancel");
    },
    setPreviewProps(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
    },
  },
  components: {
    Core,
    CompSpeechBubble,
  },
};
</script>

<style></style>
