<template>
  <div v-if="editPropsCopy && language">
    <v-sheet class="elevation-2 pa-3 rounded-lg mb-4">
      <div>
        <!-- Component ID -->
        <v-row>
          <v-col class="pt-0 mb-2">
            <v-text-field
              placeholder="Component ID"
              v-model="editPropsCopy.id"
              hide-details
              single-line />
          </v-col>
          <v-col class="pt-0 mb-2">
            <v-text-field
              placeholder="Target to reference for scroll"
              v-model="editPropsCopy.target"
              hide-details
              single-line />
          </v-col>
        </v-row>
        <!-- Paddings -->
        <h3 class="mt-2 mb-2 black--text">Edit component paddings</h3>
        <v-row>
          <v-col>
            <v-slider
              label="Padding top"
              max="16"
              min="0"
              v-model="editPropsCopy.pt"
              :thumb-label="true"
              color="black"
              track-color="black"></v-slider
          ></v-col>
          <v-col>
            <v-slider
              label="Padding bottum"
              max="16"
              min="0"
              v-model="editPropsCopy.pb"
              :thumb-label="true"
              color="black"
              track-color="black"></v-slider
          ></v-col>
        </v-row>
      </div>
      <!-- Switch case for different types -->
      <div v-for="(prop, index) in editPropsCopy" :key="index">
        <!-- text -->
        <div v-if="prop.type == 'text'">
          <div>
            <h3 class="mt-2 black--text">Edit {{ prop.name }}</h3>
            <v-textarea
              auto-grow
              rows="1"
              v-model="prop.value[language]"></v-textarea>
          </div>
        </div>
        <!-- link -->
        <div v-if="prop.type == 'link'">
          <div>
            <h3 class="mt-2 black--text">Edit {{ prop.name }}</h3>
            <v-textarea auto-grow rows="1" v-model="prop.value"></v-textarea>
          </div>
        </div>
        <div v-if="prop.type == 'number'">
          <div>
            <h3 class="mt-2 black--text">Edit {{ prop.name }}</h3>
            <v-text-field
              v-model="prop.value"
              hide-details
              single-line
              type="number" />
          </div>
        </div>
        <!-- color -->
        <div v-if="prop.type == 'color'">
          <div>
            <h3 class="mt-2 black--text">Edit {{ prop.name }}</h3>
            <v-row>
              <v-col cols="3" class="py-0">
                <v-text-field
                  v-model="prop.value"
                  hint="HEX value or color
                string"
                  @click:append="prop.value = '#FFFFFF'"
                  :append-icon="'mdi-close-circle'" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="py-0">
                <v-color-picker
                  v-if="/^#([0-9a-f]{6}){1,2}$/i.test(prop.value)"
                  class="mt-2"
                  v-model="prop.value"
                  dot-size="25"
                  hide-mode-switch
                  mode="hexa"></v-color-picker>
                <p v-else class="grey--text">
                  If a valid HEX color is provided a color picker is visible.
                  Alternatively click the clear icon to set a valid HEX color.
                </p>
              </v-col>
            </v-row>
          </div>
        </div>
        <!-- image -->
        <div v-if="prop.type == 'image'">
          <h3 class="mt-2 black--text">Set {{ prop.name }}</h3>
          <v-row>
            <v-col cols="2"
              ><v-btn
                color="success"
                class="mt-2 mb-4"
                @click.stop="getImages(prop.name)"
                >Set image</v-btn
              ></v-col
            >
            <v-col cols="10">
              <v-text-field
                v-model="prop.value"
                :label="prop.name"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <!-- Image dialog -->
        <div v-if="prop.type == 'image'">
          <v-dialog
            persistent
            v-model="dialogImage[prop.name]"
            width="100%"
            fullscreen>
            <v-card :loading="imagesLoading">
              <v-card-title class="text-h5 black white--text">
                Set and upload images
                <v-spacer></v-spacer>
                <span class="white--text text-body-2">{{ selectedImage }}</span>
                <v-btn color="success" text @click="setImage(prop)">Set</v-btn>
                <v-btn color="error" text @click="cancelImageDialog(prop)">
                  Cancel
                </v-btn>
              </v-card-title>

              <v-card-text class="pt-4" id="core">
                <v-row>
                  <v-col cols="6">
                    <v-file-input
                      v-model="imageToUpload"
                      label="select image to upload"
                      counter
                      show-size
                      truncate-length="28"></v-file-input>
                    <v-btn
                      :disabled="!imageToUpload"
                      color="info"
                      @click="uploadImage"
                      >Upload</v-btn
                    >
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="imageFilterName"
                      label="Filter images"
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col
                    align="center"
                    v-for="(image, index) in imageUrlsFiltered"
                    :key="index">
                    <v-img
                      :style="`cursor: pointer; ${
                        index == selectedImageIndex
                          ? 'border: 4px solid lightgreen'
                          : ''
                      }`"
                      class="elevation-1 checkerboard"
                      width="300"
                      :src="image.url"
                      @click="selectImage(index, image.url)"></v-img>
                    <p>{{ image.name }}</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
        <!-- Boolean -->
        <div v-if="prop.type == 'boolean'">
          <div>
            <h3 class="mt-2 black--text">Edit {{ prop.name }}</h3>
            <v-switch v-model="prop.value"></v-switch>
          </div>
        </div>
        <!-- Select -->
        <div v-if="prop.type == 'select'">
          <div>
            <h3 class="mt-2 black--text">Edit {{ prop.name }}</h3>
            <v-select
              v-model="prop.value"
              :items="getSelectItems(prop.types)"></v-select>
          </div>
        </div>
      </div>
    </v-sheet>
    <v-row>
      <v-col>
        <v-btn color="info" @click="emitProps">Apply to Preview</v-btn>
      </v-col>
      <v-col align="end">
        <v-btn color="success" class="mr-2" @click="save">Save</v-btn>
        <v-btn color="error" @click="cancel">Cancel</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import { getImages, uploadImage } from "../api/api";
// eslint-disable-next-line no-unused-vars
import { getSelectItems } from "./helper";
import { mapState } from "vuex";

export default {
  name: "Core",
  props: ["editProps"],
  data() {
    return {
      editPropsCopy: null,
      imageUrls: [],
      dialogImage: {},
      selectedImage: "",
      selectedImageIndex: -1,
      imageToUpload: null,
      imageFilterName: "",
      imagesLoading: false,
      numberRule: v => {
        if (!isNaN(parseFloat(v))) return true;
        return "Please enter a valid number!";
      },
      aspectRatioRule: v => {
        if (
          v &&
          v.split("/").length == 2 &&
          !isNaN(parseFloat(v.split("/")[0])) &&
          !isNaN(parseFloat(v.split("/")[1]))
        )
          return true;
        return "Please enter a valid ratio! (i.e. 16/9)";
      },
    };
  },
  mounted() {
    this.editPropsCopy = JSON.parse(JSON.stringify(this.editProps));
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
    imageUrlsFiltered: function () {
      return this.imageUrls.filter(image => {
        return image.name.includes(this.imageFilterName) && !image.is_directory;
      });
    },
  },
  methods: {
    getImages(propName) {
      this.dialogImage[propName] = true;
      this.imagesLoading = true;
      getImages().then(res => {
        this.imageUrls = res.data;
        this.imagesLoading = false;
      });
    },
    getSelectItems(types) {
      return getSelectItems(types);
    },
    uploadImage() {
      uploadImage(this.imageToUpload).then(() => {
        this.getImages();
      });
    },
    selectImage(index, url) {
      this.selectedImage = url;
      this.selectedImageIndex = index;
    },
    setImage(prop) {
      prop.value = this.selectedImage;
      this.$set(this.dialogImage, prop.name, false);
    },
    cancelImageDialog(prop) {
      this.$set(this.dialogImage, prop.name, false);
      this.dialogImage = JSON.parse(JSON.stringify(this.dialogImage)); // Trigger reactive update
    },
    getOriginalPropStructure() {
      let tempProps = {};
      tempProps["id"] = this.editPropsCopy.id;
      tempProps["target"] = this.editPropsCopy.target;
      tempProps["pt"] = this.editPropsCopy.pt;
      tempProps["pb"] = this.editPropsCopy.pb;
      for (var prop in this.editPropsCopy) {
        if (this.editPropsCopy[prop].value) {
          Vue.set(tempProps, prop, this.editPropsCopy[prop].value);
        }
      }
      return tempProps;
    },
    emitProps() {
      let tmpProps = this.getOriginalPropStructure();
      this.$emit("emitProps", tmpProps);
    },
    save() {
      let tmpProps = this.getOriginalPropStructure();
      this.$emit("save", tmpProps);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style>
#core .checkerboard {
  background-image: linear-gradient(45deg, #b3b3b3 25%, transparent 25%),
    linear-gradient(-45deg, #b3b3b3 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #b3b3b3 75%),
    linear-gradient(-45deg, transparent 75%, #b3b3b3 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  background-repeat: repeat;
}
</style>
