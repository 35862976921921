<template>
  <div>
    <v-container fluid class="pa-0">
      <v-row class="elevation-2 mb-2 text-body-1" align="center">
        <v-col class="pb-0">
          <h1 v-if="!config" class="text-h5 pt-1 pb-2 pl-1">
            Configuration Overview
          </h1>
          <v-tabs v-model="tabSelected" v-if="config && !object">
            <v-tab>Pages</v-tab>
            <v-tab>Articles</v-tab>
            <v-tab>Common Components</v-tab>
          </v-tabs>
          <div
            v-if="object"
            @click="toObjectOverview"
            class="ml-2 d-inline mr-2"
            style="cursor: pointer">
            <v-icon color="red" v-if="object"> mdi-backburger </v-icon>
            <v-btn color="error" class="py-2" small dark> Go Back </v-btn>
          </div>
          <v-btn
            :disabled="objectEdited ? false : true"
            v-if="object"
            @click="uploadDialog"
            color="success"
            class="py-2"
            small
            :dark="objectEdited ? true : false">
            {{ objectEdited ? "Save Object" : "No changes" }}
          </v-btn>
        </v-col>
        <v-col class="pb-0" cols="4" align="right" justify="center">
          <span v-if="config" class="mr-4">
            <b>Selected Version:</b> {{ loadedVersion }}
          </span>
          <v-btn
            v-if="config"
            class="mr-2"
            dark
            small
            @click="toConfigOverview"
            color="blue">
            Config Overview
          </v-btn>
          <v-icon
            @click="$router.push({ path: '/' })"
            style="cursor: pointer"
            color="red"
            large
            class="mr-2">
            mdi-close-box
          </v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <ConfigOverview v-if="!config" @configSelected="setConfig" />
          <ObjectOverview
            v-if="config && !object"
            :tabSelected="tabSelected"
            :config="config"
            @editObject="editObject" />
          <Edit
            v-if="object"
            :object="object"
            @objectGotEdited="objectGotEdited" />
        </v-col>
      </v-row>
    </v-container>

    <!-- Upload dialog -->
    <v-dialog persistent v-model="dialogUpload" width="60%">
      <v-card :loading="uploadConfigLoading">
        <template slot="progress">
          <v-progress-linear
            color="green"
            height="8"
            indeterminate></v-progress-linear>
        </template>
        <v-card-title class="text-h5 grey lighten-3">
          Upload the config file
        </v-card-title>

        <v-card-text class="pt-4">
          <v-form ref="formUpload" lazy-validation>
            <v-row>
              <v-col>
                <span class="text--medium">Current selected Version: </span>
                <b>{{ loadedVersion }}</b>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <v-text-field
                  v-model="formUpload.upload.version"
                  label="New version*"
                  :rules="[v => !!v || 'Bitte ausfüllen!']"
                  required></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="formUpload.upload.comment"
                  label="Comment*"
                  :rules="[v => !!v || 'Bitte ausfüllen!']"
                  required></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="upload">Upload</v-btn>
          <v-btn color="error" text @click="dialogUpload = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Upload response snackbar -->
    <v-snackbar v-model="responseUpload.dialog" :timeout="-1" color="white">
      <v-row justify="center" align="center">
        <v-col cols="2">
          <v-icon v-if="responseUpload.type == 'error'" color="red" large
            >mdi-alert-circle-outline</v-icon
          >
          <v-icon v-if="responseUpload.type == 'success'" color="success" large
            >mdi-cloud-check-outline</v-icon
          >
        </v-col>
        <v-col align="center">
          <h4 class="black--text text-body-1" v-html="responseUpload.text"></h4>
        </v-col>
      </v-row>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="responseUpload.dialog = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ConfigOverview from "./sub_pages/ConfigOverview.vue";
import ObjectOverview from "./sub_pages/ObjectOverview.vue";
import Edit from "./sub_pages/Edit.vue";
import { savePageConfig } from "../api/api";
export default {
  data() {
    return {
      tabSelected: 0,
      config: null,
      object: null,
      objectEdited: null,
      dialogUpload: false,
      uploadConfigLoading: false,
      formUpload: {
        upload: {
          version: "",
          comment: "",
        },
      },
      responseUpload: {
        dialog: false,
        type: "",
        text: "",
      },
    };
  },
  computed: {
    loadedVersion() {
      if (this.config) {
        return this.config.meta_data.version;
      }
      return "None selected";
    },
  },
  methods: {
    setConfig(config) {
      this.config = config;
    },
    toConfigOverview() {
      if (
        !this.object ||
        confirm(
          "If you go back to the config overview you will lose your page changes!"
        )
      ) {
        this.object = null;
        this.config = null;
      }
    },
    toObjectOverview() {
      if (confirm("If you go back you will lose your page changes!")) {
        this.object = null;
      }
    },
    editObject(object) {
      this.object = object;
    },
    uploadDialog() {
      this.dialogUpload = true;
    },
    objectGotEdited(object) {
      this.objectEdited = object;
    },
    upload() {
      this.responseUpload.dialog = false;
      if (this.$refs.formUpload.validate()) {
        this.uploadConfigLoading = true;

        for (let index = 0; index < this.config.data.pages.length; index++) {
          var page = this.config.data.pages[index];
          if (page.path == this.objectEdited.path) {
            page.components = JSON.parse(
              JSON.stringify(this.objectEdited.components)
            );
            savePageConfig(this.config.meta_data.identifier, page, {
              version: this.formUpload.upload.version,
              comment: this.formUpload.upload.comment,
            })
              .then(() => {
                this.uploadConfigLoading = false;
                this.dialogUpload = false;
                this.responseUpload.dialog = true;
                this.responseUpload.type = "success";
                this.responseUpload.text = "Changes got saved successfully!";
              })
              .catch(error => {
                console.log(error);
                this.uploadConfigLoading = false;
                this.dialogUpload = false;
                this.responseUpload.dialog = true;
                this.responseUpload.type = "error";
                this.responseUpload.text =
                  "There was an error uploading the changes.<br> Error: " +
                  error;
              });
            return;
          }
        }
        this.uploadConfigLoading = false;
      }
    },
  },
  components: {
    ConfigOverview,
    ObjectOverview,
    Edit,
  },
};
</script>
