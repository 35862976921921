<template>
  <v-container fluid v-if="props" class="pa-0">
    <v-card class="pa-6 mb-10 rounded-lg">
      <h2 class="text-h4 mb-3" v-html="props.headline"></h2>
      <span v-html="props.advantages"></span>
    </v-card>
    <!-- Images -->
    <v-row justify="center">
      <v-col
        class="pa-0 px-1"
        cols="3"
        sm="3"
        md="3"
        lg="3"
        xl="3"
        align="center">
        <v-img contain max-width="88px" :src="props.img_href_1"></v-img>
      </v-col>
      <v-col
        class="pa-0 px-1"
        cols="3"
        sm="3"
        md="3"
        lg="3"
        xl="3"
        align="center">
        <v-img contain max-width="88px" :src="props.img_href_2"></v-img>
      </v-col>
      <v-col
        class="pa-0 px-1"
        cols="3"
        sm="3"
        md="3"
        lg="3"
        xl="3"
        align="center">
        <v-img contain max-width="88px" :src="props.img_href_3"></v-img>
      </v-col>
    </v-row>
    <!-- Captions -->
    <v-row justify="center">
      <v-col class="pa-0" cols="12" sm="3" md="3" lg="3" xl="3" align="center">
        <p class="text-caption text_light--text" v-html="props.caption_1"></p>
      </v-col>
      <v-col class="pa-0" cols="12" sm="3" md="3" lg="3" xl="3" align="center">
        <p class="text-caption text_light--text" v-html="props.caption_2"></p>
      </v-col>
      <v-col class="pa-0" cols="12" sm="3" md="3" lg="3" xl="3" align="center">
        <p class="text-caption text_light--text" v-html="props.caption_3"></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Advantages",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      props: state => {
        return state.config.internal_components["advantages"] ?? [];
      },
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
};
</script>

<style></style>
