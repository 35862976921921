<template>
  <v-card tile>
    <v-card-text class="pt-5">
      <v-data-table
        :loading="loadingTable"
        loading-text="Loading... Please wait"
        :headers="headersConfig"
        :items="configs"
        :items-per-page="20"
        class="elevation-0">
        <!-- eslint-disable-next-line  -->
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" color="green" @click="selectConfig(item)">
            mdi-pencil
          </v-icon>
          <v-icon class="mr-2" color="blue" @click="setProduction(item)">
            mdi-play
          </v-icon>
          <v-icon color="red" @click="deleteConfigFile(item)">
            mdi-delete
          </v-icon>
        </template>
        <!-- eslint-disable-next-line  -->
        <template v-slot:item.is_production_file="{ item }">
          <v-icon v-if="item.is_production_file" color="success"
            >mdi-check</v-icon
          >
          <v-icon v-if="!item.is_production_file" color="error"
            >mdi-close</v-icon
          >
        </template>
      </v-data-table>
    </v-card-text>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
import { getInitialAuthUrl } from "../../auth/auth";
import {
  getConfigs,
  getConfigByUUID,
  toProduction,
  deleteConfig,
} from "../../api/api";

export default {
  data() {
    return {
      configs: [],
      selectedConfig: {},
      loadingTable: false,
      headersConfig: [
        { text: "Live", value: "is_production_file" },
        { text: "Version", value: "version" },
        { text: "Identifier", value: "identifier" },
        { text: "Created on", value: "created_on" },
        { text: "Modification", value: "modification" },
        { text: "Comment", value: "comment" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    if (!this.$store.getters.access_token) {
      window.location = getInitialAuthUrl();
      return;
    }
    this.loadingTable = true;
    getConfigs()
      .then(res => {
        this.configs = res.data;
        this.loadingTable = false;
      })
      .catch(() => {
        window.location = getInitialAuthUrl();
      });
    this.dialogConfig = true;
  },
  methods: {
    selectConfig(meta_data) {
      getConfigByUUID(meta_data.identifier).then(res => {
        this.selectedConfig.data = JSON.parse(res.data);
        this.selectedConfig.meta_data = meta_data;
        this.setTheme(this.selectedConfig.data.theme);
        this.dialogConfig = false;
        this.$emit("configSelected", this.selectedConfig);
      });
    },
    deleteConfigFile(config) {
      if (confirm("Are you sure you want to delete this config file?")) {
        this.loadingTable = true;
        deleteConfig(config.identifier)
          .then(() => {
            getConfigs()
              .then(res => {
                this.configs = res.data;
                this.loadingTable = false;
              })
              .catch(() => {
                this.loadingTable = false;
                window.location = getInitialAuthUrl();
              });
            this.dialogConfig = true;
          })
          .catch(err => {
            this.loadingTable = false;
            console.log(err);
          });
      }
    },
    setProduction(config) {
      if (
        confirm(
          "Are you sure you want to set this config file to be production?"
        )
      ) {
        toProduction(config.identifier)
          .then(() => {
            this.configs.forEach(configTmp => {
              if (configTmp.identifier == config.identifier) {
                configTmp.is_production_file = true;
              } else {
                configTmp.is_production_file = false;
              }
            });
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    setTheme(theme) {
      this.$vuetify.theme.themes.light.primary = theme.primary;
      this.$vuetify.theme.themes.light.secondary = theme.secondary;
      this.$vuetify.theme.themes.light.accent = theme.accent;
      this.$vuetify.theme.themes.light.text_light = theme.text_light;
      this.$vuetify.theme.themes.light.background_primary =
        theme.background_primary;
      this.$vuetify.theme.themes.light.background_secondary =
        theme.background_secondary;
    },
  },
};
</script>

<style></style>
