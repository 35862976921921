<template>
  <v-sheet
    :id="`${props.target}`"
    :color="props.color_background"
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`">
    <v-container fluid v-if="props" class="pr-0">
      <v-row class="align-center mb-0" justify="space-between">
        <v-col
          class="container-padding pr-0"
          :align="props.align_left_column ? props.align_left_column : ''"
          cols="12"
          sm="12"
          md="6"
          lg="6"
          xl="6">
          <div>
            <div
              v-if="props.headline && props.headline[language]"
              v-html="props.headline[language]"></div>
            <div
              v-if="props.points && props.points[language]"
              v-html="props.points[language]"></div>
            <br />
            <v-btn
              v-if="props.button_text && props.button_text[language]"
              class="mt-8 px-6 py-7"
              color="secondary"
              v-html="props.button_text[language]"
              :href="props.button_href"></v-btn>
          </div>
        </v-col>

        <!-- Image -->
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
          :class="$vuetify.breakpoint.smAndDown ? `pt-10 pl-0` : `pa-0`">
          <v-img
            v-if="props.img_href"
            height="100%"
            :class="props.scaling ? 'padding-img' : ''"
            :src="props.img_href"></v-img>
        </v-col>
      </v-row>

      <!-- Edit dialog -->
      <v-dialog v-if="edit" persistent v-model="edit" fullscreen>
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Component
          </v-card-title>
          <v-card-text class="pt-5">
            <Core
              :editProps="editProps"
              @emitProps="setPreviewProps"
              @save="save"
              @cancel="cancel"
              name="Core" />
            <!-- Preview -->
            <h2 class="my-2 text-h4 black--text">Preview:</h2>
            <CompInformationToTarifBigImage
              v-if="previewProps"
              name="CompInformationToTarifBigImage"
              :props="previewProps" />
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import CompInformationToTarifBigImage from "./CompInformationToTarifBigImage";
import Core from "../editor/Core.vue";

export default {
  name: "CompInformationToTarifBigImage",
  props: ["props", "edit", "index", "componentId"],
  data() {
    return {
      editProps: {},
      previewProps: null,
    };
  },
  watch: {
    edit: function () {
      this.editProps.color_background = {};
      this.editProps.color_background.type = "color";
      this.editProps.color_background.name = "color_background";
      this.editProps.color_background.value =
        this.props.color_background ?? "#FFFFFF";

      this.editProps.target = this.props.target ?? "";
      this.editProps.pt = this.props.pt ?? 0;
      this.editProps.pb = this.props.pb ?? 0;

      this.editProps.points = {};
      this.editProps.points.type = "text";
      this.editProps.points.name = "points";
      this.editProps.points.value = this.props.points ?? {};

      this.editProps.button_text = {};
      this.editProps.button_text.type = "text";
      this.editProps.button_text.name = "button_text";
      this.editProps.button_text.value = this.props.button_text ?? {};

      this.editProps.button_href = {};
      this.editProps.button_href.type = "link";
      this.editProps.button_href.name = "button_href";
      this.editProps.button_href.value = this.props.button_href ?? "";

      this.editProps.headline = {};
      this.editProps.headline.type = "text";
      this.editProps.headline.name = "headline";
      this.editProps.headline.value = this.props.headline ?? {};

      this.editProps.img_href = {};
      this.editProps.img_href.type = "image";
      this.editProps.img_href.name = "img_href";
      this.editProps.img_href.value = this.props.img_href ?? "";

      this.editProps.align_left_column = {};
      this.editProps.align_left_column.type = "select";
      this.editProps.align_left_column.types = "aligns";
      this.editProps.align_left_column.name = "align_left_column";
      this.editProps.align_left_column.value =
        this.props.align_left_column ?? "";
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
      this.$emit("save", this.previewProps, this.index, this.componentId);
    },
    cancel() {
      this.$emit("cancel");
    },
    setPreviewProps(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
    },
  },
  components: {
    Core,
    CompInformationToTarifBigImage,
  },
};
</script>

<style>
#CompInformationToTarifBigImage .padding-img {
  margin: 5rem 15rem;
}

@media (max-width: 2300px) {
  #CompInformationToTarifBigImage .padding-img {
    margin: 5rem 12rem;
  }
}
@media (max-width: 2000px) {
  #CompInformationToTarifBigImage .padding-img {
    margin: 5rem 9rem;
  }
}
@media (max-width: 1650px) {
  #CompInformationToTarifBigImage .padding-img {
    margin: 5rem 6rem;
  }
}
@media (max-width: 1450px) {
  #CompInformationToTarifBigImage .padding-img {
    margin: 3rem 4rem;
  }
}
@media (max-width: 1350px) {
  #CompInformationToTarifBigImage .padding-img {
    margin: 1rem 2rem;
  }
}
@media (max-width: 1200px) {
  #CompInformationToTarifBigImage .padding-img {
    margin: 1rem 1rem;
  }
}
@media (max-width: 1000px) {
  #CompInformationToTarifBigImage .padding-img {
    margin: 1rem 0rem;
  }
}
@media (max-width: 960px) {
  #CompInformationToTarifBigImage .padding-img {
    margin: 1rem 0rem;
  }
}
</style>
