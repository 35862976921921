<template>
  <v-sheet
    :id="`${props.target}`"
    :color="props.color_background"
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`">
    <v-container fluid v-if="props" class="container-padding">
      <v-row justify="center">
        <v-col cols="12" sm="6" md="6" lg="6">
          <h3
            class="mb-3"
            v-if="props.sub_headline && props.sub_headline[language]"
            v-html="props.sub_headline[language]"></h3>
          <h1
            v-if="props.headline && props.headline[language]"
            v-html="props.headline[language]"></h1>
        </v-col>
        <v-col
          v-if="props.text && props.text[language]"
          cols="12"
          sm="6"
          md="6"
          lg="6">
          <p v-html="props.text[language]"></p>
        </v-col>
      </v-row>
      <!-- Edit dialog -->
      <v-dialog v-if="edit" persistent v-model="edit" fullscreen>
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Component
          </v-card-title>
          <v-card-text class="pt-5">
            <Core
              name="Core"
              :editProps="editProps"
              @emitProps="setPreviewProps"
              @save="save"
              @cancel="cancel" />
            <!-- Preview -->
            <h2 class="my-2 text-h4 black--text">Preview:</h2>
            <CompInformation
              v-if="previewProps"
              name="CompInformation"
              :props="previewProps" />
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import CompInformation from "./CompInformation";
import Core from "../editor/Core.vue";

export default {
  name: "CompInformation",
  props: ["props", "edit", "index", "componentId"],
  data() {
    return {
      editProps: {},
      previewProps: null,
    };
  },
  watch: {
    edit: function () {
      this.editProps.color_background = {};
      this.editProps.color_background.type = "color";
      this.editProps.color_background.name = "color_background";
      this.editProps.color_background.value =
        this.props.color_background ?? "#FFFFFF";

      this.editProps.target = this.props.target ?? "";
      this.editProps.pt = this.props.pt ?? 0;
      this.editProps.pb = this.props.pb ?? 0;

      this.editProps.text = {};
      this.editProps.text.type = "text";
      this.editProps.text.name = "text";
      this.editProps.text.value = this.props.text ?? {};

      this.editProps.headline = {};
      this.editProps.headline.type = "text";
      this.editProps.headline.name = "headline";
      this.editProps.headline.value = this.props.headline ?? {};

      this.editProps.sub_headline = {};
      this.editProps.sub_headline.type = "text";
      this.editProps.sub_headline.name = "sub_headline";
      this.editProps.sub_headline.value = this.props.sub_headline ?? {};
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
      this.$emit("save", this.previewProps, this.index, this.componentId);
    },
    cancel() {
      this.$emit("cancel");
    },
    setPreviewProps(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
    },
  },
  components: {
    Core,
    CompInformation,
  },
};
</script>
<style></style>
