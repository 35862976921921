<template>
  <v-sheet
    :id="`${props.target}`"
    :color="props.color_background"
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`">
    <v-container fluid v-if="props" class="container-padding">
      <v-row v-if="props.headline && props.headline[language]" justify="center">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <b v-html="props.headline[language]"></b>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
          <v-img v-if="props.img_href_1_0" :src="props.img_href_1_0"></v-img>
          <h3
            v-if="props.headline_1_0 && props.headline_1_0[language]"
            v-html="props.headline_1_0[language]"></h3>
          <v-sheet
            min-height="100px"
            v-if="props.text_1_0 && props.text_1_0[language]">
            <p v-html="props.text_1_0[language]"></p>
          </v-sheet>
          <a
            v-if="props.link_text_1_0 && props.link_text_1_0[language]"
            style="vertical-align: middle"
            v-html="props.link_text_1_0[language]"
            :href="props.link_1_0"></a>
          <v-icon
            v-if="props.link_text_1_0 && props.link_text_1_0[language]"
            size="xx-large"
            color="black"
            >mdi-arrow-right-thin</v-icon
          >
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
          <v-img v-if="props.img_href_2_0" :src="props.img_href_2_0"></v-img>
          <h3
            v-if="props.headline_2_0 && props.headline_2_0[language]"
            v-html="props.headline_2_0[language]"></h3>
          <v-sheet
            v-if="props.text_2_0 && props.text_2_0[language]"
            min-height="100px">
            <p v-html="props.text_2_0[language]"></p>
          </v-sheet>
          <a
            v-if="props.link_text_2_0 && props.link_text_2_0[language]"
            style="vertical-align: middle"
            v-html="props.link_text_2_0[language]"
            :href="props.link_2_0"></a>
          <v-icon
            v-if="props.link_text_2_0 && props.link_text_2_0[language]"
            size="xx-large"
            color="black"
            >mdi-arrow-right-thin</v-icon
          >
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
          <v-img v-if="props.img_href_3_0" :src="props.img_href_3_0"></v-img>
          <h3
            v-if="props.headline_3_0 && props.headline_3_0[language]"
            v-html="props.headline_3_0[language]"></h3>
          <v-sheet
            v-if="props.text_3_0 && props.text_3_0[language]"
            min-height="100px">
            <p v-html="props.text_3_0[language]"></p>
          </v-sheet>
          <a
            v-if="props.link_text_3_0 && props.link_text_3_0[language]"
            style="vertical-align: middle"
            v-html="props.link_text_3_0[language]"
            :href="props.link_3_0"></a>
          <v-icon
            v-if="props.link_text_3_0 && props.link_text_3_0[language]"
            size="xx-large"
            color="black"
            >mdi-arrow-right-thin</v-icon
          >
        </v-col>
      </v-row>

      <!-- Edit dialog -->
      <v-dialog v-if="edit" persistent v-model="edit" fullscreen>
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Component
          </v-card-title>
          <v-card-text class="pt-5">
            <Core
              name="Core"
              :editProps="editProps"
              @emitProps="setPreviewProps"
              @save="save"
              @cancel="cancel" />
            <!-- Preview -->
            <h2 class="my-2 text-h4 black--text">Preview:</h2>
            <CompGrid3x1Links
              v-if="previewProps"
              name="CompGrid3x1Links"
              :props="previewProps" />
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import CompGrid3x1Links from "./CompGrid3x1Links";
import Core from "../editor/Core.vue";

export default {
  name: "CompGrid3x1Links",
  props: ["props", "edit", "index", "componentId"],
  data() {
    return {
      editProps: {},
      previewProps: null,
    };
  },
  watch: {
    edit: function () {
      this.editProps.color_background = {};
      this.editProps.color_background.type = "color";
      this.editProps.color_background.name = "color_background";
      this.editProps.color_background.value =
        this.props.color_background ?? "#FFFFFF";

      this.editProps.target = this.props.target ?? "";
      this.editProps.pt = this.props.pt ?? 0;
      this.editProps.pb = this.props.pb ?? 0;

      this.editProps.headline = {};
      this.editProps.headline.type = "text";
      this.editProps.headline.name = "headline";
      this.editProps.headline.value = this.props.headline ?? {};

      this.editProps.headline_1_0 = {};
      this.editProps.headline_1_0.type = "text";
      this.editProps.headline_1_0.name = "headline_1_0";
      this.editProps.headline_1_0.value = this.props.headline_1_0 ?? {};

      this.editProps.headline_2_0 = {};
      this.editProps.headline_2_0.type = "text";
      this.editProps.headline_2_0.name = "headline_2_0";
      this.editProps.headline_2_0.value = this.props.headline_2_0 ?? {};

      this.editProps.headline_3_0 = {};
      this.editProps.headline_3_0.type = "text";
      this.editProps.headline_3_0.name = "headline_3_0";
      this.editProps.headline_3_0.value = this.props.headline_3_0 ?? {};

      this.editProps.text_1_0 = {};
      this.editProps.text_1_0.type = "text";
      this.editProps.text_1_0.name = "text_1_0";
      this.editProps.text_1_0.value = this.props.text_1_0 ?? {};

      this.editProps.text_2_0 = {};
      this.editProps.text_2_0.type = "text";
      this.editProps.text_2_0.name = "text_2_0";
      this.editProps.text_2_0.value = this.props.text_2_0 ?? {};

      this.editProps.text_3_0 = {};
      this.editProps.text_3_0.type = "text";
      this.editProps.text_3_0.name = "text_3_0";
      this.editProps.text_3_0.value = this.props.text_3_0 ?? {};

      this.editProps.link_text_1_0 = {};
      this.editProps.link_text_1_0.type = "text";
      this.editProps.link_text_1_0.name = "link_text_1_0";
      this.editProps.link_text_1_0.value = this.props.link_text_1_0 ?? {};

      this.editProps.link_text_2_0 = {};
      this.editProps.link_text_2_0.type = "text";
      this.editProps.link_text_2_0.name = "link_text_2_0";
      this.editProps.link_text_2_0.value = this.props.link_text_2_0 ?? {};

      this.editProps.link_text_3_0 = {};
      this.editProps.link_text_3_0.type = "text";
      this.editProps.link_text_3_0.name = "link_text_3_0";
      this.editProps.link_text_3_0.value = this.props.link_text_3_0 ?? {};

      this.editProps.link_1_0 = {};
      this.editProps.link_1_0.type = "link";
      this.editProps.link_1_0.name = "link_1_0";
      this.editProps.link_1_0.value = this.props.link_1_0 ?? "";

      this.editProps.link_2_0 = {};
      this.editProps.link_2_0.type = "link";
      this.editProps.link_2_0.name = "link_2_0";
      this.editProps.link_2_0.value = this.props.link_2_0 ?? "";

      this.editProps.link_3_0 = {};
      this.editProps.link_3_0.type = "link";
      this.editProps.link_3_0.name = "link_3_0";
      this.editProps.link_3_0.value = this.props.link_3_0 ?? "";

      this.editProps.img_href_1_0 = {};
      this.editProps.img_href_1_0.type = "image";
      this.editProps.img_href_1_0.name = "img_href_1_0";
      this.editProps.img_href_1_0.value = this.props.img_href_1_0 ?? "";

      this.editProps.img_href_2_0 = {};
      this.editProps.img_href_2_0.type = "image";
      this.editProps.img_href_2_0.name = "img_href_2_0";
      this.editProps.img_href_2_0.value = this.props.img_href_2_0 ?? "";

      this.editProps.img_href_3_0 = {};
      this.editProps.img_href_3_0.type = "image";
      this.editProps.img_href_3_0.name = "img_href_3_0";
      this.editProps.img_href_3_0.value = this.props.img_href_3_0 ?? "";
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
      this.$emit("save", this.previewProps, this.index, this.componentId);
    },
    cancel() {
      this.$emit("cancel");
    },
    setPreviewProps(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
    },
  },
  components: {
    Core,
    CompGrid3x1Links,
  },
};
</script>

<style></style>
