<template>
  <v-form ref="form">
    <h1 class="text-h5 pb-10">Objektdaten Hausstrom</h1>
    <h3>STROMDATEN</h3>
    <v-row>
      <v-col>
        <v-text-field
          v-model="form.energyData.currentSupplier.value"
          label="Bisheriger Stromlieferant (Hausstrom)*"
          :rules="[v => !!v || 'Bitte ausfüllen!']"
          required></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="form.energyData.customerNumberOld.value"
          label="Kundennummer beim bisherigen Stromanbieter"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="form.energyData.meteringNumber.value"
          label="Zählernummer*"
          :rules="[v => !!v || 'Bitte ausfüllen!']"
          required></v-text-field>
      </v-col>
    </v-row>

    <h3 class="pt-5">WECHSELGRUND*</h3>
    <v-row>
      <v-col>
        <v-radio-group v-model="changeReason" column class="mt-1" mandatory>
          <v-radio
            v-for="(item, index) in changeReasonItems"
            :label="item.name"
            :value="item.value"
            :key="`${index}-change-reason`"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="form.changeReason.date.value"
          :label="
            changeReason != -1
              ? changeReasonItems[changeReason - 1].requiredDate
                ? `Datum auswählen*`
                : `Datum auswählen`
              : `Datum auswählen`
          "
          :rules="
            changeReason != -1
              ? changeReasonItems[changeReason - 1].requiredDate
                ? [v => !!v || 'Bitte ausfüllen!']
                : []
              : []
          "
          type="date"></v-text-field>
      </v-col>
    </v-row>
    <p style="font-size: 8pt; color: grey">*Pflichtangaben</p>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      changeReason: 1,
      changeReasonItems: [
        {
          name: "Lieferantenwechsel zum nächstmöglichen Zeitpunkt",
          value: 1,
          requiredDate: false,
        },
        { name: "Lieferantenwechsel zum", value: 2, requiredDate: true },
        { name: "Einzug/Umzug zum", value: 3, requiredDate: true },
        {
          name: "Vertrag durch Kunden bereits gekündigt zum",
          value: 4,
          requiredDate: true,
        },
        { name: "Neubau des Hauses", value: 5, requiredDate: true },
      ],
      menu: false,
      form: {
        text: "Objektdaten Hausstrom",
        energyData: {
          text: "Stromdaten",
          currentSupplier: { text: "Bisheriger Stromlieferant", value: "" },
          customerNumberOld: {
            text: "Kundennummer beim bisherigen Stromanbieter",
            value: "",
          },
          meteringNumber: { text: "Zählernummer", value: "" },
        },
        changeReason: {
          text: "Wechselgrund",
          reason: { text: "Wechselgrund", value: "" },
          date: { text: "Datum", value: "" },
        },
      },
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    checkDate(v) {
      var requiredDate = false;
      this.changeReasonItems.forEach(element => {
        if (this.changeReason == element.value && element.requiredDate)
          requiredDate = true;
      });
      return !(requiredDate && !v);
    },
    parseData() {
      this.form.changeReason.reason.value =
        this.changeReasonItems[this.changeReason - 1].name;
    },
    saveData(id) {
      this.parseData();
      let data = {
        id: id,
        section: "formSupplyObject",
        data: this.form,
        enabled: true,
      };
      this.$store.commit("setCheckoutData", data);
    },
  },
  mounted() {
    this.resetValidation();
  },
};
</script>

<style></style>
