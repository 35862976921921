<template>
  <v-sheet
    :id="`${props.target}`"
    :color="props.color_background"
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`">
    <v-container
      fluid
      :class="`container-padding ${props.mode == 'dark' ? 'white--text' : ''}`"
      v-if="props">
      <v-row justify="center" v-if="props.headline && props.headline[language]">
        <!-- Headline -->
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <h2 v-html="props.headline[language]"></h2>
        </v-col>
      </v-row>
      <v-row justify="space-between" class="py-3">
        <!-- Columns -->
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <v-sheet
            class="pa-8 rounded d-flex align-center flex-column"
            elevation="0"
            height="100%"
            :color="props.color ? props.color : 'background_secondary'">
            <v-img
              v-if="props.img_href_1_0"
              :src="props.img_href_1_0"
              :max-height="`${props.img_max_height}px`"
              contain></v-img>
            <h3
              v-if="props.headline_1_0 && props.headline_1_0[language]"
              :style="props.headline_link_1_0 ? `cursor: pointer` : ``"
              :class="`${props.mode == 'dark' ? 'white--text' : ''}`"
              v-html="props.headline_1_0[language]"
              @click="$router.push({ path: props.headline_link_1_0 })"></h3>
            <div
              v-if="props.text_1_0 && props.text_1_0[language]"
              :class="`${props.mode == 'dark' ? 'white--text' : ''}`"
              v-html="props.text_1_0[language]"></div>
            <div
              v-if="props.link_1_0 && props.link_1_0[language]"
              class="mt-auto"
              v-html="props.link_1_0[language]"></div>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <v-sheet
            class="pa-8 rounded d-flex align-center flex-column"
            elevation="0"
            height="100%"
            :color="props.color ? props.color : 'background_secondary'">
            <v-img
              v-if="props.img_href_2_0"
              :src="props.img_href_2_0"
              :max-height="`${props.img_max_height}px`"
              contain></v-img>
            <h3
              v-if="props.headline_2_0 && props.headline_2_0[language]"
              :style="props.headline_link_2_0 ? `cursor: pointer` : ``"
              :class="`${props.mode == 'dark' ? 'white--text' : ''}`"
              v-html="props.headline_2_0[language]"
              @click="$router.push({ path: props.headline_link_2_0 })"></h3>
            <div
              v-if="props.text_2_0 && props.text_2_0[language]"
              :class="`${props.mode == 'dark' ? 'white--text' : ''}`"
              v-html="props.text_2_0[language]"></div>
            <div
              v-if="props.link_2_0 && props.link_2_0[language]"
              class="mt-auto"
              v-html="props.link_2_0[language]"></div>
          </v-sheet>
        </v-col>
      </v-row>

      <!-- Edit dialog -->
      <v-dialog v-if="edit" persistent v-model="edit" fullscreen>
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Component
          </v-card-title>
          <v-card-text class="pt-5">
            <Core
              :editProps="editProps"
              @emitProps="setPreviewProps"
              @save="save"
              @cancel="cancel"
              name="Core" />
            <!-- Preview -->
            <h2 class="my-2 text-h4 black--text">Preview:</h2>
            <CompGrid2x1
              v-if="previewProps"
              name="CompGrid2x1"
              :props="previewProps" />
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import CompGrid2x1 from "./CompGrid2x1";
import Core from "../editor/Core.vue";

export default {
  name: "CompGrid2x1",
  props: ["props", "edit", "index", "componentId"],
  data() {
    return {
      editProps: {},
      previewProps: null,
    };
  },
  watch: {
    edit: function () {
      this.editProps.color_background = {};
      this.editProps.color_background.type = "color";
      this.editProps.color_background.name = "color_background";
      this.editProps.color_background.value =
        this.props.color_background ?? "#FFFFFF";

      this.editProps.target = this.props.target ?? "";
      this.editProps.pt = this.props.pt ?? 0;
      this.editProps.pb = this.props.pb ?? 0;

      // headline, mode, , link_1_0

      this.editProps.headline = {};
      this.editProps.headline.type = "text";
      this.editProps.headline.name = "headline";
      this.editProps.headline.value = this.props.headline ?? {};

      this.editProps.headline_1_0 = {};
      this.editProps.headline_1_0.type = "text";
      this.editProps.headline_1_0.name = "headline_1_0";
      this.editProps.headline_1_0.value = this.props.headline_1_0 ?? {};

      this.editProps.headline_2_0 = {};
      this.editProps.headline_2_0.type = "text";
      this.editProps.headline_2_0.name = "headline_2_0";
      this.editProps.headline_2_0.value = this.props.headline_2_0 ?? {};

      this.editProps.text_1_0 = {};
      this.editProps.text_1_0.type = "text";
      this.editProps.text_1_0.name = "text_1_0";
      this.editProps.text_1_0.value = this.props.text_1_0 ?? {};

      this.editProps.text_2_0 = {};
      this.editProps.text_2_0.type = "text";
      this.editProps.text_2_0.name = "text_2_0";
      this.editProps.text_2_0.value = this.props.text_2_0 ?? {};

      this.editProps.img_max_height = {};
      this.editProps.img_max_height.type = "number";
      this.editProps.img_max_height.name = "img_max_height";
      this.editProps.img_max_height.value = this.props.img_max_height ?? 100;

      this.editProps.headline_link_1_0 = {};
      this.editProps.headline_link_1_0.type = "link";
      this.editProps.headline_link_1_0.name = "headline_link_1_0";
      this.editProps.headline_link_1_0.value =
        this.props.headline_link_1_0 ?? "";

      this.editProps.headline_link_2_0 = {};
      this.editProps.headline_link_2_0.type = "link";
      this.editProps.headline_link_2_0.name = "headline_link_2_0";
      this.editProps.headline_link_2_0.value =
        this.props.headline_link_2_0 ?? "";

      this.editProps.link_1_0 = {};
      this.editProps.link_1_0.type = "text";
      this.editProps.link_1_0.name = "link_1_0";
      this.editProps.link_1_0.value = this.props.link_1_0 ?? "";

      this.editProps.link_2_0 = {};
      this.editProps.link_2_0.type = "text";
      this.editProps.link_2_0.name = "link_2_0";
      this.editProps.link_2_0.value = this.props.link_2_0 ?? "";

      this.editProps.img_href_1_0 = {};
      this.editProps.img_href_1_0.type = "image";
      this.editProps.img_href_1_0.name = "img_href_1_0";
      this.editProps.img_href_1_0.value = this.props.img_href_1_0 ?? "";

      this.editProps.img_href_2_0 = {};
      this.editProps.img_href_2_0.type = "image";
      this.editProps.img_href_2_0.name = "img_href_2_0";
      this.editProps.img_href_2_0.value = this.props.img_href_2_0 ?? "";

      this.editProps.color = {};
      this.editProps.color.type = "color";
      this.editProps.color.name = "color";
      this.editProps.color.value = this.props.color ?? "#FFFFFF";
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
      this.$emit("save", this.previewProps, this.index, this.componentId);
    },
    cancel() {
      this.$emit("cancel");
    },
    setPreviewProps(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
    },
  },
  components: {
    Core,
    CompGrid2x1,
  },
};
</script>

<style></style>
