<template>
  <v-form ref="formContact" v-model="valid" lazy-validation>
    <h1 class="text-h5 pb-10">Persönliche Daten für die Anmeldung</h1>

    <v-row>
      <v-col>
        <v-radio-group v-model="form.customerType" row mandatory>
          <v-radio label="Privat" value="1" @click="resetValidation()">
          </v-radio>
          <v-radio label="Firma" value="2" @click="resetValidation()">
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <!-- Private Customer -->
    <div v-if="form.customerType == 1">
      <h3 class="pt-5">PRIVATKUNDE</h3>
      <v-row>
        <v-col cols="12" md="6">
          <v-select
            v-model="form.private.privateCustomer.salutation.value"
            :items="[`Herr`, `Frau`, `Divers`]"
            label="Anrede*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="form.private.privateCustomer.title.value"
            :items="[`Prof.`, `Dr.`, `Ing.`, `Kfm.`]"
            label="Titel"></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.private.privateCustomer.firstName.value"
            label="Vorname*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.private.privateCustomer.lastName.value"
            label="Nachname*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
      </v-row>
      <h3 class="pt-5">LIEFERADRESSE</h3>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.private.supplyAddress.street.value"
            label="Straße*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.private.supplyAddress.number.value"
            label="Hausnummer*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.private.supplyAddress.postalcode.value"
            label="Postleitzahl*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.private.supplyAddress.region.value"
            label="Ort*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.private.privateCustomer.birthday.value"
            label="Geburtstag"
            type="date"></v-text-field>
        </v-col>
      </v-row>
      <h3 class="pt-5">ERREICHBARKEIT FÜR RÜCKFRAGEN</h3>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.private.callbackData.email.value"
            label="E-Mail-Adresse*"
            :rules="[
              v => !!v || 'Bitte ausfüllen!',
              v => /.+@.+\..+/.test(v) || 'Ungültige Email!',
            ]"
            required></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            type="number"
            v-model="form.private.callbackData.phone.value"
            label="Telefon*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            placeholder="Festnetz/Mobil"></v-text-field>
        </v-col>
      </v-row>
      <p style="font-size: 8pt; color: grey">*Pflichtangaben</p>
    </div>

    <!-- Company Customer -->
    <div v-if="form.customerType == 2">
      <h3 class="pt-5">FIRMA</h3>
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.commercial.commercialCustomer.company.value"
            label="Unternehmen*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            v-model="form.commercial.commercialCustomer.legalform.value"
            :items="[
              `AG`,
              `Einzelunternehmen`,
              `GbR`,
              `GmbH`,
              `GmbH & Co KG`,
              `Holding`,
              `KG`,
              `KGaA`,
              `OHG`,
              `UG (haftungsbeschränkt)`,
            ]"
            label="Rechtsform*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-select>
        </v-col>
        <v-col>
          <v-text-field
            v-model="form.commercial.commercialCustomer.registryCourt.value"
            label="Registergericht*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.commercial.commercialCustomer.hrb.value"
            label="HRB-Nummer*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
      </v-row>
      <h3 class="pt-5">ADRESSE & KONTAKT</h3>
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.commercial.supplyAddress.street.value"
            label="Straße*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="form.commercial.supplyAddress.number.value"
            label="Hausnummer*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.commercial.supplyAddress.postalcode.value"
            label="Postleitzahl*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="form.commercial.supplyAddress.region.value"
            label="Ort*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.commercial.supplyAddress.email.value"
            label="E-Mail-Adresse*"
            :rules="[
              v => !!v || 'Bitte ausfüllen!',
              v => /.+@.+\..+/.test(v) || 'Ungültige Email!',
            ]"
            required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.commercial.supplyAddress.phone.value"
            label="Telefonnummer*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
      </v-row>
      <h3 class="pt-5">ANSPRECHPARTNER</h3>
      <v-row>
        <v-col>
          <v-select
            v-model="form.commercial.callbackPerson.salutation.value"
            :items="[`Herr`, `Frau`, `Divers`]"
            label="Anrede*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-select>
        </v-col>
        <v-col>
          <v-select
            v-model="form.commercial.callbackPerson.title.value"
            :items="[`Prof.`, `Dr.`, `Ing.`, `Kfm.`]"
            label="Titel"></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.commercial.callbackPerson.firstName.value"
            label="Vorname*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="form.commercial.callbackPerson.lastName.value"
            label="Nachname*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
      </v-row>
      <p style="font-size: 8pt; color: grey">*Pflichtangaben</p>
    </div>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      date: "",
      menu: false,
      valid: true,
      form: {
        customerType: 2,
        private: {
          text: "Persönliche Daten",
          privateCustomer: {
            text: "Privat",
            salutation: { text: "Anrede", value: "" },
            title: { text: "Titel", value: "" },
            firstName: { text: "Vorname", value: "" },
            lastName: { text: "Nachname", value: "" },
            birthday: { text: "Geburtstag", value: "" },
          },
          supplyAddress: {
            text: "Lieferadresse",
            street: { text: "Straße", value: "" },
            number: { text: "Hausnummer", value: "" },
            postalcode: { text: "Postleitzahl", value: "" },
            region: { text: "Ort", value: "" },
          },
          callbackData: {
            text: "Erreichbarkeit für Rückfragen",
            email: { text: "E-Mail-Adresse", value: "" },
            phone: { text: "Telefonnummer", value: "" },
          },
        },
        commercial: {
          text: "Persönliche Daten",
          commercialCustomer: {
            text: "Firma",
            company: { text: "Unternehmen", value: "" },
            legalform: { text: "Rechtsform", value: "" },
            registryCourt: { text: "Registergericht", value: "" },
            hrb: { text: "HRB", value: "" },
          },
          supplyAddress: {
            text: "Adresse und Kontakt",
            street: { text: "Straße", value: "" },
            number: { text: "Hausnummer", value: "" },
            postalcode: { text: "Postleitzahl", value: "" },
            region: { text: "Ort", value: "" },
            email: { text: "E-Mail-Adresse", value: "" },
            phone: { text: "Telefonnummer", value: "" },
          },
          callbackPerson: {
            text: "Ansprechpartner",
            salutation: { text: "Anrede", value: "" },
            title: { text: "Titel", value: "" },
            firstName: { text: "Vorname", value: "" },
            lastName: { text: "Nachname", value: "" },
          },
        },
      },
    };
  },
  methods: {
    validate() {
      return this.$refs.formContact.validate();
    },
    reset() {
      this.$refs.formContact.reset();
    },
    resetValidation() {
      this.$refs.formContact.resetValidation();
    },
    saveData(id) {
      let data = {
        id: id,
        section: "formContact",
        data:
          this.form.customerType == 1
            ? this.form.private
            : this.form.commercial,
        enabled: true,
        customerType: this.form.customerType == 1 ? "private" : "commercial",
      };
      this.$store.commit("setCheckoutData", data);
    },
  },
  mounted() {
    this.resetValidation();
  },
};
</script>

<style></style>
