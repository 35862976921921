<template>
  <v-sheet
    :id="`${props.target}`"
    :color="props.color_background"
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`"
    :style="`background-image: url(\'${
      $vuetify.breakpoint.smAndDown
        ? props.mobile_img_href
          ? props.mobile_img_href
          : props.img_href
        : props.img_href
    }\');background-position: center;background-size: cover;`">
    <v-container fluid v-if="props" class="container-padding">
      <v-row align="center" class="py-6" :style="`height: ${props.height}px;`">
        <!-- Text -->
        <v-col
          :align="$vuetify.breakpoint.xs ? 'center' : 'left'"
          cols="12"
          sm="6"
          md="6"
          lg="6"
          class="pa-6"
          style="
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 5px;
          ">
          <div
            v-if="props.text && props.text[language]"
            v-html="props.text[language]"></div>
          <!-- Button -->
          <v-btn
            v-if="props.button_text && props.button_text[language]"
            v-html="props.button_text[language]"
            class="px-6 py-7"
            color="secondary"
            style=""
            @click="
              if ($route.query.target) scrollIntoView($route.query.target);
            "
            :to="props.link"></v-btn>
        </v-col>
      </v-row>

      <!-- Edit dialog -->
      <v-dialog v-if="edit" persistent v-model="edit" fullscreen>
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Component
          </v-card-title>
          <v-card-text class="pt-5">
            <Core
              :editProps="editProps"
              @emitProps="setPreviewProps"
              @save="save"
              @cancel="cancel" />
            <!-- Preview -->
            <h2 class="my-2 text-h4 black--text">Preview:</h2>
            <CompBannerToTarif
              v-if="previewProps"
              name="CompBannerToTarif"
              :props="previewProps" />
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import CompBannerToTarif from "./CompBannerToTarif";
import Core from "../editor/Core.vue";
import { scrollIntoView } from "../helper";

export default {
  name: "CompBannerToTarif",
  props: ["props", "edit", "index", "componentId"],
  data() {
    return {
      editProps: {},
      previewProps: null,
    };
  },
  watch: {
    edit: function () {
      this.editProps.color_background = {};
      this.editProps.color_background.type = "color";
      this.editProps.color_background.name = "color_background";
      this.editProps.color_background.value =
        this.props.color_background ?? "#FFFFFF";

      this.editProps.target = this.props.target ?? "";
      this.editProps.pt = this.props.pt ?? 0;
      this.editProps.pb = this.props.pb ?? 0;

      this.editProps.button_text = {};
      this.editProps.button_text.type = "text";
      this.editProps.button_text.name = "button_text";
      this.editProps.button_text.value = this.props.button_text ?? {};

      this.editProps.text = {};
      this.editProps.text.type = "text";
      this.editProps.text.name = "text";
      this.editProps.text.value = this.props.text ?? {};

      this.editProps.img_href = {};
      this.editProps.img_href.type = "image";
      this.editProps.img_href.name = "img_href";
      this.editProps.img_href.value = this.props.img_href ?? "";

      this.editProps.mobile_img_href = {};
      this.editProps.mobile_img_href.type = "image";
      this.editProps.mobile_img_href.name = "mobile_img_href";
      this.editProps.mobile_img_href.value = this.props.mobile_img_href ?? "";

      this.editProps.link = {};
      this.editProps.link.type = "link";
      this.editProps.link.name = "link";
      this.editProps.link.value = this.props.link ?? "";

      this.editProps.height = {};
      this.editProps.height.type = "number";
      this.editProps.height.name = "height";
      this.editProps.height.value = this.props.height ?? 400;
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
      this.$emit("save", this.previewProps, this.index, this.componentId);
    },
    cancel() {
      this.$emit("cancel");
    },
    setPreviewProps(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
    },
    scrollIntoView(id) {
      scrollIntoView(id);
    },
  },
  components: {
    Core,
    CompBannerToTarif,
  },
};
</script>

<style></style>
