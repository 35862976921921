<template>
  <v-sheet
    :id="`${props.target}`"
    :color="
      props.color_background ? props.color_background : 'background_secondary'
    "
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`">
    <v-container
      fluid
      v-if="props"
      class="container-padding"
      id="CompCareerForm">
      <v-row justify="center">
        <v-col>
          <v-card class="py-4 px-6 mt-4 mb-6">
            <v-form ref="formCareer" class="black--text" lazy-validation>
              <h1 class="mb-2">Bewerbungsformular</h1>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="form.position"
                    :items="positions[language]"
                    :rules="[v => !!v || 'Bitte ausfüllen!']"
                    label="Position*"></v-select>
                </v-col>
              </v-row>
              <h2>Persönliche Daten</h2>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="form.title"
                    :items="[`Prof.`, `Dr.`, `Ing.`, `Kfm.`]"
                    label="Titel"></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="form.salutation"
                    :items="[`Herr`, `Frau`, `Divers`, `Keine Angabe`]"
                    label="Anrede*"
                    :rules="[v => !!v || 'Bitte ausfüllen!']"
                    required></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.first_name"
                    label="Vorname*"
                    :rules="[v => !!v || 'Bitte ausfüllen!']"
                    required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.last_name"
                    label="Nachname*"
                    :rules="[v => !!v || 'Bitte ausfüllen!']"
                    required></v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.street_and_number"
                    label="Strasse und Hausnummer*"
                    :rules="[v => !!v || 'Bitte ausfüllen!']"
                    required></v-text-field
                ></v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.zip_code"
                    label="Postleitzahl*"
                    :rules="[v => !!v || 'Bitte ausfüllen!']"
                    required></v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.city"
                    label="Ort*"
                    :rules="[v => !!v || 'Bitte ausfüllen!']"
                    required></v-text-field
                ></v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.country"
                    label="Land*"
                    :rules="[v => !!v || 'Bitte ausfüllen!']"
                    required></v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.phone"
                    label="Telefonnummer*"
                    :rules="[v => !!v || 'Bitte ausfüllen!']"
                    required></v-text-field
                ></v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.email"
                    label="E-Mail*"
                    :rules="[
                      v => !!v || 'Bitte ausfüllen!',
                      v =>
                        !!v.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) ||
                        'E-Mail ist ungültig!',
                    ]"
                    required></v-text-field
                ></v-col>
              </v-row>
              <h2 class="mt-4">Sonstiges</h2>
              <br />
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.start_date"
                    type="date"
                    label="Frühestmöglicher Eintrittstermin*"
                    :rules="[v => !!v || 'Bitte ausfüllen!']"
                    required></v-text-field
                ></v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.week_hours"
                    type="number"
                    label="Wochenarbeitsstunden*"
                    required></v-text-field
                ></v-col>
              </v-row>
              <h2 class="mt-4">Anlagen</h2>
              <br />
              <p>
                Du kannst hier Deine Bewerbungsunterlagen hochladen. Folgende
                Dateiformate sind möglich: Word, PDF, PNG, JPG. Die Dokumente
                dürfen eine Größe von 20 MB nicht überschreiten.
              </p>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-file-input
                    v-model="form.application_letter"
                    class="mt-4"
                    label="Anschreiben*"
                    required
                    :rules="[...uploadRules, ...rules]"
                    chips
                    counter
                    multiple
                    truncate-length="30"></v-file-input>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-file-input
                    v-model="form.cv"
                    class="mt-4"
                    label="Lebenslauf*"
                    required
                    :rules="[...uploadRules, ...rules]"
                    chips
                    counter
                    multiple
                    truncate-length="30"></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-file-input
                    v-model="form.qualifications"
                    class="mt-4"
                    label="Zeugnisse"
                    :rules="uploadRules"
                    chips
                    counter
                    multiple
                    truncate-length="30"></v-file-input>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-file-input
                    v-model="form.work_samples"
                    class="mt-4"
                    label="Arbeitsproben"
                    :rules="uploadRules"
                    chips
                    counter
                    multiple
                    truncate-length="30"></v-file-input>
                </v-col>
              </v-row>
              <p class="text-caption">
                Alle mit * gekennzeichneten Felder sind Pflichtfelder
              </p>
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="form.checkbox_agb"
                    required
                    :rules="[v => !!v || 'Bitte zustimmen!']">
                    <template v-slot:label>
                      <span class="vertical-align:middle black--text">
                        Hiermit bestätige ich, dass ich die
                        <a
                          @click="dataPolicyClicked"
                          style="color: #eb002e"
                          href="privacy-policy">
                          Datenschutzerklärung</a
                        >
                        zur Kenntnis genommen habe.
                      </span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    @click="applicationSubmit"
                    class="py-6 mt-2 mb-2"
                    color="secondary"
                    width="100%"
                    >Bewerbung absenden</v-btn
                  >
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="py-0">
                  <v-alert v-if="showSuccessAlert" dense text type="success">
                    Ihre Anfrage ist erfolgreich verschickt!
                  </v-alert>
                  <v-alert v-if="showInfoAlert" dense text type="info">
                    Anfrage wird bearbeitet...
                  </v-alert>
                  <v-alert v-if="showErrorAlert" dense text type="error">
                    Leider ist ein Fehler aufgetreten. Versuchen Sie es bitte
                    erneut oder kontaktieren Sie uns direkt per E-Mail.
                  </v-alert>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>

      <!-- Edit dialog -->
      <v-dialog v-if="edit" persistent v-model="edit" fullscreen>
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Component
          </v-card-title>
          <v-card-text class="pt-5">
            <Core
              :editProps="editProps"
              @emitProps="setPreviewProps"
              @save="save"
              @cancel="cancel"
              name="Core" />
            <!-- Preview -->
            <h2 class="my-2 text-h4 black--text">Preview:</h2>
            <CompCareerForm
              v-if="previewProps"
              name="CompCareerForm"
              :props="previewProps" />
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import CompCareerForm from "./CompCareerForm";
import Core from "../editor/Core.vue";
import { postCareerRequest } from "../api/api";
export default {
  name: "CompCareerForm",
  props: ["props", "edit", "index", "componentId"],
  data() {
    return {
      editProps: {},
      previewProps: null,
      positions: {
        de: [
          "Customer Service Agent In- und Outbound",
          "Data Analyst/ Data Scientist",
          "Initiativbewerbung",
        ],
        en: [
          "Customer Service Agent In- and Outbound",
          "Data Analyst/Data Scientist",
          "Unsolicited Application",
        ],
      },
      form: {
        position: "",
        title: "",
        salutation: "",
        first_name: "",
        last_name: "",
        street_and_number: "",
        zip_code: "",
        city: "",
        country: "",
        phone: "",
        email: "",
        start_date: "",
        week_hours: "",
        application_letter: null,
        cv: null,
        qualifications: null,
        work_samples: null,
      },
      showErrorAlert: false,
      showInfoAlert: false,
      showSuccessAlert: false,
      rules: [
        value => {
          if (!value) return "Bitte ausfüllen!";
          return true;
        },
      ],
      uploadRules: [
        value => {
          if (!value) return true;
          let sizeSum = 0;
          for (let index = 0; index < value.length; index++) {
            const size = value[index].size;
            sizeSum += size;
          }
          if (sizeSum > 20000000)
            return "Dokumente müssen weniger als 20 MB groß sein!";
          return true;
        },
      ],
    };
  },
  watch: {
    edit: function () {
      this.editProps.color_background = {};
      this.editProps.color_background.type = "color";
      this.editProps.color_background.name = "color_background";
      this.editProps.color_background.value =
        this.props.color_background ?? "#FFFFFF";

      this.editProps.target = this.props.target ?? "";
      this.editProps.pt = this.props.pt ?? 0;
      this.editProps.pb = this.props.pb ?? 0;

      this.editProps.id = this.props.id ?? "";
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  methods: {
    save(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
      this.$emit("save", this.previewProps, this.index, this.componentId);
    },
    cancel() {
      this.$emit("cancel");
    },
    setPreviewProps(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
    },
    applicationSubmit() {
      if (this.$refs.formCareer.validate()) {
        let payload = {
          position: this.form.position,
          title: this.form.title,
          salutation: this.form.salutation,
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          street_and_number: this.form.street_and_number,
          zip_code: this.form.zip_code,
          city: this.form.city,
          country: this.form.country,
          phone: this.form.phone,
          email: this.form.email,
          start_date: this.form.start_date,
          week_hours: this.form.week_hours,
          application_letter: this.form.application_letter,
          cv: this.form.cv,
          qualifications: this.form.qualifications,
          work_samples: this.form.work_samples,
        };
        this.showInfoAlert = true;
        this.showErrorAlert = false;
        this.showSuccessAlert = false;
        postCareerRequest(payload)
          .then(() => {
            this.showInfoAlert = false;
            this.showSuccessAlert = true;
          })
          .catch(error => {
            console.log(error);
            this.showInfoAlert = false;
            this.showErrorAlert = true;
          });
      }
    },
    dataPolicyClicked() {
      this.$router.push({ path: "/privacy-policy" });
    },
  },
  components: {
    Core,
    CompCareerForm,
  },
};
</script>

<style>
#CompCareerForm .v-input--selection-controls {
  margin-top: 0px !important;
}
</style>
