<template>
  <v-sheet
    :id="`${props.target}`"
    :color="props.color_background"
    :class="`
      pt-${props.pt ? props.pt : '0'} 
      pb-${props.pb ? props.pb : '0'}`">
    <v-container fluid v-if="props" class="container-padding">
      <v-row class="justify-center">
        <v-col
          :cols="props.advantages ? 12 : 12"
          :sm="props.advantages ? 12 : 12"
          :md="props.advantages ? 12 : 12"
          :lg="props.advantages ? 9 : 12"
          :xl="props.advantages ? 9 : 12">
          <v-sheet class="pa-8 rounded-lg elevation-2">
            <h1
              v-if="props.headline && props.headline[language]"
              v-html="props.headline[language]"></h1>
            <div
              v-if="props.text_1 && props.text_1[language]"
              v-html="props.text_1[language]"></div>
            <v-img
              v-if="props.img_href"
              :src="props.img_href"
              :max-width="props.max_width ? props.max_width : '100%'">
            </v-img>
            <span
              v-if="props.text_2 && props.text_2[language]"
              v-html="props.text_2[language]"></span>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="3" xl="3" v-if="props.advantages">
          <Advantages name="Advantages" />
        </v-col>
      </v-row>

      <!-- Edit dialog -->
      <v-dialog v-if="edit" persistent v-model="edit" fullscreen>
        <v-card>
          <v-card-title class="text-h5 black white--text">
            Edit Component
          </v-card-title>
          <v-card-text class="pt-5">
            <Core
              :editProps="editProps"
              @emitProps="setPreviewProps"
              @save="save"
              @cancel="cancel"
              name="Core" />
            <!-- Preview -->
            <h2 class="my-2 text-h4 black--text">Preview:</h2>
            <CompTextImgText
              v-if="previewProps"
              name="CompTextImgText"
              :props="previewProps" />
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import CompTextImgText from "./CompTextImgText";
import Advantages from "./Internal/Advantages";
import Core from "../editor/Core.vue";

export default {
  name: "CompTextImgText",
  props: ["props", "edit", "index", "componentId"],
  data() {
    return {
      editProps: {},
      previewProps: null,
    };
  },
  watch: {
    edit: function () {
      this.editProps.color_background = {};
      this.editProps.color_background.type = "color";
      this.editProps.color_background.name = "color_background";
      this.editProps.color_background.value =
        this.props.color_background ?? "#FFFFFF";

      this.editProps.target = this.props.target ?? "";
      this.editProps.pt = this.props.pt ?? 0;
      this.editProps.pb = this.props.pb ?? 0;

      this.editProps.headline = {};
      this.editProps.headline.type = "text";
      this.editProps.headline.name = "headline";
      this.editProps.headline.value = this.props.headline ?? {};

      this.editProps.text_1 = {};
      this.editProps.text_1.type = "text";
      this.editProps.text_1.name = "text_1";
      this.editProps.text_1.value = this.props.text_1 ?? {};

      this.editProps.text_2 = {};
      this.editProps.text_2.type = "text";
      this.editProps.text_2.name = "text_2";
      this.editProps.text_2.value = this.props.text_2 ?? {};

      this.editProps.max_width = {};
      this.editProps.max_width.type = "number";
      this.editProps.max_width.name = "max_width";
      this.editProps.max_width.value = this.props.max_width ?? 100;

      this.editProps.advantages = {};
      this.editProps.advantages.type = "boolean";
      this.editProps.advantages.name = "advantages";
      this.editProps.advantages.value = this.props.advantages ?? false;

      this.editProps.img_href = {};
      this.editProps.img_href.type = "image";
      this.editProps.img_href.name = "img_href";
      this.editProps.img_href.value = this.props.img_href ?? "";
    },
  },
  computed: {
    ...mapState({
      language: state => {
        return state.language ?? "de";
      },
    }),
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.query.target)
        this.$vuetify.goTo(`#${this.$route.query.target}`, {});
    }, 100);
  },
  methods: {
    save(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
      this.$emit("save", this.previewProps, this.index, this.componentId);
    },
    cancel() {
      this.$emit("cancel");
    },
    setPreviewProps(previewProps) {
      this.previewProps = JSON.parse(JSON.stringify(previewProps));
    },
  },
  components: {
    Core,
    CompTextImgText,
    Advantages,
  },
};
</script>

<style></style>
