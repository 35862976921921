<template>
  <div>
    <h1 class="text-h5 pb-10">Objektdaten Photovoltaikanlage</h1>
    <v-form ref="form">
      <h3>KENNZAHLEN DER ANLAGE</h3>
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.energyData.meteringNumber.value"
            label="Zählernummer vom Einspeisezähler*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.energyData.customerNumberDSO.value"
            label="Kunden-Nr. beim Netzbetreiber für die Einspeiseabrechnung">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="form.energyData.startDate.value"
            label="Inbetriebnahme"
            type="date"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="form.energyData.powerKWP.value"
            label="Leistung in kWp"></v-text-field>
        </v-col>
      </v-row>

      <h3 class="pt-5">
        UMSATZSTEUER
        <v-icon @click="explanationVAT = true">mdi-information-outline</v-icon>
      </h3>
      <p><b>Ich muss Umsatzsteuer auf die Einspeisevergütung abführen:</b></p>
      <v-radio-group v-model="vat" column class="mt-0" row mandatory>
        <v-radio label="Ja" value="1"></v-radio>
        <v-radio label="Nein" value="0"></v-radio>
      </v-radio-group>
      <p>
        Der Kunde ist verpflichtet, Änderungen seiner steuerlichen Verhältnisse
        an GreenStone Energy mitzuteilen und ggf. unberechtigt ausgewiesene und
        ausgezählte Umsatzsteuer an Greenstone Energy zurückzuzahlen
      </p>

      <v-dialog persistent v-model="explanationVAT" width="40%">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Umsatzsteuer
          </v-card-title>

          <v-card-text class="pt-5">
            Eine Photovoltaik-Anlage sollte idealerweise Gewinn erwirtschaften,
            d. h. die Erlöse aus der Einspeisevergütung sind größer als die
            Investition. Sobald jedoch das Ziel einer Tätigkeit ist, dauerhaft
            Gewinn zu erzielen, liegt per Definition eine unternehmerische
            Tätigkeit vor. Das Finanzamt teilt jedem Betreiber einer
            netzgekoppelten Photovoltaik-Anlage eine Umsatzsteuernummer zu.
            <br /><br />
            <b>Vorteil</b> : Die beim Kauf der PV-Anlage (und ggf. die beim Kauf
            des Speichers) enthaltene Umsatzsteuer, die sogenannte Vorsteuer,
            kann gegenüber dem Finanzamt im Rahmen der Umsatzsteuervoranmeldung
            geltend gemacht werden. <br /><br />
            <b>Nachteil</b>: Es besteht die Pflicht, eine Umsatzsteuerklärung
            abzugeben. Zudem muss – zu Beginn monatlich, später vierteljährlich
            – eine Umsatzsteuervoranmeldung abgegeben werden. Hierin ist die
            Umsatzsteuer auf die Einspeisevergütung und auf den Eigenverbrauch
            zu erklären und abzuführen. <br /><br />
            Bei einem Jahresumsatz (entspricht der Einspeisevergütung) unter
            17.500 Euro im letzten und unter 50.000 Euro im laufenden Jahr kann
            die Kleinunternehmerregelung (§ 19 Abs. 1 UStG) in Anspruch genommen
            werden. Dadurch entfällt die Pflicht zur Umsatzsteuererklärung,
            allerdings bekommt der Photovoltaik-Betreiber nicht die Umsatzsteuer
            der Investitionen erstattet. Der Betreiber kann dem Finanzamt
            gegenüber erklären, dass er auf die Anwendung der
            Kleinunternehmerregelung verzichtet. Er ist an diese Erklärung
            mindestens für fünf Kalenderjahre gebunden.
            <br /><br />
            Dies stellt keine steuerliche Beratung dar, sondern informiert nur
            allgemein. Bitte wenden Sie sich bei konkreten Fragen an einen
            Steuerberater.
            <br /><br />
            NewEnergyCloud fragt Sie bei Vertragsabschluss, ob Sie
            umsatzsteuerpflichtig sind, also ob Sie die Einspeisevergütung mit
            Umsatzsteuer enthalten. Wenn Sie dies bejahen, erhalten Sie bei der
            Abrechnung von NewEnergyCloud einen Bonus in Höhe der auf die
            Einspeisevergütung enthaltenen Umsatzsteuer, damit Sie in keinem
            Fall zu viel bezahlen.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="explanationVAT = false"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <p style="font-size: 8pt; color: grey">*Pflichtangaben</p>
    </v-form>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      date: "",
      menu: false,
      explanationVAT: false,
      vat: 0,
      form: {
        text: "Objektdaten Photovoltaikanlage",
        energyData: {
          text: "Kennzahlen der Anlage",
          meteringNumber: {
            text: "Zählernummer vom Einspeisezähler",
            value: "",
          },
          customerNumberDSO: {
            text: "Kunden-Nr. beim Netzbetreiber für die Einspeiseabrechnung",
            value: "",
          },
          startDate: { text: "Inbetriebnahme", value: "" },
          powerKWP: { text: "Leistung in kWp", value: "" },
        },
        vat: {
          text: "Umsatzsteuer",
          vat: { text: "Umsatzsteuer abführen?", value: "" },
        },
      },
    };
  },
  computed: {
    ...mapState({
      selectedTarifAndOptions: state => {
        return state.selectedTarifAndOptions ?? null;
      },
    }),
    checkPageVisibility() {
      if (!this.selectedTarifAndOptions.options) {
        this.$emit("ErrorNoTarifOptions");
        return false;
      }
      if (
        this.selectedTarifAndOptions &&
        !this.selectedTarifAndOptions.options.pv
      ) {
        this.$emit("removePage");
        return false;
      }
      return true;
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    saveData(id) {
      this.vat == 1
        ? (this.form.vat.vat.value = "Ja")
        : (this.form.vat.vat.value = "Nein");

      let data = {
        id: id,
        section: "formSupplyObjectPV",
        data: this.form,
        enabled: true,
      };
      this.$store.commit("setCheckoutData", data);
    },
  },
  mounted() {
    this.resetValidation();
    this.checkPageVisibility;
  },
};
</script>

<style></style>
